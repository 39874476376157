import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { Button, IconButton, InputAdornment, Typography } from '@mui/material';
import { ICommunity } from '../../models/communities.modal';
import { useCommunity } from '../../hooks/useCommunity';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { BankAccInterface } from '../../models/BankAcc.models';
import { useBankAccounts } from '../../hooks/useBankAccounts';
import { Visibility, VisibilityOff } from '@mui/icons-material';

interface PaymentFormProps {
  onCloseModal: () => void;
  tableRowData: BankAccInterface | null;
  fetchMyBankDetails: () => void;
  fetchBankDetailsById: () => void;
  openMode: boolean;
  bankDetailsByCommunity: BankAccInterface[];
}
const PaymentForm: React.FC<PaymentFormProps> = ({
  onCloseModal,
  tableRowData,
  fetchMyBankDetails,
  fetchBankDetailsById,
  openMode,
  bankDetailsByCommunity,
}) => {
  const communitySelected = useSelector(
    (state: any) => state.selectedCommunity.selectedCommunity
  );
  const { updateBankDetail, addCommunityBankDetails } = useBankAccounts();
  const [parentData, setParentData] = useState<BankAccInterface>({
    _id: '',
    community: {
      logo: '',
      name: '',
      status: '',
      type: '',
      _id: '',
    },
    fullName: '',
    branchName: '',
    accountNumber: '',
    ifsc: '',
    phoneNumber: 0,
    bankName: '',
    status: '',
    label: '',
    message: '',
    comments: [
      {
        createdBy: {
          _id: '',
          firstName: '',
          lastName: '',
          avatar: '',
          role: '',
        },

        message: '',
        _id: '',
        createdAt: '',
      },
    ],
  });

  const [verifiedAccountNumber, setVerifiedAccountNumber] =
    useState<string>('');
  const [accountNumberError, setAccountNumberError] = useState<string>('');
  const [verifyAccountNumberError, setVerifyAccountNumberError] =
    useState<string>('');
  const [ifscError, setIfscError] = useState('');
  const [fullNameError, setFullNameError] = useState<string>('');
  const [branchNameError, setBranchNameError] = useState<string>('');
  const [phoneNumberError, setPhoneNumberError] = useState<string>('');
  const [bankNameError, setBankNameError] = useState<string>('');
  const [messageError, setMessageError] = useState<string>('');
  const [showVerifyAccountNumber, setShowVerifyAccountNumber] = useState(false);

  useEffect(
    () => {
      if (tableRowData) {
        setParentData((prevData) => ({
          ...prevData,
          ...tableRowData,
        }));
      } else {
        // If tableRowData is not available, set parentData to default state
        setParentData({
          _id: '',
          community: {
            logo: '',
            name: '',
            status: '',
            type: '',
            _id: '',
          },
          fullName: '',
          branchName: '',
          accountNumber: '',
          ifsc: '',
          phoneNumber: 0,
          bankName: '',
          status: '',
          label: '',
          message: '',
          comments: [
            {
              createdBy: {
                _id: '',
                firstName: '',
                lastName: '',
                avatar: '',
                role: '',
              },

              message: '',
              _id: '',
              createdAt: '',
            },
          ],
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleFieldChange = (fieldName: string, value: string) => {
    if (fieldName === 'verifyAccountNumber') {
      setVerifiedAccountNumber(value);

      if (parentData.accountNumber !== value) {
        setVerifyAccountNumberError('Account numbers do not match');
      } else {
        setVerifyAccountNumberError('');
      }
    } else {
      setParentData((prevData) => ({
        ...prevData,
        [fieldName]: value,
      }));
    }

    if (fieldName === 'ifsc') {
      const ifscRegex = /^[A-Za-z]{4}[0][A-Za-z0-9]{6}$/;
      if (!ifscRegex.test(value)) {
        setParentData((prevData) => ({
          ...prevData,
          [fieldName]: value,
        }));
        setIfscError('Invalid IFSC code format');
      } else {
        setParentData((prevData) => ({
          ...prevData,
          [fieldName]: value,
        }));
        setIfscError('');
      }
    } else {
      setParentData((prevData) => ({
        ...prevData,
        [fieldName]: value,
      }));
    }
  };

  const [loading, setLoading] = useState(false);
  const [draftLoader, setDraftLoader] = useState(false);
  const { getCommunitiesList } = useCommunity();
  const [communities, setCommunities] = useState<ICommunity[]>([]);
  const [selectedCommunityData, setSelectedCommunityData] =
    useState<ICommunity | null>(null);

  const [selectedCommunity, setSelectedCommunity] = useState(
    communitySelected.name || ''
  );
  useEffect(() => {
    const getList = async () => {
      const communitiesDataList = await getCommunitiesList();
      setCommunities(communitiesDataList);
    };
    getList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const communityTitles = communities
    ? communities.map((community) => community.title)
    : [];

  const toggleShowAccountNumber = () => {
    setShowVerifyAccountNumber(!showVerifyAccountNumber);
  };

  const hasNoValue =
    parentData.message &&
    parentData.bankName &&
    parentData.phoneNumber &&
    parentData.ifsc &&
    parentData.accountNumber &&
    parentData.branchName &&
    parentData.fullName &&
    verifiedAccountNumber &&
    parentData?.accountNumber;

  // Handle field changes
  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const ifscRegex = /^[A-Za-z]{4}[0][A-Za-z0-9]{6}$/;

    if (parentData?.accountNumber !== verifiedAccountNumber) {
      setVerifyAccountNumberError('Account numbers do not match');
      return;
    }
    if (!verifiedAccountNumber) {
      setVerifyAccountNumberError('Account number required');
    } else {
      setVerifyAccountNumberError('');
    }
    if (!parentData.fullName) {
      setFullNameError('Name is required');
    } else {
      setFullNameError('');
    }

    if (!parentData.branchName) {
      setBranchNameError('Branch Name is required');
    } else {
      setBranchNameError('');
    }

    if (!parentData.accountNumber) {
      setAccountNumberError('Account Number is required');
    } else {
      setAccountNumberError('');
    }
    if (!parentData.ifsc) {
      setIfscError('IFSC code is required');
    } else if (!ifscRegex.test(parentData.ifsc)) {
      setIfscError('Invalid IFSC code format');
    } else {
      setIfscError('');
    }

    if (!parentData.phoneNumber) {
      setPhoneNumberError('your mobilenumber required');
    } else {
      setPhoneNumberError('');
    }

    if (!parentData.bankName) {
      setBankNameError('your bankname required');
    } else {
      setBankNameError('');
    }
    if (!parentData.message) {
      setMessageError('your comments are required');
    } else {
      setMessageError('');
    }

    const isDuplicateAccount = bankDetailsByCommunity.some((item) => {
      return String(item.accountNumber) === parentData.accountNumber;
    });

    if (isDuplicateAccount) {
      setAccountNumberError('Account number already exists');
      return;
    }

    // Reset account number error if it was previously set
    setAccountNumberError('');

    if (!tableRowData?._id && hasNoValue && !isDuplicateAccount) {
      try {
        setLoading(true);
        const updatedData = {
          fullName: parentData.fullName,
          branchName: parentData.branchName,
          accountNumber: parentData.accountNumber,
          ifsc: parentData.ifsc,
          phoneNumber: parentData.phoneNumber,
          bankName: parentData.bankName,
          status: 'PENDING',
          label: parentData.label,
          message: parentData.message,
        };
        await addCommunityBankDetails(communitySelected?._id, updatedData);
        if (openMode) {
          fetchBankDetailsById();
        } else {
          fetchMyBankDetails();
        }
        onCloseModal();
      } catch (error) {
        console.log('error');
      } finally {
        setLoading(false);
        onCloseModal();
      }
    }
    if (tableRowData && hasNoValue && !isDuplicateAccount) {
      try {
        const updatedData = {
          fullName: parentData.fullName,
          branchName: parentData.branchName,
          accountNumber: parentData.accountNumber,
          ifsc: parentData.ifsc,
          phoneNumber: parentData.phoneNumber,
          bankName: parentData.bankName,
          status: 'PENDING',
          label: parentData.label,
          message: parentData.message,
        };
        setLoading(true);
        await updateBankDetail(parentData?._id, updatedData);
        if (openMode) {
          fetchBankDetailsById();
        } else {
          fetchMyBankDetails();
        }
        onCloseModal();
      } catch (error) {
        console.log('error');
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCreate = async (e: any) => {
    e.preventDefault();

    const ifscRegex = /^[A-Za-z]{4}[0][A-Za-z0-9]{6}$/;

    if (parentData?.accountNumber !== verifiedAccountNumber) {
      setVerifyAccountNumberError('Account numbers do not match');
      return;
    }
    if (!verifiedAccountNumber) {
      setVerifyAccountNumberError('Account number required');
    } else {
      setVerifyAccountNumberError('');
    }
    if (!parentData.fullName) {
      setFullNameError('Name is required');
    } else {
      setFullNameError('');
    }

    if (!parentData.branchName) {
      setBranchNameError('Branch Name is required');
    } else {
      setBranchNameError('');
    }

    if (!parentData.accountNumber) {
      setAccountNumberError('Account Number is required');
    } else {
      setAccountNumberError('');
    }
    if (!parentData.ifsc) {
      setIfscError('IFSC code is required');
    } else if (!ifscRegex.test(parentData.ifsc)) {
      setIfscError('Invalid IFSC code format');
    } else {
      setIfscError('');
    }

    if (!parentData.phoneNumber) {
      setPhoneNumberError('your mobile number required');
    } else {
      setPhoneNumberError('');
    }

    if (!parentData.bankName) {
      setBankNameError('your bank name required');
    } else {
      setBankNameError('');
    }
    if (!parentData.message) {
      setMessageError('your comments are required');
    } else {
      setMessageError('');
    }
    const isDuplicateAccount = bankDetailsByCommunity
      .filter((item) => item.accountNumber !== parentData.accountNumber) // Exclude the selected account
      .some((item) => item.accountNumber === parentData.accountNumber);

    if (isDuplicateAccount) {
      setAccountNumberError('Account number already exists');
      return;
    }
    if (
      fullNameError ||
      branchNameError ||
      accountNumberError ||
      verifyAccountNumberError ||
      ifscError ||
      phoneNumberError ||
      bankNameError ||
      messageError
    ) {
      // If there are errors, do not proceed with API calls
      return;
    }

    // if (
    //   !fullNameError &&
    //   !branchNameError &&
    //   !accountNumberError &&
    //   !verifyAccountNumberError &&
    //   !ifscError &&
    //   !phoneNumberError &&
    //   !bankNameError &&
    //   !messageError
    // ) {
    if (!tableRowData?._id && hasNoValue && !isDuplicateAccount) {
      try {
        setDraftLoader(true);
        const updatedData = {
          fullName: parentData.fullName,
          branchName: parentData.branchName,
          accountNumber: parentData.accountNumber,
          ifsc: parentData.ifsc,
          phoneNumber: parentData.phoneNumber,
          bankName: parentData.bankName,
          status: 'CREATED',
          label: parentData.label,
          message: parentData.message,
        };
        await addCommunityBankDetails(
          selectedCommunityData?._id
            ? selectedCommunityData?._id
            : communitySelected?._id,
          updatedData
        );
        if (openMode) {
          fetchBankDetailsById();
        } else {
          fetchMyBankDetails();
        }
        onCloseModal();
      } catch (error) {
        console.log('error');
      } finally {
        setDraftLoader(false);
        onCloseModal();
      }
    }
    if (tableRowData && hasNoValue && !isDuplicateAccount) {
      try {
        const updatedData = {
          fullName: parentData.fullName,
          branchName: parentData.branchName,
          accountNumber: parentData.accountNumber,
          ifsc: parentData.ifsc,
          phoneNumber: parentData.phoneNumber,
          bankName: parentData.bankName,
          status: 'CREATED',
          label: parentData.label,
          message: parentData.message,
        };
        setDraftLoader(true);
        await updateBankDetail(parentData?._id, updatedData);
        if (openMode) {
          fetchBankDetailsById();
        } else {
          fetchMyBankDetails();
        }
        onCloseModal();
      } catch (error) {
        console.log('error');
      } finally {
        setDraftLoader(false);
      }
    }
    // }
  };

  return (
    <Box>
      <Autocomplete
        options={communityTitles}
        value={selectedCommunity}
        disabled
        componentsProps={{
          paper: {
            sx: {
              padding: '0px',
              fontFamily: 'Montserrat'

            },
          },
        }}
        size="small"
        sx={{ backgroundColor: 'white' }}
        ListboxProps={{ sx: { fontSize: '13px', fontFamily: 'Montserrat' } }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Community"
            size="small"
            sx={{
              padding: '0px',
              fontFamily: 'Montserrat',
              '& .MuiOutlinedInput-root': {
                borderRadius: '8px',
              },

            }}
            InputLabelProps={{
              style: {
                fontSize: '13px',
                fontFamily: 'Montserrat',
              },
            }}
            value={selectedCommunity}
          />
        )}
        onChange={(event, newValue) => {
          setSelectedCommunity(newValue); // Update the selected value
          const communityData = communities.find(
            (community) => community.title === newValue
          );
          setSelectedCommunityData(communityData || null);
        }}
      />
      {/* in create a bank details only not for update
      <TextField
        label="Label"
        name="label"
        value={parentData?.label}
        placeholder="enter Bank branch name"
        onChange={(e) => handleFieldChange("label", e.target.value)}
        fullWidth
        size="small"
        sx={{ mt: "20px" }}
        inputProps={{
          style: {
            fontSize: "13px",
            fontFamily: "Roboto",
          },
        }}
        InputLabelProps={{
          style: {
            fontSize: "13px",
            fontFamily: "Roboto",
          },
        }}
        // error={Boolean(branchNameError)}
        // helperText={branchNameError}
      /> */}

      <TextField
        label="Name"
        name="username"
        value={parentData?.fullName}
        placeholder="Enter your name"
        onChange={(e) => handleFieldChange('fullName', e.target.value)}
        fullWidth
        size="small"
        sx={{
          marginBottom: '20px', marginTop: '20px', '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
          },
        }}
        inputProps={{
          style: {
            fontSize: '13px',
            fontFamily: 'Montserrat',
          },
        }}
        InputLabelProps={{
          style: {
            fontSize: '13px',
            fontFamily: 'Montserrat',
            textAlign: 'center',
          },
        }}
        error={Boolean(fullNameError)}
        helperText={fullNameError}
      />
      <TextField
        label="Branch Name"
        name="branchName"
        value={parentData?.branchName}
        placeholder="Enter Bank branch name"
        onChange={(e) => handleFieldChange('branchName', e.target.value)}
        fullWidth
        size="small"
        sx={{
          marginBottom: '20px',
          '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
          },
        }}
        inputProps={{
          style: {
            fontSize: '13px',
            fontFamily: 'Montserrat',
          },
        }}
        InputLabelProps={{
          style: {
            fontSize: '13px',
            fontFamily: 'Montserrat',
          },
        }}
        error={Boolean(branchNameError)}
        helperText={branchNameError}
      />
      <TextField
        label="Account Number"
        name="accountNumber"
        value={parentData?.accountNumber}
        placeholder="Enter your account number"
        onChange={(e) => handleFieldChange('accountNumber', e.target.value)}
        fullWidth
        size="small"
        sx={{
          marginBottom: '20px', '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
          },
        }}
        inputProps={{
          style: {
            fontSize: '13px',
            fontFamily: 'Montserrat',
          },
        }}
        InputLabelProps={{
          sx: {
            fontSize: '13px',
            fontFamily: 'Montserrat',
            textAlign: 'center',
          },
        }}
        error={Boolean(accountNumberError)}
        helperText={accountNumberError}
      />

      <TextField
        label="Verify Account Number"
        name="verifyAccountNumber"
        value={verifiedAccountNumber}
        type={showVerifyAccountNumber ? 'text' : 'password'}
        placeholder="Verify your account number"
        onChange={(e) =>
          handleFieldChange('verifyAccountNumber', e.target.value)
        }
        fullWidth
        size="small"
        sx={{
          marginBottom: '20px', '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
          },
        }}
        inputProps={{
          style: {
            fontSize: '13px',
            fontFamily: 'Montserrat',
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={toggleShowAccountNumber} edge="end">
                {showVerifyAccountNumber ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          style: {
            fontSize: '13px',
            fontFamily: 'Montserrat',
          },
        }}
        error={Boolean(verifyAccountNumberError)}
        helperText={verifyAccountNumberError}
      />

      <TextField
        label="IFSC"
        name="ifsc"
        value={parentData?.ifsc}
        placeholder="Enter IFSC code"
        onChange={(e) => handleFieldChange('ifsc', e.target.value)}
        fullWidth
        size="small"
        sx={{
          marginBottom: '20px', '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
          },
        }}
        inputProps={{
          style: {
            fontSize: '13px',
            fontFamily: 'Montserrat',
          },
        }}
        InputLabelProps={{
          style: {
            fontSize: '13px',
            fontFamily: 'Montserrat',
          },
        }}
        error={Boolean(ifscError)}
        helperText={ifscError}
      />

      <TextField
        label="Mobile Number"
        name="phoneNumber"
        value={parentData?.phoneNumber !== 0 ? parentData?.phoneNumber : ''}
        onChange={(e) => handleFieldChange('phoneNumber', e.target.value)}
        fullWidth
        size="small"
        placeholder="Enter Mobile Number"
        sx={{
          marginBottom: '20px', '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
          },
        }}
        inputProps={{
          style: {
            fontSize: '13px',
            fontFamily: 'Montserrat',
          },
        }}
        InputLabelProps={{
          style: {
            fontSize: '13px',
            fontFamily: 'Montserrat',
          },
        }}
        error={Boolean(phoneNumberError)}
        helperText={phoneNumberError}
      />
      <TextField
        label="Bank Name"
        name="bankName"
        placeholder="Bank Name as per Record"
        value={parentData?.bankName}
        onChange={(e) => handleFieldChange('bankName', e.target.value)}
        fullWidth
        size="small"
        inputProps={{
          style: {
            fontSize: '13px',
            fontFamily: 'Montserrat',
          },
        }}
        InputLabelProps={{
          style: {
            fontSize: '13px',
            fontFamily: 'Montserrat',
          },
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
          },
        }}
        error={Boolean(bankNameError)}
        helperText={bankNameError}
      />

      <TextField
        label="Comments"
        name="message"
        value={parentData?.message}
        placeholder="Enter Comment"
        onChange={(e) => handleFieldChange('message', e.target.value)}
        fullWidth
        size="small"
        sx={{
          mt: '20px', '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
          },
        }}
        inputProps={{
          style: {
            fontSize: '13px',
            fontFamily: 'Montserrat',
          },
        }}
        InputLabelProps={{
          style: {
            fontSize: '13px',
            fontFamily: 'Montserrat',
          },
        }}
        required
        error={Boolean(messageError)}
        helperText={messageError}
      />
      <Typography sx={{ fontSize: '10px', color: 'red', fontFamily: 'Montserrat', }}>
        ** Once you click the <strong>submit</strong> button, altering your bank
        account details may no longer be possible
      </Typography>
      <Box
        sx={{
          mt: 1.5,
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          gap: { xs: 1, sm: 2, md: 2 },
          justifyContent: 'flex-end',
        }}
      >
        <Button
          variant="contained"
          sx={{
            px: 2,
            textTransform: 'capitalize',
            borderRadius: '4px',
            backgroundColor: ' #3C5AA0',
            boxShadow: 'none',
            fontFamily: 'Montserrat',
            '&:hover': {
              boxShadow: 'none',
              backgroundColor: ' #3C5AA0',
              cursor: 'pointer',
              border: 'none',
            },
          }}
          onClick={handleSubmit}
        >
          {loading ? (
            <CircularProgress size={24} sx={{ color: 'white' }} />
          ) : (
            'Submit'
          )}
        </Button>

        <Button
          variant="outlined"
          sx={{
            px: 3,
            textTransform: 'capitalize',
            borderRadius: '4px',
            color: ' #3C5AA0',
            border: '1px solid #3C5AA0',
            boxShadow: 'none',
            fontFamily: 'Montserrat',
            '&:hover': {
              color: ' #3C5AA0',
              border: '1px solid #3C5AA0',
              boxShadow: 'none',
              cursor: 'pointer',

            },
          }}
          onClick={handleCreate}
        >
          {draftLoader ? (
            <CircularProgress size={24} sx={{ color: 'white' }} />
          ) : (
            'Draft'
          )}
        </Button>
      </Box>
    </Box>
  );
};

export default PaymentForm;
