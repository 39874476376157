
import { Box, Button, InputAdornment, Stack, Tab, TextField, Typography } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { metaConfig } from '../../utils/constants';
import { SubNavbar } from '../Layout/components/SubNavbar';
import { useNavigate } from 'react-router-dom';
import UpComingAppointments from './Components/UpComingAppointments';
import Bookings from './Components/Bookings';
import { SearchIcon1 } from '../../assets/icons';
import { AddCircleOutlineOutlined } from '@mui/icons-material';

const Appointments = () => {
    const [value, setValue] = React.useState('1');


    const [searchQuery, setSearchQuery] = useState('');

    const navigate = useNavigate()

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <>
            <Helmet>
                <title>{metaConfig.title.appointments}</title>
                <meta name="description" content={metaConfig.description} />
                <meta name="format-detection" content="telephone=no" />
                <meta name="author" content={metaConfig.author} />
                <meta name="keywords" content={metaConfig.keywords} />
            </Helmet>


            <Stack flexGrow={1} sx={{ display: { xs: 'flex', md: 'none' }, pb: 0 }} flexDirection={'row'} gap={1} display={'flex'} justifyItems={'center'} alignItems={'center'}>
                {/* <ArrowBackIosNewOutlinedIcon sx={{ fontSize: '18px' }} onClick={() => {
                    navigate('/your-target-route');
                }} /> */}
                <Typography
                    sx={{
                        fontSize: { xs: '14px' },
                        fontWeight: 600,
                        color: '#000000',
                        fontFamily: 'Montserrat',
                    }}
                >
                    Appointments
                </Typography>
            </Stack>

            <SubNavbar>
                <Stack direction={'row'} flexGrow={1} alignItems={'center'} ml={0}>
                    <Stack direction={'row'} flexGrow={1} sx={{ display: { xs: 'none', md: 'block' } }}>
                        <Typography
                            sx={{
                                fontSize: { xs: '15px', md: '16px' },
                                fontWeight: 600,
                                color: '#000000',
                                fontFamily: 'Montserrat',
                            }}
                        >
                            Appointments
                        </Typography>
                    </Stack>
                    <Stack direction={'row'} flexGrow={1} sx={{ justifyContent: { xs: 'space-between', md: 'flex-end' } }} gap={2} >
                        <TextField
                            placeholder="Search "
                            variant="outlined"
                            size="small"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon1 sx={{ mt: 1, }} />
                                    </InputAdornment>
                                ),
                                sx: {
                                    borderRadius: '8px',
                                    backgroundColor: '#F9F9F9',
                                    fontSize: { xs: '12px', md: '15px' },
                                    height: '37px',
                                    width: { xs: '100%', md: '300px' },
                                    color: '#000000',
                                    fontFamily: 'Montserrat',
                                    ml: { xs: -1 },
                                },
                            }}
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <Button
                            variant="contained"
                            endIcon={<AddCircleOutlineOutlined />}
                            sx={{
                                textTransform: 'capitalize',
                                borderRadius: '8px',
                                backgroundColor: '#3C5AA0',
                                fontFamily: 'Montserrat',
                                boxShadow: 'none',
                                '&:hover': {
                                    backgroundColor: '#3C5AA0',
                                    cursor: 'pointer',
                                    border: 'none',
                                },
                            }}
                            onClick={() => {
                                navigate('/add-appointment')
                            }}
                        >
                            Add New
                        </Button>
                    </Stack>
                </Stack >
            </SubNavbar>
            <Box sx={{ width: '100%', mt: 2, backgroundColor: 'white', borderRadius: '12px !important', border: '1px solid #E7EBF1', px: 2 }}>
                <TabContext value={value}>
                    <Box
                        sx={{
                            borderBottom: 1,
                            borderColor: 'divider',
                            // backgroundColor: 'white',
                            borderRadius: '5px',
                        }}
                    >
                        <TabList onChange={handleChange} aria-label="lab API tabs example"
                            sx={{
                                '& .MuiTabs-indicator': {
                                    backgroundColor: '#2653A3',
                                },
                            }}
                        >
                            <Tab
                                label="Appointments"
                                value="1"
                                sx={{
                                    fontSize: { xs: "14px", md: '16px' },
                                    fontWeight: 500,
                                    textTransform: 'capitalize',
                                    fontFamily: 'Montserrat',
                                    color: '#92929D',
                                    '&.Mui-selected': {
                                        color: '#2653A3',
                                        fontWeight: 500,
                                    },

                                }}
                            />
                            <Tab
                                label="Bookings"
                                value="2"
                                sx={{
                                    fontSize: { xs: "14px", md: '16px' },
                                    fontWeight: 500,
                                    textTransform: 'capitalize',
                                    fontFamily: 'Montserrat',
                                    color: '#92929D',
                                    '&.Mui-selected': {
                                        color: '#2653A3',
                                        fontWeight: 500,
                                    },
                                }}
                            />
                            {/* <Tab
                                label="Attendees"
                                value="3"
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: 500,
                                    textTransform: 'capitalize',
                                    fontFamily: 'Inter',
                                    color: '#92929D',
                                    '&.Mui-selected': {
                                        color: '#2653A3',
                                        fontWeight: 500,
                                    },
                                }}
                            /> */}
                        </TabList>
                    </Box>
                    <TabPanel value="1" sx={{ px: 0, py: 1 }}>
                        <UpComingAppointments searchQuery={searchQuery} />
                    </TabPanel>
                    <TabPanel value="2" sx={{ px: 0, py: 1 }}>
                        <Bookings />
                    </TabPanel>
                    {/* <TabPanel value="3" sx={{ px: 0, py: 1 }}>
                        <PastAppointments />
                    </TabPanel> */}
                </TabContext>
            </Box>
        </>
    );
};

export default Appointments;
