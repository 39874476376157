import {
  Avatar,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { metaConfig } from '../../utils/constants';
import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import { INotification } from '../../models/notifications.model';
import store from '../../store';
import { useSelector } from 'react-redux';
import { NotificationList } from '../../hooks/useNotification';
import { AlternativePage } from '../AlternatePages/AlternativePage';
import Loader from '../Loader/Loader';

const Notifications = () => {
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [loadNotifications, setLoadNotifications] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const { getNotificationList, updateNotificationStatus } = NotificationList();

  const state = store.getState();
  const loggedInUserData = useSelector(() => {
    return state?.loggedInUser || null;
  });

  const selectedCommunity = useSelector(
    (state: any) => state.selectedCommunity.selectedCommunity
  );
  const userID = loggedInUserData?.user?.id || undefined;

  // Retrieve communityId from localStorage
  const storedCommunityId = localStorage.getItem('communityId');

  const loadNotifcations = async () => {
    setLoadNotifications(true);
    try {
      const response = await getNotificationList(userID);
      if (response && response.data) {
        const notificationData = response.data.notifications;
        // Filter notifications for the selected community
        const filteredNotifications = notificationData.filter(
          (notification) => notification?.communityId === storedCommunityId
        );
        setNotifications(filteredNotifications);
        const sentCount = filteredNotifications.filter(
          (notification) => notification.status === 'SENT'
        ).length;
        // console.log(`Number of notifications with status SENT: ${sentCount}`);
      } else {
        console.log('No notifications found.');
        console.log('No notifications found.');
      }
    } catch (err) {
      console.log('Error: ', err);
    } finally {
      setLoadNotifications(false);
    }
  };

  const handleNotificationClick = async (notificationId: string) => {
    try {
      setIsUpdating(true);
      const success = await updateNotificationStatus([notificationId], 'READ');
      if (success) {
        setNotifications((prevNotifications) =>
          prevNotifications.map((notification) =>
            notification._id === notificationId
              ? { ...notification, status: 'READ' }
              : notification
          )
        );
      }
    } catch (error) {
      console.log('Error updating notification status:', error);
    } finally {
      setIsUpdating(false);
    }
  };

  useEffect(() => {
    loadNotifcations();
  }, []);


  const formatDateRelative = (dateString: string): string => {
    const currentDate = new Date();
    const inputDate = new Date(dateString);
    const timeDifference = currentDate.getTime() - inputDate.getTime();
    const secondsDifference = Math.floor(timeDifference / 1000);
    const minutesDifference = Math.floor(secondsDifference / 60);
    const hoursDifference = Math.floor(minutesDifference / 60);
    const daysDifference = Math.floor(hoursDifference / 24);

    if (daysDifference < 1) {
      if (hoursDifference < 1) {
        if (minutesDifference < 1) {
          return "Just Now";
        }
        return `${minutesDifference} minute${minutesDifference !== 1 ? "s" : ""
          } ago`;
      } else if (hoursDifference < 12) {
        if (hoursDifference >= 1) {
          return "1 hour ago";
        }
        return `${hoursDifference} Hours ago`;
      } else {
        return "Yesterday";
      }
    }
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return inputDate.toLocaleDateString(undefined, options);
  };

  return (
    <>
      <Helmet>
        <title>{metaConfig.title.notification}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.description} />
        <meta name="keywords" content={metaConfig.description} />
      </Helmet>

      <Stack
        direction="row"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 2,
          mb: 1
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: '14px', md: '16px' },
            fontWeight: 600,
            color: '#000000',
            fontFamily: 'Montserrat',
            textAlign: 'center',
          }}
        >
          Notifications
        </Typography>
      </Stack>


      {loadNotifications ? (
        <Loader />
      ) :
        notifications.length === 0 ? (
          <AlternativePage
            title="No Notifications"
            image={require('./../../assets/icons/svg-icons/noPlans.png')}
          />
        ) : (
          <Stack >
            <List>
              {notifications.map((item, index) => (
                <ListItemButton
                  key={index}
                  sx={{
                    boxShadow: 0.5,
                    p: 1,
                    m: 1,

                    backgroundColor: 'white',
                    fontFamily: 'Montserrat',
                    borderRadius: '5px',
                    border: '1px solid #EAEAEA',
                  }}
                  onClick={() => handleNotificationClick(item._id)}
                >
                  <ListItemAvatar>
                    <Avatar alt="Profile Picture" src={selectedCommunity?.logo} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography
                        sx={{
                          fontSize: { xs: '12px', md: '16px', fontFamily: 'Montserrat' },
                          fontWeight: item.status === 'SENT' ? 'bold' : 'normal',
                        }}
                      >
                        {item.title} -
                        <span style={{ fontSize: '12px', color: 'black', fontWeight: 'bold', fontStyle: 'italic', fontFamily: 'Montserrat' }}>{formatDateRelative(item?.createdAt)}</span>
                      </Typography>
                    }
                    secondary={
                      <Typography
                        sx={{
                          fontSize: { xs: '10px', md: '14px' },
                          fontFamily: 'Montserrat'
                        }}
                      >
                        {item.body}
                      </Typography>
                    }
                    sx={{
                      fontFamily: 'Montserrat',
                      fontSize: { xs: '12px', md: '17px' },
                    }}
                  />
                </ListItemButton>
              ))}
            </List>
          </Stack>
        )}
    </>
  );
};

export default Notifications;
