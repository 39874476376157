import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Stack, Grid, Divider } from '@mui/material';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import LanguageIcon from '@mui/icons-material/Language';
import { Helmet } from 'react-helmet';
import { Close } from '@mui/icons-material';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { usePlans } from '../../../hooks/usePlans';
import { useBankAccounts } from '../../../hooks/useBankAccounts';
import { useInvitations } from '../../../hooks/useInvitations';
import { IPlan, Plans } from '../../Plans/Plans';
import { BankAccInterface } from '../../../models/BankAcc.models';
import { Invitations } from '../../../models/Invitation.model';
import { metaConfig } from '../../../utils/constants';
import Invitation from '../../Invitations/Invitation';
import PaymentList from '../../Payments/PaymentList';
import FAQ from '../../FAQ/FAQ';
import LinkCards from './LinkCards';
import CommunitySteps from './CommunitySteps';
import Confetti from 'react-confetti';
// import { useParams } from "react-router-dom";
// import Step1 from "../../assets/images/school.png"

const GetStarted = () => {
    const communitySelected = useSelector(
        (state: any) => state.selectedCommunity.selectedCommunity
    );

    const community_id = communitySelected?._id;

    const [openPlans, setOpenPlans] = React.useState(false);
    const [openAddUser, setOpenAddUser] = React.useState(false);
    const [openBankDetails, setOpenBankDetails] = React.useState(false);
    const [fullWidth] = React.useState(true);
    const [maxWidth] = React.useState<DialogProps['maxWidth']>('lg');
    const { getCommunityPlansList } = usePlans();
    const { getBankDetailById } = useBankAccounts();
    const { getInviteList } = useInvitations();
    const [plans, setPlans] = React.useState<IPlan[]>([]);
    const [accountDetails, setAccountDetails] = React.useState<BankAccInterface[]>([]);
    const [invitesList, setInvitesList] = React.useState<Invitations[]>([]);
    const [progress, setProgress] = React.useState<number>(0);
    const [error, setError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    //Popup Fuctions Start//

    React.useEffect(() => {
        const fetchDetails = async () => {
            try {
                if (community_id) {
                    const plans_data = await getCommunityPlansList(community_id);
                    if (communitySelected?.status === 'PUBLISHED') {
                        setProgress((prevProgress) => prevProgress + 25);
                    }

                    if (plans_data && plans_data?.length > 0) {
                        setPlans(plans_data);
                        setProgress((prevProgress) => prevProgress + 25);
                    }

                    const response = await getBankDetailById(community_id);

                    // memberListRef.current = users || [];
                    if (response && response.data?.length > 0) {
                        setAccountDetails(response.data);
                        setProgress((prevProgress) => prevProgress + 25);
                    }
                    const invitesList = await getInviteList(community_id);
                    if (invitesList && invitesList.data?.length > 0) {
                        setInvitesList(invitesList.data);
                        setProgress((prevProgress) => prevProgress + 25);
                    }
                }
            } catch (error) {
                console.error('Error fetching invite list:', error);
            }
        };

        fetchDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [community_id]);

    const refetchPlanDetails = async () => {
        try {
            if (community_id) {
                const plans_data = await getCommunityPlansList(community_id);
                if (plans_data && plans_data?.length > 0) {
                    setPlans(plans_data);
                    setProgress((prevProgress) =>
                        prevProgress < 100 ? prevProgress + 25 : 100
                    );
                }
            }
        } catch (error) {
            console.error('Error fetching invite list:', error);
        }
    };

    const refetchBankDetails = async () => {
        try {
            if (community_id) {
                const response = await getBankDetailById(community_id);
                // memberListRef.current = users || [];
                if (response && response.data?.length > 0) {
                    setAccountDetails(response.data);
                    setProgress((prevProgress) =>
                        prevProgress < 100 ? prevProgress + 25 : 100
                    );
                }
            }
        } catch (error) {
            console.error('Error fetching invite list:', error);
        }
    };

    const refetchInviteDetails = async () => {
        try {
            if (community_id) {
                const invitesList = await getInviteList(community_id);
                if (invitesList && invitesList.data?.length > 0) {
                    setInvitesList(invitesList.data);
                    setProgress((prevProgress) =>
                        prevProgress < 100 ? prevProgress + 25 : 100
                    );
                }
            }
        } catch (error) {
            console.error('Error fetching invite list:', error);
        }
    };

    const handleClickOpenPlans = () => {
        // if( communitySelected?.status === "CREATED" ){
        setOpenPlans(true);
        // }
    };

    const handleClose = () => {
        setOpenPlans(false);
        refetchPlanDetails();
    };

    const navigate = useNavigate();

    const handleClickCommunityPublish = () => {
        if (plans?.length > 0) {
            navigate(`/communities/${community_id}/build`);
        }
    };
    const handleClickCommunityPublishFree = () => {
        navigate(`/communities/${community_id}/build`);
    };

    const handleClickOpenAddUser = () => {
        if (
            communitySelected.collectSubscription === 'YES' &&
            communitySelected?.status === 'PUBLISHED' &&
            // accountDetails.length > 0 &&
            plans.length > 0
        ) {
            setOpenAddUser(true);
        } else if (
            communitySelected.collectSubscription === 'NO' &&
            communitySelected.status === 'PUBLISHED'
        ) {
            setOpenAddUser(true);
        } else {
            setError(true);
            if (
                communitySelected.collectSubscription === 'NO' &&
                communitySelected?.status !== 'PUBLISHED'
            ) {
                setErrorMessage('Please build your community to invite members');
            } else {
                setErrorMessage(
                    'Please complete the first 2 necessary steps to invite members.'
                );
            }
        }
    };

    const handleCloseAddUser = () => {
        setOpenAddUser(false);
        refetchInviteDetails();
    };

    const handleOpenBankDetails = () => {
        if (communitySelected?.status === 'PUBLISHED') {
            setOpenBankDetails(true);
        }
    };

    const handleCloseBankDetails = () => {
        setOpenBankDetails(false);
        refetchBankDetails();
    };

    return (
        <>

            <Helmet>
                <title>{metaConfig.title.createCommunity}</title>
                <meta name="description" content={metaConfig.description} />
                <meta name="format-detection" content="telephone=no" />
                <meta name="author" content={metaConfig.author} />
                <meta name="keywords" content={metaConfig.keywords} />
            </Helmet>
            <Confetti numberOfPieces={200} recycle={false} initialVelocityY={5} />
            <Box sx={{ px: { xs: 0, md: 0 }, py: 2 }}>
                <Stack>
                    <Stack sx={{ backgroundColor: 'white', p: 2, borderRadius: '12px', border: '1px solid #E7EBF1' }}>
                        <Stack direction={'row'} spacing={3} alignContent={'center'} alignItems={'center'}>
                            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                                <CircularProgress
                                    variant="determinate"
                                    value={progress}
                                    size={70}
                                    color="success"
                                />
                                <Box
                                    sx={{
                                        top: 0,
                                        left: 0,
                                        bottom: 0,
                                        right: 0,
                                        position: 'absolute',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: '14px',
                                            fontWeight: 600,
                                            fontFamily: 'Montserrat'
                                        }}
                                    >{`${progress}%`}</Typography>
                                </Box>
                            </Box>
                            <Box>
                                <Typography
                                    sx={{
                                        fontFamily: 'Montserrat',
                                        color: '#000000',
                                        fontWeight: '600',
                                        fontSize: {
                                            xs: '15px',
                                            sm: '15px',
                                            md: '18px',
                                            lg: '18px',
                                        },
                                    }}
                                >
                                    👋🏻 Hey Urvik, Complete your company profile
                                </Typography>
                                <Typography
                                    sx={{
                                        mt: 1,
                                        color: '#646464',
                                        fontSize: '14px',
                                        fontFamily: 'Montserrat',
                                        display: {
                                            xs: 'none',
                                            sm: 'none',
                                            md: 'none',
                                            lg: 'block',
                                        },
                                    }}
                                >
                                    Complete your profile to unlock the full potential of your community experience.
                                </Typography>
                            </Box>
                        </Stack>
                    </Stack>

                    <Stack sx={{ backgroundColor: 'white', borderRadius: '6px', mt: 1 }}>
                        <CommunitySteps />
                    </Stack>

                    <Dialog
                        fullWidth
                        maxWidth="lg"
                        open={openBankDetails}
                        onClose={() => handleCloseBankDetails()}
                        PaperProps={{
                            sx: {
                                width: '100%',
                            },
                        }}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{ backgroundColor: '#F0F9FF', height: '50px', px: 2 }}
                        >
                            <Typography
                                sx={{
                                    color: '#3C5AA0',
                                    fontSize: { xs: '15px', md: '20px' },
                                    fontWeight: '500',
                                    backgroundColor: '#F0F9FF',
                                }}
                            >
                                {/* Account Details */}
                            </Typography>
                            <Box
                                sx={{
                                    backgroundColor: '#3C5AA0',
                                    padding: '4px',
                                    borderRadius: '50%',
                                    width: '20px',
                                    height: '20px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                }}
                                onClick={() => handleCloseBankDetails()}
                            >
                                <Close sx={{ fontSize: '15px', color: '#FFFFFF' }} />
                            </Box>
                        </Grid>
                        <DialogContent sx={{ pt: 0, overflowY: 'hidden' }}>
                            <PaymentList openMode={openBankDetails} />
                        </DialogContent>
                    </Dialog>
                    <Stack>
                        {error ? (
                            <Typography
                                sx={{
                                    fontSize: '13px',
                                    color: 'red',
                                    pl: '5px',
                                    textAlign: 'right',
                                }}
                            >
                                {errorMessage}
                            </Typography>
                        ) : null}
                    </Stack>
                    <Stack
                        sx={{
                            backgroundColor: 'white',
                            mt: 1,
                            overflow: 'auto',
                            borderRadius: '12px',
                            border: '1px solid #E7EBF1'
                        }}
                    >
                        <Stack
                            alignContent={'center'}
                            justifyContent={'center'}
                            sx={{ p: 2 }}
                        >
                            <LinkCards />
                        </Stack>
                    </Stack>
                </Stack>
            </Box>
        </>
    );
};
export default GetStarted;
