import { Helmet } from "react-helmet";
import { metaConfig } from "../../utils/constants";
import { Box, Button, CircularProgress, Dialog, DialogContent, Divider, FormControl, Grid, InputAdornment, MenuItem, Paper, Select, SelectChangeEvent, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GooglesIcon from "../../assets/icons/svg-icons/googlemeet.svg"
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { AccessAlarmOutlined, AccessTime, AddPhotoAlternateOutlined, CurrencyRupeeOutlined } from "@mui/icons-material";
import store from "../../store";
import { loadSelectedCommunity } from "../../store/slices/CommunitySlice";
import { useLiveClasses } from "../../hooks/useLiveClassess";
import { ICommunity } from "../../models/communities.modal";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker, LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEvents } from "../../hooks/useEvents";
import { useWebBuilders } from "../../hooks/useWebBuilder";
import { useSnackbar } from "notistack";
import Loader from "../Loader/Loader";
import dayjs, { Dayjs } from "dayjs";
import { link } from "fs";
import BreadcrumbDouble from "../AlternatePages/BreadCumsDouble";





export interface IAddEventDetails {
    title: string;
    description: string;
    coverImage: string | null;
    startDate: string;
    startTime: string;
    endDate: string;
    endTime: string;
    community: string;
    location: string;
    pricing: string | number | null;
    limitCapacity: string | number | null;
    hostedBy: string;
    links: string;
    guestApproval: boolean;
    collectguestPayment: boolean;
}


interface IEvent {
    _id: string;
    title: string;
    description: string;
    coverImage: string;
    availability: Availability[];
    location: string;
    community: Community;
    hostedBy: string;
    createdBy: CreatedBy;
    pricing: number;
    isPaidService: boolean;
    limitCapacity: number;
    guestApproval: boolean;
    collectguestPayment: boolean;
    status: string;
    createdAt: string;
    updatedAt: string;
}

interface Availability {
    day: string;
    availableTimes: AvailableTime[];
    _id: string;
}

interface AvailableTime {
    startTime: string;
    endTime: string;
    meetingLink: string;
    googleEventId: string;
    attendees: any[];
    _id: string;
}

interface Community {
    _id: string;
    name: string;
}

interface CreatedBy {
    _id: string;
    firstName: string;
    lastName: string;
    phoneNumber: number;
    emailId: string;
}


const AddEvent = () => {

    const detailsRef = useRef(null);
    // const schedulingRef = useRef(null);
    // const availabilityRef = useRef(null);
    // const calenderRef = useRef(null);
    // const bookingRef = useRef(null);
    const selectedCommunityId = useSelector(
        (state: any) => state.selectedCommunity.selectedCommunity?._id
    );

    const { id } = useParams();

    const navigate = useNavigate()

    const { enqueueSnackbar } = useSnackbar();

    const [locationType, setLocationType] = useState<'ONLINE' | 'offline'>('offline');
    const [priceType, setPriceType] = useState<'paid' | 0>('paid');
    const [limitType, setLimitType] = useState<'limited' | 0>('limited');
    const [selectedSection, setSelectedSection] = useState<string | null>("instant");
    const [isCreateLoading, setIsCreateLoading] = useState(false);
    const [openConnect, setOpenConnect] = useState(true)
    const [imageUploading, setImageUploading] = useState(false);
    const [selectedStartDate, setSelectedStartDate] = useState<Dayjs | null>(dayjs());
    const [selectedEndDate, setSelectedEndDate] = useState<Dayjs | null>(dayjs());
    const [selectedStartTime, setSelectedStartTime] = useState<string>('10:00');
    const [selectedEndTime, setSelectedEndTime] = useState<string>('11:00');
    const [guestApproval, setGuestApproval] = useState<boolean>(false);


    const scrollToSection = (ref: React.RefObject<HTMLDivElement>, section: string) => {
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            setSelectedSection(section);
        }
    };

    const { createEvent, updateEventById, getEventById } = useEvents();


    const { uploadImages } = useWebBuilders();

    const [formValues, setFormValues] = useState<IAddEventDetails>({
        title: '',
        description: '',
        coverImage: '',
        startDate: selectedStartDate?.format('YYYY-MM-DD') || '',
        startTime: selectedStartTime || '',
        endDate: selectedEndDate?.format('YYYY-MM-DD') || '',
        endTime: selectedEndTime || '',
        community: selectedCommunityId,
        location: '',
        pricing: 0,
        limitCapacity: 0,
        hostedBy: '',
        links: '',
        guestApproval: guestApproval,
        collectguestPayment: false,
    });


    // console.log(formValues?.appointmentType, "formvalues")


    const handleLocationTypeChange = (type: 'ONLINE' | 'offline') => {
        setLocationType(type);
        setFormValues((prev) => ({
            ...prev,
            location: type === 'ONLINE' ? 'ONLINE' : '',
        }));
    };


    useEffect(() => {
        if (locationType === 'ONLINE') {
            setFormValues((prevValues) => ({
                ...prevValues,
                location: 'ONLINE',
            }));
        } else {
            setFormValues((prevValues) => ({
                ...prevValues,
                location: '',
            }));
        }
    }, [locationType]);



    const handleLimitTypeChange = (type: 'limited' | 0) => {
        setLimitType(type);
        setFormValues((prev) => ({
            ...prev,
            limitCapacity: type === 0 ? 0 : null,
        }));
    };


    const handlePriceTypeChange = (type: 'paid' | 0) => {
        setPriceType(type);
        setFormValues((prev) => ({
            ...prev,
            pricing: type === 0 ? 0 : null,
        }));
    };


    const formateStartDate = (date: string) => {
        const startDate = date.slice(0, 10);
        setSelectedStartDate(dayjs.utc(startDate));
        return startDate;
    };

    const formateEndDate = (date: string) => {
        const endDate = date.slice(0, 10);
        setSelectedEndDate(dayjs.utc(endDate));
        return endDate;
    };

    // const formatStartTime = (date: string) => {
    //     const formattedTime = dayjs.utc(date).format("HH:mm");
    //     setSelectedStartTime(dayjs.utc(date));
    //     return formattedTime;
    // };

    // const formatEndTime = (date: string) => {
    //     const formattedTime = dayjs.utc(date).format("HH:mm");
    //     setSelectedEndTime(dayjs.utc(date));
    //     return formattedTime;
    // };


    const handleMyDateChange = (e: any) => {
        const selectedNewDate = new Date(e);
        const formattedDate = selectedNewDate.toISOString().split('T')[0];
        setFormValues({ ...formValues, startDate: formattedDate });
        formateStartDate(formattedDate);
    };

    const handleMyEndDateChange = (e: any) => {
        const selectedNewDate = new Date(e);
        const formattedDate = selectedNewDate.toISOString().split('T')[0];
        setFormValues({ ...formValues, endDate: formattedDate });
        formateEndDate(formattedDate);
    };

    // const handleMyTimeChange = (time: any) => {
    //     const formattedTime = dayjs(time).format("HH:mm");
    //     setFormValues({ ...formValues, startTime: formattedTime });
    //     formatStartTime(formattedTime)
    // };

    // const handleEndTimeChange = (time: any) => {
    //     const formattedTime = dayjs(time).format("HH:mm");
    //     setFormValues({ ...formValues, endTime: formattedTime });
    //     formatEndTime(formattedTime)
    // };

    const handleChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };


    // Updated useEffect with proper typing
    useEffect(() => {
        if (!id) return;

        const loadEvent = async () => {
            const data = await getEventById(id || '');
            const responseData = data;
            // console.log(responseData, "responseData");
            if (responseData) {
                const isOnline = responseData.location === 'ONLINE';
                const isPrice = responseData.pricing === 0
                const availability = responseData.availability?.map((avail: Availability) => ({
                    day: avail.day,
                    availableTimes: avail.availableTimes.map((time: AvailableTime) => ({
                        startTime: time.startTime,
                        endTime: time.endTime,
                        meetingLink: time.meetingLink,
                        googleEventId: time.googleEventId,
                        attendees: time.attendees,
                        _id: time._id
                    })),
                    _id: avail._id
                })) || [];

                const sortedDays = availability
                    .map((a: Availability) => dayjs(a.day))
                    .sort((a: dayjs.Dayjs, b: dayjs.Dayjs) => a.isBefore(b) ? -1 : 1);

                const startDate = sortedDays[0];
                const endDate = sortedDays[sortedDays.length - 1];
                const firstTimeSlot = availability[0]?.availableTimes[0];
                const startTime = firstTimeSlot?.startTime
                const endTime = firstTimeSlot?.endTime

                console.log(endTime, "endtime");
                console.log(startTime, "startTime");
                setFormValues(prev => ({
                    ...prev,
                    title: responseData.title || '',
                    description: responseData.description || '',
                    coverImage: responseData.coverImage || '',
                    startDate: startDate?.format('YYYY-MM-DD') || '',
                    startTime: responseData.startTime || '',
                    endDate: endDate?.format('YYYY-MM-DD') || '',
                    endTime: responseData.endTime || '',
                    community: responseData.community?._id || '',
                    pricing: isPrice ? 0 : responseData.pricing || 0,
                    location: isOnline ? 'ONLINE' : responseData.location || '',
                    limitCapacity: responseData.limitCapacity || 0,
                    hostedBy: responseData.hostedBy || '',
                    guestApproval: responseData.guestApproval || false,
                    links: responseData?.links || "",
                    collectguestPayment: responseData.collectguestPayment || false,
                    availability: availability
                }));

                // Update UI states
                setSelectedStartDate(startDate);
                setSelectedEndDate(endDate);
                setGuestApproval(responseData.guestApproval);
                setLocationType(isOnline ? 'ONLINE' : 'offline');
                setPriceType(isPrice ? 0 : 'paid');
                setLimitType(limitType ? 0 : 'limited');
                setSelectedStartTime(startTime);
                setSelectedEndTime(endTime);
            }
        };

        loadEvent();
    }, [id]);


    const handleImageChange = async (files: FileList | null) => {
        if (!files?.length) return;
        setImageUploading(true);
        try {
            const file = files[0];
            const [response] = await uploadImages([file]);
            const uploadedImageUrl = response?._id;
            if (!uploadedImageUrl) {
                throw new Error('Image upload failed: No response value');
            }
            setFormValues((prev) => ({
                ...prev,
                coverImage: uploadedImageUrl,
            }));
            enqueueSnackbar('Banner image uploaded successfully', { variant: 'success' });
        } catch (error) {
            console.error('Error uploading image:', error);
            enqueueSnackbar('Failed to upload image. Please try again.', { variant: 'error' });
        } finally {
            setImageUploading(false);
        }
    };


    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsCreateLoading(true);
        const updateResponse = {
            ...formValues,
            title: formValues.title || '',
            description: formValues.description || '',
            coverImage: formValues?.coverImage || '67a06282324921eff3de4bce',
            startDate: formValues?.startDate || '',
            startTime: formValues?.startTime || '',
            endDate: formValues?.endDate || '',
            endTime: formValues?.endTime || '',
            community: selectedCommunityId,
            location: formValues?.location || '',
            pricing: Number(formValues?.pricing) || 0,
            limitCapacity: Number(formValues?.limitCapacity) || 0,
            hostedBy: formValues?.hostedBy || '',
            link: formValues?.links || '',
            guestApproval: formValues?.guestApproval || false,
            collectguestPayment: formValues?.collectguestPayment || false,
        };
        try {
            if (id) {
                await updateEventById(updateResponse, id);
            } else {
                await createEvent(updateResponse);
            }
        } catch (error) {
            console.error('Error creating appointment:', error);
        } finally {
            setIsCreateLoading(false);
        }
    };


    const dispatch = useDispatch();

    const communityState = store.getState();
    const currentCommunity = communityState?.selectedCommunity?.selectedCommunity;

    const { createGoogleAuth, } = useLiveClasses()

    const handleSubmitGenerateAuth = async () => {
        const communityId = currentCommunity?._id;
        try {
            const response = await createGoogleAuth(communityId || "");
            if (response?.data?.authUrl) {
                window.open(response.data.authUrl, "_blank");
            }
            if (response?.data?.authUrl) {
                if (currentCommunity !== null && currentCommunity !== undefined) {
                    const communityData: ICommunity = currentCommunity;
                    dispatch(loadSelectedCommunity(communityData));
                }
            }
            return response;
        } catch (err) {
            console.log(err, "error");
        }
    };


    const DataAvailable = formValues?.title && formValues?.description && formValues?.location && formValues?.endDate && formValues?.startDate && formValues?.hostedBy


    const handleGuestApproval = (approvalType: boolean) => {
        setGuestApproval(approvalType);
        setFormValues(prev => ({
            ...prev,
            guestApproval: approvalType
        }));
    };


    const generateTimeSlots = () => {
        const times = [];
        let startTime = dayjs().startOf('day'); // 00:00
        const endTime = dayjs().endOf('day');   // 23:59

        while (startTime.isBefore(endTime)) {
            times.push(startTime.format('HH:mm'));
            startTime = startTime.add(30, 'minute');
        }

        return times;
    };


    const handleEndTimeChange = (event: SelectChangeEvent<string>) => {
        const formattedTime = event.target.value as string;
        setSelectedEndTime(formattedTime);
        setFormValues({ ...formValues, endTime: formattedTime });

    };

    const handleMyTimeChange = (event: SelectChangeEvent<string>) => {
        const formattedTime = event.target.value as string;
        setSelectedStartTime(formattedTime);
        setFormValues({ ...formValues, startTime: formattedTime });
    };

    return (
        <>
            <Helmet>
                <title>{metaConfig.title.addAppointments}</title>
                <meta name="description" content={metaConfig.description} />
                <meta name="format-detection" content="telephone=no" />
                <meta name="author" content={metaConfig.author} />
                <meta name="keywords" content={metaConfig.keywords} />
            </Helmet>
            <BreadcrumbDouble
                items={[
                    { label: 'Events', path: '/events' },
                    { label: `${id ? "Edit Event" : "Create Event"}` }
                ]}
            // actionButton={{ label: 'Events', path: '/events' }}
            />

            <Paper elevation={0} sx={{ borderRadius: '12px !important', border: '1px solid #E7EBF1', backgroundColor: '#F9F9F9', height: { xs: '100vh', md: 'fit-content' }, overflow: 'auto', mt: 1 }}>

                <Box sx={{ width: '100%', typography: '', fontFamily: 'Montserrat', p: 2 }}>
                    {/* Instant Appointment Section */}
                    <Box ref={detailsRef} sx={{ mb: 2 }}>
                        {/* <Typography variant="h5" sx={{ fontSize: '16px', color: '#2653A3', fontWeight: '600', fontFamily: 'Montserrat', mb: 2 }}>
                            Details
                        </Typography> */}
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={8}>
                                <Stack sx={{ pt: 1, pl: 0.5, pr: 0.5 }}>
                                    <Typography variant="h5" sx={{ fontSize: '14px', color: '#000000', fontWeight: '500', fontFamily: 'Montserrat', }}>
                                        Event Title
                                    </Typography>
                                    <TextField
                                        value={formValues?.title}
                                        required
                                        name="title"
                                        label="Event title"
                                        size="small"
                                        onChange={handleChanges}
                                        InputLabelProps={{ style: { fontSize: '13px', color: '#646464', fontFamily: 'Montserrat', }, }}
                                        sx={{
                                            mt: 1, mb: 1, fontSize: '13px', backgroundColor: '#ffffff', fontFamily: 'Montserrat',
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: '8px',
                                            },
                                        }}
                                    />
                                </Stack>
                                <Stack sx={{ pt: 1, pl: 0.5, pr: 0.5 }}>
                                    <Typography variant="h5" sx={{ fontSize: '14px', color: '#000000', fontWeight: '500', fontFamily: 'Montserrat', }}>
                                        Event Description
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        required
                                        value={formValues?.description}
                                        name="description"
                                        label="Event description"
                                        size="small"
                                        multiline
                                        rows={5}
                                        onChange={handleChanges}
                                        InputLabelProps={{
                                            style: { fontSize: '13px', color: '#646464', fontFamily: 'Montserrat', },
                                        }}
                                        sx={{
                                            mt: 1, mb: 1, fontSize: '13px', backgroundColor: '#ffffff', fontFamily: 'Montserrat',
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: '8px',
                                            },
                                        }}
                                    />
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Stack sx={{ pt: 1, pl: 0.5, pr: 0.5 }}>
                                    <Typography
                                        sx={{
                                            ml: 1,
                                            mt: 1,
                                            fontSize: '13px',
                                            color: '#2653A3',
                                            fontWeight: '500',
                                            fontFamily: 'Montserrat',
                                        }}
                                    >
                                        Upload Cover Image
                                    </Typography>

                                    <Stack
                                        sx={{
                                            p: 1.5,
                                            m: 1,
                                            borderRadius: '5px',
                                            border: '1px dashed #000000',
                                            height: { xs: '20vh', md: '25vh' },
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            backgroundImage: formValues?.coverImage
                                                ? `url(${formValues?.coverImage})`
                                                : 'none',
                                            backgroundPosition: 'center',
                                            backgroundSize: 'cover',
                                            backgroundColor: '#F5F5F5'
                                        }}
                                    >
                                        {imageUploading ? (
                                            <Loader />
                                        ) : formValues?.coverImage ? (
                                            <></>
                                        ) : (
                                            <>
                                            </>
                                        )}
                                        {!id && (
                                            <>
                                                <AddPhotoAlternateOutlined
                                                    sx={{ fontSize: '40px', color: '#5E6C84', pb: 1 }}
                                                />
                                                <Typography sx={{ fontSize: '13px', color: '#5E6C84', pb: 1, fontFamily: 'Montserrat', }}>
                                                    Upload Cover Image
                                                </Typography>
                                            </>
                                        )}
                                        <input
                                            type="file"
                                            name="images"
                                            accept="image/*"
                                            onChange={(e) => handleImageChange(e.target.files)}
                                            style={{
                                                border: '2px solid #5E6C84',
                                                borderRadius: '5px',
                                                padding: '8px',
                                                backgroundColor: '#ffffff',
                                                color: '#5E6C84',
                                                fontSize: '10px',
                                                fontWeight: '500',
                                                cursor: 'pointer',
                                                width: '70%',
                                                marginTop: '10px',
                                                fontFamily: 'Montserrat',
                                            }}
                                        />
                                        {/* <Box component={'img'} src={formValues?.coverImage?.value
                                            ? `${formValues.coverImage.value}`
                                            : `${defaultImages}`} /> */}
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <Stack>
                                            <Stack sx={{}}>
                                                <Typography
                                                    sx={{
                                                        mt: 1,
                                                        fontSize: '14px',
                                                        color: '#000000',
                                                        fontWeight: '500',
                                                        fontFamily: 'Montserrat',
                                                    }}
                                                >
                                                    Start Date
                                                </Typography>
                                            </Stack>
                                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                <DemoContainer
                                                    components={['DesktopDatePicker']}
                                                    sx={{
                                                        overflow: 'hidden',
                                                        backgroundColor: 'white',
                                                        mt: 1,
                                                        p: 0,
                                                        boxShadow: 'none'
                                                    }}
                                                >
                                                    <DatePicker
                                                        value={selectedStartDate}
                                                        format="DD/MM/YYYY"
                                                        // label="Select Date"
                                                        disablePast
                                                        onChange={handleMyDateChange}
                                                        sx={{
                                                            fontSize: '12px',
                                                            borderRadius: '10px',
                                                            backgroundColor: 'white',
                                                            boxShadow: 'none',
                                                            '& .css-q9e13y-MuiStack-root>.MuiTextField-root':
                                                            {
                                                                backgroundColor: 'white',
                                                            },
                                                        }}
                                                        slotProps={{
                                                            textField: {
                                                                size: 'small',
                                                                fullWidth: true,
                                                                sx: {
                                                                    '& .MuiOutlinedInput-root': {
                                                                        borderRadius: '8px',
                                                                    },
                                                                    '& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-error':
                                                                    {
                                                                        color: '#00000061',
                                                                    },
                                                                    '& .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
                                                                    {
                                                                        borderColor: 'green',
                                                                        border: '1px solid',
                                                                    },
                                                                    '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input':
                                                                    {
                                                                        color: '#92929D',
                                                                        fontSize: '13px',
                                                                    },
                                                                    '& .MuiInputLabel-root': {
                                                                        fontSize: '13px',
                                                                    },
                                                                    '& .MuiInputLabel-root.Mui-focused': {
                                                                        fontSize: '13px',
                                                                    },

                                                                },
                                                            },
                                                            layout: {
                                                                sx: {
                                                                    color: '#2653A3',
                                                                    borderRadius: '20px',
                                                                    borderWidth: '0px',
                                                                    borderColor: '#2653A3',
                                                                    border: '0px solid',
                                                                    backgroundColor: 'white',
                                                                    fontFamily: 'Montserrat',
                                                                    boxShadow: 'none'
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Stack sx={{}}>
                                            <Typography
                                                sx={{
                                                    mt: 1,
                                                    fontSize: '14px',
                                                    color: '#000000',
                                                    fontWeight: '500',
                                                    fontFamily: 'Montserrat',
                                                }}
                                            >
                                                Start Time
                                            </Typography>
                                        </Stack>
                                        <FormControl fullWidth sx={{ backgroundColor: 'white', mt: 1, borderRadius: '8px' }}>
                                            <Select
                                                value={selectedStartTime}
                                                onChange={handleMyTimeChange}
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <AccessAlarmOutlined sx={{ color: '#575757' }} />
                                                    </InputAdornment>
                                                }
                                                sx={{
                                                    fontFamily: 'Montserrat',
                                                    backgroundColor: 'white',
                                                    fontSize: '13px',
                                                    borderRadius: '8px',
                                                    height: '40px',
                                                    '& .MuiSelect-select': {
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        height: '50%',
                                                    },
                                                }}
                                                MenuProps={{
                                                    PaperProps: {
                                                        sx: { maxHeight: 200 },
                                                    },
                                                }}
                                            >
                                                {generateTimeSlots().map((time) => (
                                                    <MenuItem
                                                        key={time}
                                                        value={time}
                                                        sx={{ fontFamily: 'Montserrat', fontSize: '13px', height: '40px' }}
                                                    >
                                                        {dayjs(time, 'HH:mm').format('h:mm A')}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <Stack>
                                            <Stack sx={{}}>
                                                <Typography
                                                    sx={{
                                                        mt: 1,
                                                        fontSize: '14px',
                                                        color: '#000000',
                                                        fontWeight: '500',
                                                        fontFamily: 'Montserrat',
                                                    }}
                                                >
                                                    End Date
                                                </Typography>
                                            </Stack>

                                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                <DemoContainer
                                                    components={['DesktopDatePicker']}

                                                    sx={{
                                                        overflow: 'hidden',
                                                        backgroundColor: 'white',
                                                        mt: 1,
                                                        p: 0,
                                                        boxShadow: 'none'
                                                    }}
                                                >
                                                    <DatePicker
                                                        value={selectedEndDate}
                                                        format="DD/MM/YYYY"
                                                        disablePast
                                                        onChange={handleMyEndDateChange}
                                                        sx={{
                                                            fontSize: '12px',
                                                            borderRadius: '10px',
                                                            backgroundColor: 'white',
                                                            boxShadow: 'none',
                                                            '& .css-q9e13y-MuiStack-root>.MuiTextField-root':
                                                            {
                                                                backgroundColor: 'white',
                                                            },
                                                        }}
                                                        slotProps={{
                                                            textField: {
                                                                size: 'small',
                                                                fullWidth: true,
                                                                sx: {
                                                                    '& .MuiOutlinedInput-root': {
                                                                        borderRadius: '8px',
                                                                    },
                                                                    '& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-error':
                                                                    {
                                                                        color: '#00000061',
                                                                    },
                                                                    '& .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
                                                                    {
                                                                        borderColor: 'green',
                                                                        border: '1px solid',
                                                                    },
                                                                    '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input':
                                                                    {
                                                                        color: '#92929D',
                                                                        fontSize: '13px',
                                                                    },
                                                                    '& .MuiInputLabel-root': {
                                                                        fontSize: '13px',
                                                                    },
                                                                    '& .MuiInputLabel-root.Mui-focused': {
                                                                        fontSize: '13px',
                                                                    },

                                                                },
                                                            },
                                                            layout: {
                                                                sx: {
                                                                    color: '#2653A3',
                                                                    borderRadius: '20px',
                                                                    borderWidth: '0px',
                                                                    borderColor: '#2653A3',
                                                                    border: '0px solid',
                                                                    backgroundColor: 'white',
                                                                    fontFamily: 'Montserrat',
                                                                    boxShadow: 'none'
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Stack sx={{}}>
                                            <Typography
                                                sx={{
                                                    mt: 1,
                                                    fontSize: '14px',
                                                    color: '#000000',
                                                    fontWeight: '500',
                                                    fontFamily: 'Montserrat',
                                                }}
                                            >
                                                End Time
                                            </Typography>
                                        </Stack>
                                        <FormControl fullWidth sx={{ backgroundColor: 'white', mt: 1, borderRadius: '8px' }}>
                                            <Select
                                                value={selectedEndTime}
                                                onChange={handleEndTimeChange}
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <AccessAlarmOutlined sx={{ color: '#575757' }} />
                                                    </InputAdornment>
                                                }
                                                sx={{
                                                    fontFamily: 'Montserrat',
                                                    backgroundColor: 'white',
                                                    fontSize: '13px',
                                                    borderRadius: '8px',
                                                    height: '40px',
                                                    '& .MuiSelect-select': {
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        height: '50%',
                                                    },
                                                }}
                                                MenuProps={{
                                                    PaperProps: {
                                                        sx: { maxHeight: 200 },
                                                    },
                                                }}
                                            >
                                                {generateTimeSlots().map((time) => {
                                                    const isDisabled = dayjs(time, 'HH:mm').isBefore(dayjs(selectedStartTime, 'HH:mm'));
                                                    return (
                                                        <MenuItem
                                                            key={time}
                                                            value={time}
                                                            disabled={isDisabled}
                                                            sx={{ fontFamily: 'Montserrat', fontSize: '13px', height: '40px' }}
                                                        >
                                                            {dayjs(time, 'HH:mm').format('h:mm A')}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>

                            </Grid>
                        </Grid>


                        <Grid container spacing={1}>
                            <Grid item xs={12} md={6}>
                                <Stack sx={{ pt: 1, pl: 0.5, pr: 0.5 }}>
                                    <Typography variant="h5" sx={{ fontSize: '14px', color: '#000000', fontWeight: '500', fontFamily: 'Montserrat', }}>
                                        Location
                                    </Typography>
                                    <Stack flexDirection={'row'}>
                                        <Button
                                            sx={{
                                                fontSize: '12px',
                                                fontFamily: 'Montserrat',
                                                fontWeight: '400',
                                                p: '7px 20px',
                                                border: formValues?.location === 'ONLINE' ? '1px solid #3760A9' : '1px solid #E7EBF1',
                                                borderRadius: '10px',
                                                color: locationType === 'ONLINE' ? '#2653A3' : '#646464',
                                                backgroundColor: formValues?.location === 'ONLINE' ? '#D4DDED4D' : '#ffffff',
                                                m: 1,
                                                ml: 0,
                                                textTransform: 'capitalize',
                                            }}
                                            onClick={() => handleLocationTypeChange('ONLINE')}
                                        >
                                            Online
                                        </Button>

                                        <Button
                                            sx={{
                                                fontSize: '12px',
                                                fontFamily: 'Montserrat',
                                                fontWeight: '400',
                                                p: '7px 20px',
                                                border: locationType === 'offline' ? '1px solid #3760A9' : '1px solid #E7EBF1',
                                                borderRadius: '10px',
                                                color: locationType === 'offline' ? '#2653A3' : '#646464',
                                                backgroundColor: locationType === 'offline' ? '#D4DDED4D' : '#ffffff',
                                                m: 1,
                                                ml: 0,
                                                textTransform: 'capitalize',
                                            }}
                                            onClick={() => handleLocationTypeChange('offline')}
                                        >
                                            Offline
                                        </Button>
                                    </Stack>

                                    <Stack flexDirection={"row"} gap={1.5} mt={1} alignItems={'center'}>
                                        {formValues?.location === 'ONLINE' ? (
                                            <>
                                                <Box
                                                    component={'img'}
                                                    src={GooglesIcon}
                                                    width={'20px'}
                                                    height={'20px'}
                                                />
                                                <Typography sx={{ fontSize: { xs: '12px', md: '14px' }, fontFamily: 'Montserrat', }}>
                                                    Join the conference on Google Meet
                                                </Typography>
                                            </>
                                        ) : (
                                            <>
                                                <TextField
                                                    fullWidth
                                                    value={formValues?.location || ''}
                                                    name="location"
                                                    label="Enter Location"
                                                    size="small"
                                                    onChange={handleChanges}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <PinDropOutlinedIcon sx={{ color: '#646464' }} />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    InputLabelProps={{
                                                        style: { fontSize: '13px', color: '#646464', fontFamily: 'Montserrat', },
                                                    }}
                                                    sx={{
                                                        fontSize: '13px', backgroundColor: '#ffffff', fontFamily: 'Montserrat',
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '8px',
                                                        },
                                                    }}
                                                />
                                            </>
                                        )}
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack sx={{ pt: 1, pl: 0.5, pr: 0.5 }}>
                                    <Typography variant="h5" sx={{ fontSize: '14px', color: '#000000', fontWeight: '500', fontFamily: 'Montserrat', }}>
                                        Pricing
                                    </Typography>
                                    <Stack flexDirection={'row'} alignItems={'center'} mb={1} >
                                        <Button
                                            sx={{
                                                fontSize: '12px',
                                                fontFamily: 'Montserrat',
                                                fontWeight: '400',
                                                p: '7px 20px',
                                                border: priceType === 'paid' ? '1px solid #3760A9' : '1px solid #E7EBF1',
                                                borderRadius: '10px',
                                                color: priceType === 'paid' ? '#2653A3' : '#646464',
                                                backgroundColor: priceType === 'paid' ? '#D4DDED4D' : '#ffffff',
                                                m: 1,
                                                ml: 0,
                                                textTransform: 'capitalize',
                                            }}
                                            onClick={() => handlePriceTypeChange('paid')}
                                        >
                                            Paid
                                        </Button>

                                        <Button
                                            sx={{
                                                fontSize: '12px',
                                                fontFamily: 'Montserrat',
                                                fontWeight: '400',
                                                p: '7px 20px',
                                                border: priceType === 0 ? '1px solid #3760A9' : '1px solid #E7EBF1',
                                                borderRadius: '10px',
                                                color: priceType === 0 ? '#2653A3' : '#646464',
                                                backgroundColor: priceType === 0 ? '#D4DDED4D' : '#ffffff',
                                                m: 1,
                                                ml: 0,
                                                textTransform: 'capitalize',
                                            }}
                                            onClick={() => handlePriceTypeChange(0)}
                                        >
                                            Free
                                        </Button>

                                    </Stack>
                                    <Stack>
                                        {priceType === "paid" ? (
                                            <TextField
                                                fullWidth
                                                value={formValues?.pricing}
                                                name="pricing"
                                                label="Enter Price"
                                                size="small"
                                                onChange={handleChanges}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <CurrencyRupeeOutlined sx={{ color: '#646464' }} />
                                                        </InputAdornment>
                                                    ),
                                                    inputProps: {
                                                        pattern: "[0-9]*",
                                                        inputMode: "numeric",
                                                    },
                                                }}
                                                InputLabelProps={{
                                                    style: { fontSize: '13px', color: '#646464', fontFamily: 'Montserrat' },
                                                }}
                                                sx={{
                                                    fontSize: '13px', backgroundColor: '#ffffff', fontFamily: 'Montserrat',
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '8px',
                                                    },
                                                }}
                                                onInput={(e: any) => {
                                                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                }}
                                            />
                                        ) : (
                                            <>
                                                <Typography sx={{ fontSize: { xs: '12px', md: '14px' }, fontFamily: 'Montserrat', }}>
                                                    No pricing for this events
                                                </Typography>
                                            </>
                                        )}
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>

                        <Grid container spacing={1}>
                            <Grid item xs={12} md={6}>
                                <Stack sx={{ pt: 1, pl: 0.5, pr: 0.5 }}>
                                    <Typography variant="h5" sx={{ fontSize: '14px', color: '#000000', fontWeight: '500', fontFamily: 'Montserrat', }}>
                                        Limit capacity
                                    </Typography>
                                    <Stack flexDirection={'row'}>

                                        <Button
                                            sx={{
                                                fontSize: '12px',
                                                fontFamily: 'Montserrat',
                                                fontWeight: '400',
                                                p: '7px 20px',
                                                border: limitType === 'limited' ? '1px solid #3760A9' : '1px solid #E7EBF1',
                                                borderRadius: '10px',
                                                color: limitType === 'limited' ? '#2653A3' : '#646464',
                                                backgroundColor: limitType === 'limited' ? '#D4DDED4D' : '#ffffff',
                                                m: 1,
                                                ml: 0,
                                                textTransform: 'capitalize',
                                            }}
                                            onClick={() => handleLimitTypeChange('limited')}
                                        >
                                            Limited
                                        </Button>
                                        <Button
                                            sx={{
                                                fontSize: '12px',
                                                fontFamily: 'Montserrat',
                                                fontWeight: '400',
                                                p: '7px 20px',
                                                border: limitType === 0 ? '1px solid #3760A9' : '1px solid #E7EBF1',
                                                borderRadius: '10px',
                                                color: limitType === 0 ? '#2653A3' : '#646464',
                                                backgroundColor: limitType === 0 ? '#D4DDED4D' : '#ffffff',
                                                m: 1,
                                                ml: 0,
                                                textTransform: 'capitalize',
                                            }}
                                            onClick={() => handleLimitTypeChange(0)}
                                        >
                                            Unlimited
                                        </Button>
                                    </Stack>

                                    <Stack flexDirection={"row"} gap={1.5} mt={1} alignItems={'center'}>
                                        {limitType === 'limited' ? (
                                            <>
                                                <TextField
                                                    fullWidth
                                                    value={formValues?.limitCapacity}
                                                    name="limitCapacity"
                                                    label="Enter limits"
                                                    size="small"
                                                    onChange={handleChanges}
                                                    InputProps={{

                                                        inputProps: {
                                                            pattern: "[0-9]*",
                                                            inputMode: "numeric",
                                                        },
                                                    }}
                                                    InputLabelProps={{
                                                        style: { fontSize: '13px', color: '#646464', fontFamily: 'Montserrat', },
                                                    }}
                                                    sx={{
                                                        fontSize: '13px', backgroundColor: '#ffffff', fontFamily: 'Montserrat',
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '8px',
                                                        },
                                                    }}
                                                    onInput={(e: any) => {
                                                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                    }}
                                                />

                                            </>
                                        ) : (
                                            <>
                                                <Typography sx={{ fontSize: { xs: '10px', md: '12px' }, fontFamily: 'Montserrat', mt: -1 }}>
                                                    Allow any number of people to join
                                                </Typography>
                                            </>
                                        )}
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack sx={{ pt: 1, pl: 0.5, pr: 0.5 }}>
                                    <Typography variant="h5" sx={{ fontSize: '14px', color: '#000000', fontWeight: '500', fontFamily: 'Montserrat', }}>
                                        Guest Approval
                                    </Typography>
                                    <Stack flexDirection={'row'} alignItems={'center'} mb={1} >
                                        <Button
                                            sx={{
                                                fontSize: '12px',
                                                fontFamily: 'Montserrat',
                                                fontWeight: '400',
                                                p: '7px 20px',
                                                border: guestApproval === true ? '1px solid #3760A9' : '1px solid #E7EBF1',
                                                borderRadius: '10px',
                                                color: guestApproval === true ? '#2653A3' : '#646464',
                                                backgroundColor: guestApproval === true ? '#D4DDED4D' : '#ffffff',
                                                m: 1,
                                                ml: 0,
                                                textTransform: 'capitalize',
                                            }}
                                            onClick={() => handleGuestApproval(true)}
                                        >
                                            Required
                                        </Button>

                                        <Button
                                            sx={{
                                                fontSize: '12px',
                                                fontFamily: 'Montserrat',
                                                fontWeight: '400',
                                                p: '7px 20px',
                                                border: guestApproval === false ? '1px solid #3760A9' : '1px solid #E7EBF1',
                                                borderRadius: '10px',
                                                color: guestApproval === false ? '#2653A3' : '#646464',
                                                backgroundColor: guestApproval === false ? '#D4DDED4D' : '#ffffff',
                                                m: 1,
                                                ml: 0,
                                                textTransform: 'capitalize',
                                            }}
                                            onClick={() => handleGuestApproval(false)}
                                        >
                                            Not Required
                                        </Button>

                                    </Stack>
                                    <Stack>
                                        {guestApproval === true ? (
                                            <Typography sx={{ fontSize: { xs: '10px', md: '12px' }, fontFamily: 'Montserrat', mt: -1 }}>
                                                You need to approve each person before they can join"
                                            </Typography>
                                        ) : (
                                            <>
                                                <Typography sx={{ fontSize: { xs: '10px', md: '12px' }, fontFamily: 'Montserrat', mt: -1 }}>
                                                    People can join immediately after registering
                                                </Typography>
                                            </>
                                        )}
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>

                        <Grid container spacing={1}>
                            <Grid item xs={12} md={6}>
                                <Stack sx={{ pt: 1, pl: 0.5, pr: 0.5 }}>
                                    <Typography variant="h5" sx={{ fontSize: '14px', color: '#000000', fontWeight: '500', fontFamily: 'Montserrat', }}>
                                        Host Name
                                    </Typography>
                                    <Stack flexDirection={'row'} alignItems={'center'} mt={1} >
                                        <TextField
                                            required

                                            type="text"
                                            fullWidth
                                            value={formValues?.hostedBy}
                                            name="hostedBy"
                                            label="Host Name"
                                            size="small"
                                            onChange={handleChanges}

                                            InputLabelProps={{
                                                style: {
                                                    fontSize: '13px', color: '#646464', fontFamily: 'Montserrat',

                                                },
                                            }}
                                            sx={{
                                                fontSize: '13px', backgroundColor: '#ffffff', fontFamily: 'Montserrat',
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: '8px',
                                                },
                                            }}
                                        />
                                    </Stack>

                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack sx={{ pt: 1, pl: 0.5, pr: 0.5 }}>
                                    <Typography variant="h5" sx={{ fontSize: '14px', color: '#000000', fontWeight: '500', fontFamily: 'Montserrat', }}>
                                        Event chat group <Typography component={'span'} sx={{ fontSize: '9px', color: '#000000', fontWeight: '400', fontFamily: 'Montserrat', }}>(Add a group chat to allow event attendees to interact with one another!)</Typography>
                                    </Typography>
                                    <Stack flexDirection={'row'} alignItems={'center'} mt={1} >
                                        <TextField
                                            type="text"
                                            fullWidth
                                            value={formValues?.links}
                                            name="links"
                                            label="Event chat group"
                                            size="small"
                                            onChange={handleChanges}

                                            InputLabelProps={{
                                                style: { fontSize: '13px', color: '#646464', fontFamily: 'Montserrat', },
                                            }}
                                            sx={{
                                                fontSize: '13px', backgroundColor: '#ffffff', fontFamily: 'Montserrat',
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: '8px',
                                                },
                                            }}
                                        />
                                    </Stack>

                                </Stack>
                            </Grid>
                        </Grid>

                        <Divider sx={{ mt: 2 }} />
                    </Box>


                    {/* Availability Section */}
                    {/* <Box>
                        <Stack
                            sx={{ p: 0.5, borderRadius: '10px', backgroundColor: '#ffffff' }}>
                            <Accordion

                                sx={{ borderRadius: '10px', boxShadow: 'none' }}
                            >
                                <AccordionSummary

                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                    sx={{
                                        border: 'none',
                                        boxShadow: 'none',
                                        borderRadius: '10px',
                                    }}
                                >
                                    <Typography variant="h5" sx={{ fontSize: '16px', color: '#2653A3', fontWeight: '600', fontFamily: 'Montserrat', }}>
                                        Advance Options
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                    sx={{
                                        pl: 1,
                                        pr: 1,
                                        backgroundColor: '#f4f4f4',
                                        border: 'none',
                                        boxShadow: 'none',
                                    }}
                                >
                                    <Box ref={schedulingRef} >
                                        <Typography variant="h5" sx={{ fontSize: '16px', color: '#2653A3', fontWeight: '600', fontFamily: 'Montserrat', mb: 2 }}>
                                            Scheduling
                                        </Typography>
                                        <Typography variant="h5" sx={{ fontSize: '14px', color: '#000000', fontWeight: '400', fontFamily: 'Montserrat', mb: 2 }}>
                                            Buffer time
                                        </Typography>
                                        <Grid container spacing={1}>
                                            <Grid item xs={6}>
                                                <Stack sx={{ pt: 1, pl: 0.5, pr: 0.5 }}>
                                                    <Typography variant="h5" sx={{ fontSize: '14px', color: '#000000', fontWeight: '500', fontFamily: 'Inter' }}>
                                                        Before
                                                    </Typography>
                                                    <FormControl fullWidth size="small">
                                                        <Select
                                                            label="Duration"
                                                            labelId="demo-simple-select-label"
                                                            id="duration"
                                                            placeholder="Plan Duration"
                                                            name="duration"
                                                            sx={{ backgroundColor: 'white', mt: 1, mb: 1 }}
                                                            onChange={(e) => {
                                                                setBeforeTime(e.target.value);
                                                            }}
                                                            value={beforeTime}
                                                        >
                                                            {beforeTimeDropdown.map((option) => (
                                                                <MenuItem
                                                                    key={option.key}
                                                                    value={option.value}
                                                                    onClick={(e) => handleDurationChange(option.value)}
                                                                    sx={{ fontSize: '14px' }}
                                                                >
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Stack sx={{ pt: 1, pl: 0.5, pr: 0.5 }}>
                                                    <Typography variant="h5" sx={{ fontSize: '14px', color: '#000000', fontWeight: '500', fontFamily: 'Inter' }}>
                                                        After
                                                    </Typography>
                                                    <FormControl fullWidth size="small">
                                                        <Select
                                                            label="Duration"
                                                            labelId="demo-simple-select-label"
                                                            id="duration"
                                                            placeholder="Plan Duration"
                                                            name="duration"
                                                            sx={{ backgroundColor: 'white', mt: 1, mb: 1 }}
                                                            onChange={(e) => {
                                                                setAfterTime(e.target.value);
                                                            }}
                                                            value={afterTime}
                                                        >
                                                            {afterTimeDropdown.map((option) => (
                                                                <MenuItem
                                                                    key={option.key}
                                                                    value={option.value}
                                                                    onClick={(e) => handleDurationChange(option.value)}
                                                                    sx={{ fontSize: '14px' }}
                                                                >
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>

                                                </Stack>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={1}>
                                            <Grid item xs={6}>
                                                <Stack sx={{ pt: 1, pl: 0.5, pr: 0.5 }}>
                                                    <Typography variant="h5" sx={{ fontSize: '14px', color: '#000000', fontWeight: '500', fontFamily: 'Inter' }}>
                                                        Maximum time in advance that an appointment can be booked
                                                    </Typography>
                                                    <FormControl fullWidth size="small">
                                                        <Select
                                                            label="Duration"
                                                            labelId="demo-simple-select-label"
                                                            id="duration"
                                                            placeholder="Plan Duration"
                                                            name="duration"
                                                            sx={{ backgroundColor: 'white', mt: 1, mb: 1 }}
                                                            onChange={(e) => {
                                                                setMaxTime(e.target.value);
                                                            }}
                                                            value={maxTime}
                                                        >
                                                            {maxTimeDropdown.map((option) => (
                                                                <MenuItem
                                                                    key={option.key}
                                                                    value={option.value}
                                                                    onClick={(e) => handleDurationChange(option.value)}
                                                                    sx={{ fontSize: '14px' }}
                                                                >
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Stack sx={{ pt: 1, pl: 0.5, pr: 0.5 }}>
                                                    <Typography variant="h5" sx={{ fontSize: '14px', color: '#000000', fontWeight: '500', fontFamily: 'Inter' }}>
                                                        Minimum time before the appointment start that it can be booked
                                                    </Typography>
                                                    <FormControl fullWidth size="small">
                                                        <Select
                                                            label="Duration"
                                                            labelId="demo-simple-select-label"
                                                            id="duration"
                                                            placeholder="Plan Duration"
                                                            name="duration"
                                                            sx={{ backgroundColor: 'white', mt: 1, mb: 1 }}
                                                            onChange={(e) => {
                                                                setMinTime(e.target.value);
                                                            }}
                                                            value={minTime}
                                                        >
                                                            {minTimeDropdown.map((option) => (
                                                                <MenuItem
                                                                    key={option.key}
                                                                    value={option.value}
                                                                    onClick={(e) => handleDurationChange(option.value)}
                                                                    sx={{ fontSize: '14px' }}
                                                                >
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box ref={availabilityRef} >
                                        <Typography variant="h5" sx={{ fontSize: '16px', color: '#2653A3', fontWeight: '600', fontFamily: 'Montserrat', mb: 2 }}>
                                            Availability
                                        </Typography>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </Stack>
                    </Box> */}

                    {/* Availability Section */}
                    {/* <Box ref={availabilityRef} sx={{ mb: 6 }}>
                        <Typography variant="h5" sx={{ fontSize: '16px', color: '#2653A3', fontWeight: '600', fontFamily: 'Montserrat', mb: 2 }}>
                            Availability
                        </Typography>
                    </Box> */}

                    {/* Availability Section */}
                    {/* <Box ref={calenderRef}>
                        <Typography variant="h5" sx={{ fontSize: '16px', color: '#2653A3', fontWeight: '600', fontFamily: 'Montserrat', mb: 2 }}>
                            Availability
                        </Typography>

                    </Box> */}
                    {/* Availability Section */}
                    {/* <Box ref={bookingRef}>
                        <Typography variant="h5" sx={{ fontSize: '16px', color: '#2653A3', fontWeight: '600', fontFamily: 'Montserrat', mb: 2 }}>
                            Availability
                        </Typography>

                    </Box> */}
                </Box>
                <Stack direction="row" spacing={1} justifyContent={'end'} gap={2} mx={2} sx={{ pb: { xs: 5, md: 1 } }}>
                    {!id ? (<>
                        <Button
                            disabled={!DataAvailable}
                            size="large"
                            variant="contained"
                            sx={{
                                backgroundColor: '#3C5AA0',
                                textTransform: 'capitalize',
                                border: 'none',
                                borderRadius: '8px',
                                boxShadow: 'none',
                                color: 'white',
                                fontFamily: 'Montserrat',
                                '&:hover': {
                                    backgroundColor: '#3C5AA0',
                                    color: 'white',
                                    border: 'none',
                                    boxShadow: 'none',
                                },
                            }}
                            onClick={handleSubmit}
                        >
                            {isCreateLoading ? <CircularProgress size={24} sx={{ color: 'white', }} /> : "Create"}
                        </Button>
                    </>
                    ) : (
                        <>
                            <Button
                                disabled={!DataAvailable}
                                size="large"
                                variant="contained"
                                sx={{
                                    backgroundColor: '#3C5AA0',
                                    textTransform: 'capitalize',
                                    border: 'none',
                                    borderRadius: '8px',
                                    boxShadow: 'none',
                                    fontFamily: 'Montserrat',
                                    color: 'white',
                                    '&:hover': {
                                        backgroundColor: '#3C5AA0',
                                        color: 'white',
                                        border: 'none',
                                        boxShadow: 'none',
                                    },
                                }}
                                onClick={handleSubmit}
                            >
                                {isCreateLoading ? <CircularProgress size={24} sx={{ color: 'white', }} /> : "Update"}
                            </Button>
                        </>
                    )}
                </Stack>
            </Paper>
            {
                !currentCommunity?.googleAuth?.googleEmail &&
                <Dialog open={openConnect}
                    fullWidth
                    maxWidth="sm"
                    // sx={{
                    // '& .MuiDialog-paper': {
                    // height: 'auto',
                    // // minHeight: { xs: '100px', md: '200px' },
                    // },
                    // }}
                    BackdropProps={{
                        sx: {
                            backdropFilter: 'blur(4px)',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        },
                    }}>
                    <DialogContent sx={{ textAlign: 'center', fontSize: { xs: "12px", md: '16px', fontWeight: 'bold' } }}>
                        Sync your calendar for automatic 1-on-1 scheduling
                    </DialogContent>
                    <Stack display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} gap={{ xs: 1, md: 3 }} sx={{ py: { xs: 4, md: 4 } }}>
                        <Box
                            component={'img'}
                            src={GooglesIcon}
                            width={'25px'}
                            height={'25px'}
                        />
                        <Typography sx={{ fontSize: { xs: '14px', md: '16px' } }}>
                            {currentCommunity?.googleAuth?.googleEmail ? (
                                <Typography>
                                    {currentCommunity.googleAuth.googleEmail}
                                </Typography>
                            ) : (
                                <Typography sx={{ fontSize: { xs: '12px', md: '16px' }, fontFamily: 'Montserrat' }}>
                                    Connect your Gmail account
                                </Typography>
                            )}
                        </Typography>
                        {currentCommunity?.googleAuth?.googleEmail ? (
                            <>
                                <Button
                                    size='small'
                                    variant='outlined'
                                    sx={{
                                        color: "#3C5AA0",
                                        textTransform: 'capitalize',
                                        borderRadius: '8px',
                                        border: '1px solid #3C5AA0',
                                        fontFamily: 'Montserrat',
                                        width: { xs: 110, md: 100 },
                                    }}
                                    onClick={handleSubmitGenerateAuth}
                                >
                                    change
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button
                                    size='small'
                                    variant='outlined'
                                    sx={{
                                        color: "#3C5AA0",
                                        textTransform: 'capitalize',
                                        borderRadius: '8px',
                                        border: '1px solid #3C5AA0',
                                        fontFamily: 'Montserrat',
                                        width: { xs: 110, md: 100 },
                                    }}
                                    onClick={handleSubmitGenerateAuth}
                                >
                                    connect
                                </Button>
                            </>
                        )}
                    </Stack>
                </Dialog>
            }
        </>
    );
};

export default AddEvent;