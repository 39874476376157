import React, { useContext, useEffect } from 'react';
import {
  // Autocomplete,
  Box,
  Button,
  // Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Divider,
  CircularProgress,
} from '@mui/material';
import { useState } from 'react';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getPlan, savePlan, updatePlan } from '../../services/plans.service';
import { AuthContext, IAuthContext } from '../../contexts/Auth.context';
import { ICommunity } from '../../models/communities.modal';
import store from '../../store';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import Loader from '../Loader/Loader';
import { IMultiMedia } from '../../models/multi-media.model';
import Close from '@mui/icons-material/Close';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import utc from 'dayjs/plugin/utc'; // Import the UTC plugin
import 'dayjs/locale/en'; // Import the English locale
import { InsertPhotoOutlined } from '@mui/icons-material';
import { getStaticValue } from '../../utils/StaticValues';
import { Helmet } from 'react-helmet';
import { metaConfig } from '../../utils/constants';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import BreadcrumbDouble from '../AlternatePages/BreadCumsDouble';

dayjs.extend(utc);

interface Community {
  name: string;
  _id: string;
}

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}
//
export interface IAddPlan {
  name: string;
  duration: string;
  interval: number;
  startDate: string;
  endDate: boolean;
  status: string;
  billingFrequency: string[];
  totalPlanValue: number;
  offerValue: number;
  promoCode: string;
  description: string;
  summary: string;
  image: IMultiMedia | '';
  document: string;
  community: string;
  images: IMultiMedia | '';
  isOfferEnds: boolean;
  offerEndsAfter: number;
  offerEndsDuration: string;
  offerEndDate: string;
  totalCount: string;

}
interface ISelectedCommunity {
  selectedCommunity: ICommunity[];
  closeDialog: () => void;
  loadPlans: () => void;
}

// duration dropdown values
const durationDropdownOptions: { key: string; value: string; label: string }[] =
  [
    { key: 'YEAR', value: 'Year', label: 'Year' },
    { key: 'MONTH', value: 'Month', label: 'Month' },
    { key: 'WEEK', value: 'Week', label: 'Week' },
    { key: 'DAY', value: 'Day', label: 'Day' },
  ];
const billingFrequencyOptions = [
  { key: 'YEARLY', value: 'Yearly', defaultChecked: false },
  { key: 'HALF_YEARLY', value: 'Half Yearly', defaultChecked: false },
  { key: 'QUARTERLY', value: 'Quarterly', defaultChecked: false },
  { key: 'MONTHLY', value: 'Monthly', defaultChecked: false },
  { key: 'WEEKLY', value: 'Weekly', defaultChecked: false },
  { key: 'DAILY', value: 'Daily', defaultChecked: false },
  { key: 'ONE_TIME', value: 'One Time', defaultChecked: false },
];

export const NewAddPlan: React.FC<ISelectedCommunity> = ({
  closeDialog,
  loadPlans,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const state = store.getState();
  const communitiesFromStore = useSelector(() => {
    return state?.communities;
  });
  const { communities } = communitiesFromStore;
  const communityState = store.getState();
  const currentCommunity = communityState.selectedCommunity.selectedCommunity;

  //   console.log(currentCommunity?.name);

  const { id } = useParams();

  const { getAccessToken } = useContext<IAuthContext>(AuthContext);
  const [community, setCommunity] = React.useState(false);
  const [options, setOptions] = React.useState<Community[]>([]);
  const loading = community && options.length === 0;

  const [loadingButton, setLoadingButton] = useState(false);

  //label change logic start
  const [durationFieldValue, setDurationFieldValue] = useState<string>('');
  const [labelText, setLabelText] = useState('Year');
  const [open, setOpen] = React.useState(false);
  const [opensPublish, setOpensPublish] = React.useState(false);
  const [planImages, setPlanImages] = useState<FileList | null>(null);
  const [planDocuments, setPlanDocuments] = useState<FileList | null>(null);
  const [subscribersCount, setSubscribersCount] = useState<any>();


  // eslint-disable-next-line
  const [error, setError] = useState(false);


  console.log(subscribersCount, "subscribersCount");

  //attach file logic end
  const { enqueueSnackbar } = useSnackbar();
  const [selectedStartDate, setSelectedStartDate] =
    React.useState<Dayjs | null>(null);
  // eslint-disable-next-line
  const [loader, setLoader] = useState(false);
  // eslint-disable-next-line
  const [plans, setPlans] = useState<IAddPlan[]>([]);
  // to load data from the server
  useEffect(() => {
    if (!id) return;
    const fetchPlan = async () => {
      const { data } = await getPlan(getAccessToken(), id);
      setSubscribersCount(data);
      const labelValue: any = durationDropdownOptions.find(
        (e) => e.key === data.duration
      );
      setDurationFieldValue(labelValue?.value);
      let tempBF = bFrequency.map((b) =>
        data.billingFrequency.includes(b.key)
          ? { ...b, defaultChecked: true }
          : b
      );
      setBFrequency(tempBF);
      setPlanValues({
        ...planValues,
        name: data.name,
        duration: data.duration,
        interval: data.interval,
        status: data.status,
        offerValue: data.offerValue,
        totalPlanValue: data.totalPlanValue,
        description: data.description,
        summary: data.summary,
        community: data.community,
        image: data.image,
        images: data.images,
        document: data.document,
        startDate: formateStartDate(data?.startDate),
        billingFrequency: data.billingFrequency,
        isOfferEnds: data?.isOfferEnds ?? false,
        offerEndsAfter: data?.offerEndsAfter ?? 0,
        offerEndsDuration: data?.offerEndsDuration ?? ' ',
        offerEndDate: data?.offerEndDate ?? ' ',
        totalCount: data?.totalCount,

      });
      // console.log(data);
    };

    fetchPlan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  React.useEffect(() => {
    if (!loading) {
      return undefined;
    }
    let active = true;
    (async () => {
      let tempCommunity = communities.map((community) => {
        return {
          _id: community._id,
          name: community.name,
        };
      });
      await sleep(1e3); // For demo purposes.
      if (active) {
        setOptions([...tempCommunity]);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (durationFieldValue) {
      const labelValue: any = durationDropdownOptions.find(
        (e) => e.value === durationFieldValue
      );
      setLabelText(`Set ${labelValue?.value}s`);
    }
  }, [durationFieldValue]);

  const [bFrequency, setBFrequency] = useState<
    { key: string; value: string; defaultChecked: boolean }[]
  >(billingFrequencyOptions);
  const [planValues, setPlanValues] = useState<IAddPlan>({
    name: '',
    duration: '',
    interval: 0,
    startDate: '',
    endDate: false,
    billingFrequency: [],
    status: '',
    totalPlanValue: 0,
    offerValue: 0,
    promoCode: '',
    description: '',
    summary: '',
    image: '',
    images: '',
    document: '',
    community: currentCommunity?._id ?? '',
    isOfferEnds: true,
    offerEndsAfter: 0,
    offerEndsDuration: 'Year',
    offerEndDate: '',
    totalCount: '',

  });

  const formateStartDate = (date: string) => {
    const startDate = date.slice(0, 10);
    setSelectedStartDate(dayjs.utc(startDate));
    return startDate;
  };

  // Functions

  const handleMyDateChange = (e: any) => {
    // Create a new Date object from the user's input
    const selectedNewDate = new Date(e);
    const timezoneOffsetInMinutes = selectedNewDate.getTimezoneOffset();
    selectedNewDate.setMinutes(
      selectedNewDate.getMinutes() - timezoneOffsetInMinutes
    );
    const isoDateString = selectedNewDate.toISOString(); //to ISO
    setPlanValues({ ...planValues, startDate: isoDateString });
  };

  const handleChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'offerValue') {
      const numericValue = parseFloat(value);
      if (!isNaN(numericValue) && numericValue > planValues.totalPlanValue) {
        return;
      }
    }
    setPlanValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setError(value === '');
  };

  const [isSubmitClicked, setIsSubmitClicked] = useState(false);

  const handleSubmit = async (e: any, status: string) => {
    // console.log(planValues, "values");
    setIsSubmitClicked(true);
    e.preventDefault();
    planValues.community = currentCommunity?._id ?? '';
    planValues.status = status;
    try {
      setLoadingButton(true);
      setLoader(true); // Set loading to true when initiating the request
      if (id) {
        const updateResponse = await updatePlan(
          id,
          getAccessToken(),
          planValues,
          planImages,
          planDocuments
        );
        if (updateResponse.data && updateResponse.status === 200) {
          enqueueSnackbar('Plan updated successfully', { variant: 'success' });
          navigate('/plans');
          closeDialog();
        } else {
          enqueueSnackbar('Failed to update plan', { variant: 'error' });
        }
      } else {
        const createResponse = await savePlan(
          currentCommunity?._id ?? '',
          getAccessToken(),
          planValues,
          planImages,
          planDocuments
        );
        setLoader(true);
        if (createResponse.data && createResponse?.status === 201) {
          setPlans((prevPlans) => [...prevPlans, createResponse.data]);
          enqueueSnackbar('Plan created successfully', { variant: 'success' });
          navigate('/plans');
          if (location.pathname === '/plans' || location.pathname === '/create-plan') {
            navigate('/plans');
          } else if (location.pathname === '/communities/create-community') {
            navigate('/communities/create-community');
          }
          closeDialog();
          loadPlans();
          setLoader(true);
        } else {
          enqueueSnackbar('Failed to create plan', { variant: 'error' });
        }
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoader(false);
      setLoadingButton(false);
    }
  };

  const [checked] = useState<boolean>(false);


  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState<DialogProps['maxWidth']>('sm');

  const handleCommunitySelection = (event: any) => {
    setPlanValues({ ...planValues, community: event });
  };

  const handleDurationChange = (e: any) => {
    setPlanValues({ ...planValues, duration: e });
  };

  const handlePlanImages = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setPlanImages(e.target.files);
    }
  };

  const handlePlanDocuments = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setPlanDocuments(e.target.files);
    }
  };

  const handleClickOpenImg = () => {
    setOpen(true);
  };

  const handleClosesImg = () => {
    setOpen(false);
  };

  const handleClickOpenPublish = () => {
    setOpensPublish(true);
  };

  const handleClickClosesPublish = () => {
    setOpensPublish(false);
  };

  const locations = useLocation();
  const IsTitleDisplay = locations.pathname.includes('/create-plan') && id;

  const isDataAvailable =
    planValues.name &&
    planValues.duration &&
    planValues.totalPlanValue &&
    planValues.interval;
  // &&
  // selectedStartDate === null &&
  // planValues.startDate;

  const offerValue = planValues.offerValue;
  const totalPlanValue = planValues.totalPlanValue;
  const showOfferValue = offerValue > 0;
  const showTotalPlanValue =
    showOfferValue && offerValue !== totalPlanValue && totalPlanValue > 0;

  return (
    <>
      <Helmet>
        <title>{metaConfig.title.createPlan}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.author} />
        <meta name="keywords" content={metaConfig.keywords} />
      </Helmet>
      <div>
        <BreadcrumbDouble
          items={[
            { label: 'Plans', path: '/plans' },
            { label: `${id ? "Edit Plan" : "Create Plan"}` }
          ]}
        />
        {loading ? (
          <Loader />
        ) : (
          <Stack direction={'column'} spacing={1}>
            <Stack sx={{ display: IsTitleDisplay ? 'block' : 'none' }}>
              <Typography
                sx={{
                  fontSize: '13px',
                  fontWeight: 500,
                  color: '#3760A9',
                  fontFamily: 'Montserrat',
                }}
              >
                {id ? 'Publish' : 'Create'} a Plan
              </Typography>
            </Stack>
            <Box
              sx={{
                backgroundColor: 'white',
                pl: 1,
                pr: 1,
                pb: 2,
                borderRadius: '12px !important', border: '1px solid #E7EBF1',
              }}
            >
              <>
                {/* <Grid container sx={{ borderRadius: 'none' }}>
                  <Grid item xs={12}>
                    <Stack sx={{ pt: 0.7 }}>
                      <Typography
                        variant="h5"
                        sx={{
                          ml: 1,
                          fontSize: '13px',
                          color: '#2653A3',
                          fontWeight: '500',
                          fontFamily: 'Montserrat',
                          pl: 0.5,
                          pr: 0.5,
                        }}
                      >
                        Community
                      </Typography>

                      <Stack sx={{ m: 1, pt: 0.5, pl: 0.5, pr: 0.5 }}>
                        <Autocomplete
                          size="small"
                          id="asynchronous-demo"
                          fullWidth
                          open={community}
                          onOpen={() => {
                            setCommunity(true);
                          }}
                          onClose={() => {
                            setCommunity(false);
                          }}
                          onChange={(e, v) =>
                            handleCommunitySelection(v?._id ?? '')
                          }
                          isOptionEqualToValue={(option, value) =>
                            option.name === value.name
                          }
                          getOptionLabel={(option) => option.name}
                          options={options}
                          loading={loading}
                          value={currentCommunity}
                          disabled
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.name}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Communities"
                              placeholder="Type and search communities"
                              sx={{ width: '100%', fontSize: '11px' }}
                              InputLabelProps={{
                                style: { fontSize: '13px', color: '#565656' },
                              }}
                            />
                          )}
                        />
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid> */}

                <Box
                  sx={{
                    backgroundColor: '#E0E0E04D',
                    p: { xs: 1, md: 2 },
                    borderRadius: '10px',
                    mt: 1,
                  }}
                >
                  <Grid container sx={{ borderRadius: '10px' }}>
                    <Grid item xs={12} md={9}>
                      <Stack spacing={1} sx={{ m: 1 }}>
                        {/* <Stack>
                          <Typography
                            sx={{
                              color: '#000000',
                              fontSize: '15px',
                              fontWeight: 500,
                            }}
                          >
                            Plan Summary
                          </Typography>
                        </Stack> */}
                        <Stack>
                          <Stack>
                            <Typography
                              variant="h5"
                              sx={{
                                fontSize: '13px',
                                color: '#2653A3',
                                fontWeight: '500',
                                fontFamily: 'Montserrat',
                              }}
                            >
                              Plan Name
                            </Typography>
                            <TextField
                              required
                              name="name"
                              label="Name"
                              size="small"
                              onChange={handleChanges}
                              value={planValues?.name}
                              // InputProps={{ style: { height: "45px" } }}
                              InputLabelProps={{
                                style: { fontSize: '13px', color: '#565656' },
                              }}
                              sx={{
                                mt: 1,

                                mr: 1,
                                mb: 1,
                                fontSize: '13px',
                                backgroundColor: 'white',
                                borderRadius: '8px',
                                '& .MuiInputLabel-root': {
                                  fontFamily: 'Montserrat',
                                },
                                '& .MuiOutlinedInput-root': {
                                  borderRadius: '8px',
                                },
                              }}
                            ></TextField>
                          </Stack>
                        </Stack>

                        <Stack>
                          <Stack>
                            <Typography
                              sx={{
                                fontSize: '13px',
                                color: '#2653A3',
                                fontWeight: '500',
                                fontFamily: 'Montserrat',
                              }}
                            >
                              Plan Description
                            </Typography>
                            <TextField
                              name="description"
                              id="description"
                              //placeholder="Describe your plan in short words..."
                              label="Description"
                              size="medium"
                              value={planValues?.description}
                              onChange={handleChanges}
                              required
                              multiline
                              rows={2}
                              InputLabelProps={{
                                style: { fontSize: 13, color: '#565656' },
                              }}
                              sx={{
                                mt: 1, mr: 1, backgroundColor: 'white',
                                borderRadius: '8px',
                                '& .MuiInputLabel-root': {
                                  fontFamily: 'Montserrat',
                                },
                                '& .MuiOutlinedInput-root': {
                                  borderRadius: '8px',
                                },
                              }}
                            ></TextField>
                          </Stack>
                          {/* <Typography
                            sx={{
                              ml: 1,
                              mt: 1,
                              fontSize: { xs: '8px', md: '11px' },
                              color: '#5E6C84',
                              fontWeight: '500',
                              fontFamily: 'Montserrat',
                            }}
                          >
                            * The Plan Name and Plan Description will appear on
                            the invoice as entered above.
                          </Typography> */}
                        </Stack>
                        {/* <Divider></Divider> */}
                      </Stack>

                      <Stack sx={{ m: 1 }}>
                        {/* <Stack sx={{ mt: 1 }}>
                          <Typography
                            sx={{
                              color: '#000000',
                              fontSize: '15px',
                              fontWeight: 500,
                              ml: 1,
                            }}
                          >
                            Billing Details
                          </Typography>
                        </Stack> */}

                        <Grid container spacing={{ xs: 1, md: 5 }}>
                          <Grid item xs={12} md={6}>
                            <Stack sx={{}}>
                              <Typography
                                sx={{
                                  ml: 1,
                                  mt: 1,
                                  fontSize: '13px',
                                  color: '#2653A3',
                                  fontWeight: '500',
                                  fontFamily: 'Montserrat',
                                }}
                              >
                                Billing Frequency
                              </Typography>
                            </Stack>{' '}
                            <Grid container spacing={1} sx={{ mt: 1 }}>
                              <Grid item sm={6} xs={6} md={6} lg={6} xl={6}>
                                <FormControl fullWidth size="small">

                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="duration"
                                    label="Plan Duration"
                                    name="duration"

                                    disabled={subscribersCount?.subscribers?.length > 1}
                                    sx={{
                                      backgroundColor: 'white',
                                      borderRadius: '8px',
                                      '& .MuiOutlinedInput-root': {
                                        borderRadius: '8px',
                                      },
                                    }}
                                    // style={{ height: "45px",marginTop:1}}

                                    onChange={
                                      (e) => {
                                        setDurationFieldValue(e.target.value);
                                      }
                                      // setLabelText(e.target.value) }
                                    }
                                    value={durationFieldValue}
                                  >
                                    {durationDropdownOptions.map((option) => (
                                      <MenuItem
                                        disabled={subscribersCount?.subscribers?.length > 1}
                                        key={option.key}
                                        value={option.value}
                                        onClick={(e) =>
                                          handleDurationChange(option.key)
                                        }
                                      >
                                        {option.value}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>

                              <Grid item sm={6} xs={6} md={6}>
                                <TextField

                                  sx={{
                                    borderRadius: '8px',
                                    backgroundColor: 'white',
                                    '& .MuiOutlinedInput-root': {
                                      borderRadius: '8px',
                                    },
                                    '& .MuiInputLabel-root': { fontFamily: 'Montserrat', },
                                  }}
                                  name="interval"
                                  id="interval"
                                  label={labelText}
                                  variant="outlined"
                                  size="small"
                                  // disabled={checked}
                                  disabled={subscribersCount?.subscribers?.length > 1}
                                  fullWidth
                                  onChange={handleChanges}
                                  type="number"
                                  InputProps={{
                                    inputProps: { min: 1 },
                                  }}
                                  value={
                                    planValues.interval > 0
                                      ? planValues.interval
                                      : ' '
                                  }
                                />
                              </Grid>
                              {/* <Typography
                                sx={{
                                  ml: 1,
                                  mt: 1,
                                  fontSize: { xs: '8px', md: '11px' },
                                  color: '#5E6C84',
                                  fontWeight: '500',
                                  fontFamily: 'Montserrat',
                                }}
                              >
                                * You can set billing cycle (start date and end
                                date) and trial period later while, creating a
                                subscription.
                              </Typography> */}
                              <label htmlFor="otherField"></label>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} md={5.9} >
                            <Stack >
                              <Stack sx={{}}>
                                <Typography
                                  sx={{
                                    ml: 1,
                                    mt: 1,
                                    fontSize: '13px',
                                    color: '#2653A3',
                                    fontWeight: '500',
                                    fontFamily: 'Montserrat',
                                  }}
                                >
                                  Start Date
                                </Typography>
                              </Stack>

                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer
                                  components={['DesktopDatePicker']}
                                  sx={{
                                    overflow: 'hidden',
                                    backgroundColor: 'white',
                                    mt: 2,
                                    p: 0,
                                    borderRadius: '8px'
                                  }}
                                >
                                  <DatePicker
                                    disablePast
                                    format="DD/MM/YYYY"
                                    value={selectedStartDate}
                                    // disabled={planValues?.subscribers?.length > 1}

                                    onChange={handleMyDateChange}
                                    sx={{

                                      fontSize: '12px',
                                      backgroundColor: 'white',

                                      '& .css-q9e13y-MuiStack-root>.MuiTextField-root':
                                      {
                                        backgroundColor: 'white',
                                      },
                                      '& .MuiInputLabel-root': {
                                        fontSize: '13px',
                                        fontFamily: 'Montserrat',
                                      },
                                      '& .MuiInputLabel-root.Mui-focused': {
                                        fontSize: '13px',
                                      },

                                    }}
                                    slotProps={{

                                      textField: {
                                        size: 'small',
                                        fullWidth: true,

                                        sx: {
                                          '& .MuiOutlinedInput-root': {
                                            borderRadius: '8px', // Ensuring radius on TextField
                                          },
                                          '& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-error':
                                          {
                                            color: '#00000061',
                                          },
                                          '& .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
                                          {
                                            borderColor: 'green',
                                            border: '1px solid',
                                          },
                                          '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input':
                                          {
                                            color: '#92929D',
                                            fontSize: '13px',
                                          },
                                          '& .MuiInputLabel-root': {
                                            fontSize: '13px',
                                          },
                                          '& .MuiInputLabel-root.Mui-focused': {
                                            fontSize: '13px',
                                          },

                                        },
                                      },
                                      layout: {
                                        sx: {
                                          color: '#2653A3',
                                          borderRadius: '20px',
                                          borderWidth: '0px',
                                          borderColor: '#2653A3',
                                          border: '0px solid',
                                          backgroundColor: 'white',
                                          fontFamily: 'Montserrat',
                                          boxShadow: 'none'
                                          ,
                                          '& .MuiInputLabel-root': {
                                            fontFamily: 'Montserrat',
                                          },
                                        }
                                      }
                                    }}
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                            </Stack>
                          </Grid>
                        </Grid>

                        <Grid container spacing={{ xs: 1, md: 5 }}>
                          <Grid item xs={12} md={6} >
                            <Stack sx={{}}>
                              <Typography
                                sx={{
                                  ml: 1,
                                  mt: 1,
                                  fontSize: '13px',
                                  color: '#2653A3',
                                  fontWeight: '500',
                                  fontFamily: 'Montserrat',
                                }}
                              >
                                Actual Price
                              </Typography>
                            </Stack>
                            <TextField
                              sx={{
                                mt: 2, backgroundColor: 'white',
                                borderRadius: '8px',
                                '& .MuiInputLabel-root': {
                                  fontFamily: 'Montserrat',
                                },
                                '& .MuiOutlinedInput-root': {
                                  borderRadius: '8px',
                                },
                              }}

                              disabled={subscribersCount?.subscribers?.length > 1}
                              required
                              fullWidth
                              onChange={handleChanges}
                              name="totalPlanValue"
                              id="totalPlanValue"
                              value={
                                planValues.totalPlanValue !== 0 ? planValues.totalPlanValue : ''
                              }
                              label="Actual Price"
                              size="small"
                              type="number"
                              inputProps={{
                                inputMode: 'numeric',
                                pattern: '[0-9]*',
                                min: 1,
                                onInput: (e: any) => {
                                  e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                },
                              }}
                              InputLabelProps={{
                                style: { fontSize: 17, color: '#565656' },
                              }}
                              InputProps={{

                                startAdornment: (
                                  <InputAdornment position="start">
                                    <CurrencyRupeeIcon
                                      sx={{
                                        // backgroundColor: "#D9D9D9",
                                        color: 'black',
                                        fontSize: '16px',
                                      }}
                                    />
                                  </InputAdornment>
                                ),
                                // style: { height: "45px" },
                              }}

                            ></TextField>
                            {/* <Typography
                              sx={{
                                ml: 1,
                                mt: 1,
                                fontSize: { xs: '8px', md: '11px' },
                                color: '#5E6C84',
                                fontWeight: '500',
                                fontFamily: 'Montserrat',
                              }}
                            >
                              * Billing amount and billing frequency can not be
                              changed later.
                            </Typography> */}
                          </Grid>

                          <Grid item xs={12} md={5.9} >
                            <Stack sx={{}}>
                              <Typography
                                sx={{
                                  ml: 1,
                                  mt: 1,
                                  fontSize: '13px',
                                  color: '#2653A3',
                                  fontWeight: '500',
                                  fontFamily: 'Montserrat',
                                }}
                              >
                                Offer Price
                              </Typography>
                            </Stack>{' '}
                            <TextField
                              sx={{
                                mt: 2, backgroundColor: 'white',
                                borderRadius: '8px',
                                '& .MuiInputLabel-root': {
                                  fontFamily: 'Montserrat',
                                },
                                '& .MuiOutlinedInput-root': {
                                  borderRadius: '8px',
                                },
                              }}
                              disabled={subscribersCount?.subscribers?.length > 1}
                              fullWidth
                              name="offerValue"
                              id="offerValue"
                              value={
                                planValues.offerValue !== 0
                                  ? planValues.offerValue
                                  : ' '
                              }
                              onChange={handleChanges}
                              label="Offer Price"
                              size="small"
                              type="number"
                              InputLabelProps={{
                                sx: { fontSize: 17, color: '#565656' },
                              }}
                              InputProps={{
                                inputProps: {
                                  min: 0,
                                  onInput: (e: any) => {
                                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                  },
                                },
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <CurrencyRupeeIcon
                                      sx={{
                                        // backgroundColor: "#D9D9D9",
                                        color: 'black',
                                        fontSize: '16px',
                                      }}
                                    />
                                  </InputAdornment>
                                ),
                                // style: { height: "45px" },
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Divider sx={{ mt: 2 }}></Divider>
                      </Stack>

                      <Stack>
                        <Stack sx={{ pt: 1 }}>
                          <Typography
                            sx={{
                              ml: 1,
                              fontSize: '13px',
                              color: '#2653A3',
                              fontWeight: '500',
                              fontFamily: 'Montserrat',
                            }}
                          >
                            Attachments
                          </Typography>
                          <Typography
                            sx={{
                              ml: 1,
                              fontSize: { xs: '10px', md: '12px' },
                              // color: "#292D32",
                              fontFamily: 'Montserrat',
                            }}
                          >
                            * Upload additional documents that provide more
                            information.
                          </Typography>
                        </Stack>

                        <Grid container spacing={{ xs: 1, md: 3 }}>
                          <Grid item xs={12} md={6}>
                            <Stack
                              sx={{
                                backgroundColor: 'white',
                                p: 1.5,
                                m: 1,
                                borderRadius: '5px',
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: '13px',
                                  color: '#5E6C84',
                                  pb: 1,
                                  fontFamily: 'Montserrat',
                                }}
                              >
                                Attach Image
                              </Typography>
                              <input
                                type="file"
                                name="images"
                                onChange={handlePlanImages}
                                style={{
                                  border: '2px solid #5E6C84',
                                  borderRadius: '8px',
                                  padding: '8px',
                                  backgroundColor: '#ffffff',
                                  color: '#5E6C84',
                                  fontSize: '12px',
                                  fontWeight: '500',
                                  cursor: 'pointer',
                                  width: '70%',
                                  fontFamily: 'Montserrat',
                                }}
                              // value={planValues?.images ? planValues?.images?.label : ''}
                              ></input>

                              {planValues?.image && (
                                <Button
                                  variant="outlined"
                                  onClick={handleClickOpenImg}
                                  sx={{
                                    textTransform: 'capitalize',
                                    mt: { xs: 2, md: 1.5 },
                                    fontSize: '13px',

                                    width: '160px',
                                    color: '#5E6C84',
                                    fontFamily: 'Montserrat',
                                    border: '1px solid #5E6C84',
                                    ':hover': {
                                      color: '#5E6C84',
                                      border: '1px solid #5E6C84',
                                    },
                                  }}
                                  startIcon={<InsertPhotoOutlined />}
                                >
                                  Preview Image
                                </Button>
                              )}
                              {/* Pop Up for About us Content */}
                              <Dialog
                                fullWidth={fullWidth}
                                maxWidth={maxWidth}
                                open={open}
                                onClose={handleClosesImg}
                              >
                                <DialogActions
                                  sx={{ backgroundColor: '#F0F9FF' }}
                                >
                                  <Stack
                                    direction={'row'}
                                    justifyContent={'space-between'}
                                    alignItems={'center'}
                                    sx={{ backgroundColor: '#F0F9FF' }}
                                  >
                                    <Stack></Stack>
                                    <Stack></Stack>
                                    <Stack sx={{ backgroundColor: '#F0F9FF' }}>
                                      <Box
                                        onClick={handleClosesImg}
                                        sx={{
                                          backgroundColor: '#3C5AA0',
                                          padding: '4px',
                                          borderRadius: '50%',
                                          width: '20px',
                                          height: '20px',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          color: 'white',
                                          fontSize: '10px',
                                          fontFamily: 'Montserrat',
                                          mt: '0.5px',
                                          mr: '10px',
                                          cursor: 'pointer',
                                          '&:hover': {
                                            // backgroundColor: "#50A1CA", // Background color on hover
                                            cursor: 'pointer',
                                            border: 'none',
                                          },
                                        }}
                                      >
                                        <Close sx={{ fontSize: '13px' }} />
                                      </Box>
                                    </Stack>
                                  </Stack>
                                </DialogActions>

                                <DialogContent>
                                  <img
                                    alt=""
                                    // onClick={() => navigate(`/plans/${plan?._id}`)}
                                    style={{
                                      width: '100%',
                                      height: 'auto',
                                      maxHeight: '300px',
                                      objectFit: 'cover',
                                      borderRadius: '4px',
                                      cursor: 'pointer',
                                      fontFamily: 'Montserrat',
                                    }}
                                    src={
                                      planValues?.images
                                        ? planValues?.images?.value
                                        : ' '
                                    }
                                  ></img>
                                </DialogContent>
                              </Dialog>
                            </Stack>
                          </Grid>
                          <Grid item xs={12} md={5.9}>
                            <Stack
                              sx={{
                                backgroundColor: 'white',
                                p: 1.5,
                                m: 1,
                                borderRadius: '5px',
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: '13px',
                                  color: '#5E6C84',
                                  pb: 1,
                                  fontFamily: 'Montserrat',
                                }}
                              >
                                Attach Document
                              </Typography>
                              <input
                                type="file"
                                name="documents"
                                onChange={handlePlanDocuments}
                                style={{
                                  border: '2px solid #5E6C84',
                                  borderRadius: '8px',
                                  padding: '8px',
                                  backgroundColor: '#ffffff',
                                  color: '#5E6C84',
                                  fontSize: '12px',
                                  fontFamily: 'Montserrat',
                                  fontWeight: '500',
                                  cursor: 'pointer',
                                  width: '70%',
                                }}
                              ></input>

                              {planValues?.document ? (
                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                <a
                                  href={
                                    typeof planValues?.document === 'object' &&
                                      'value' in planValues?.document
                                      ? (
                                        planValues?.document as {
                                          value: string;
                                        }
                                      ).value
                                      : ''
                                  }
                                >
                                  <Button
                                    variant="outlined"
                                    onClick={handleClickOpenImg}
                                    sx={{
                                      textTransform: 'capitalize',
                                      mt: { xs: 2, md: 1.5 },
                                      fontSize: '13px',

                                      width: '200px',
                                      color: '#5E6C84',
                                      fontFamily: 'Montserrat',
                                      border: '1px solid #5E6C84',
                                      ':hover': {
                                        color: '#5E6C84',
                                        border: '1px solid #5E6C84',
                                      },
                                    }}
                                    startIcon={<CloudDownloadOutlinedIcon />}
                                  >
                                    Document
                                  </Button>
                                </a>
                              ) : (
                                ''
                              )}
                            </Stack>
                          </Grid>
                        </Grid>
                      </Stack>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      sx={{
                        backgroundColor: 'white',
                        borderRadius: '5px',
                        display: { xs: 'none', md: 'block' },
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: 'white',
                          borderRadius: '5px',
                          height: '70vh',
                          p: 1,
                        }}
                      >
                        <Stack>
                          <Typography
                            sx={{
                              ml: 1,
                              mt: 1,
                              fontSize: '13px',
                              color: '#2653A3',
                              fontWeight: '500',
                              fontFamily: 'Montserrat',
                            }}
                          >
                            Your Subscription at{' '}
                          </Typography>
                          <Typography
                            sx={{
                              ml: 1,

                              fontSize: '13px',
                              color: '#000000',
                              fontWeight: '500',
                              fontFamily: 'Montserrat',
                            }}
                          >
                            {currentCommunity?.name}
                          </Typography>

                          <Divider sx={{ mt: 1 }}></Divider>
                        </Stack>
                        <Stack sx={{ m: 1 }}>
                          <Typography
                            sx={{
                              fontSize: '13px',
                              color: '#2653A3',
                              fontWeight: '500',
                              fontFamily: 'Montserrat',
                            }}
                          >
                            Subscription Plan{' '}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '13px',
                              color: '#000000',
                              fontWeight: '500',
                              fontFamily: 'Montserrat',
                            }}
                          >
                            {planValues?.name}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '12px',
                              color: '#000000',
                              fontFamily: 'Montserrat',
                            }}
                          >
                            {planValues?.description}
                          </Typography>
                          <Button
                            variant="contained"
                            sx={{
                              fontSize: '13px',
                              color: '#000000',
                              fontWeight: '500',
                              fontFamily: 'Montserrat',
                              backgroundColor: '#E0E0E0',
                              textAlign: 'left',
                              justifyContent: 'left',
                              height: '35px',
                              mt: 1,
                              boxShadow: 'none',
                              ':hover': {
                                backgroundColor: '#E0E0E0',
                                boxShadow: 'none',
                              },
                            }}
                          >
                            <Typography
                              variant="caption"
                              sx={{
                                color: '#000000',
                                fontSize: '2vh',
                                fontFamily: 'Montserrat',
                              }}
                            >
                              {showOfferValue && `₹ ${offerValue}/-`}
                              {!showOfferValue &&
                                totalPlanValue > 0 &&
                                `₹ ${totalPlanValue}/-`}
                            </Typography>
                            {showTotalPlanValue && (
                              <Stack
                                direction={'row'}
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'left'}
                              >
                                <Typography
                                  sx={{ color: '#787878', fontSize: '13px', fontFamily: 'Montserrat', }}
                                >
                                  ₹{' '}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  sx={{
                                    color: '#787878',
                                    fontSize: '2vh',
                                    textDecorationLine: 'line-through',
                                    fontFamily: 'Montserrat',
                                  }}
                                >
                                  {' '}
                                  {totalPlanValue}/-
                                </Typography>
                              </Stack>
                            )}
                          </Button>
                          <Typography
                            sx={{
                              fontSize: '12px',
                              mt: 1,
                              color: '#000000',
                              fontFamily: 'Montserrat',
                              textTransform: 'capitalize',
                            }}
                          >
                            Billed every {planValues?.interval}{' '}
                            {planValues?.interval > 1
                              ? `${getStaticValue(planValues?.duration)}s`
                              : getStaticValue(planValues?.duration)}
                          </Typography>
                        </Stack>

                        <Divider sx={{ mt: 1 }}></Divider>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Grid container sx={{ mt: 2, mb: { xs: 5, md: 0 } }}>
                  <Grid item xs={12} md={12}>
                    <Stack direction="row" spacing={1}>
                      <Button
                        size="large"
                        variant="contained"
                        sx={{
                          backgroundColor: '#3C5AA0',
                          textTransform: 'capitalize',
                          border: 'none',
                          borderRadius: '8px',
                          boxShadow: 'none',
                          color: 'white',
                          fontFamily: 'Montserrat',
                          // display: hideCreateButton ? "none" : "block",
                          '&:hover': {
                            backgroundColor: '#3C5AA0',
                            cursor: 'pointer',
                            color: 'white',
                            border: 'none',
                            boxShadow: 'none',
                          },
                        }}
                        onClick={handleClickOpenPublish}
                        disabled={!isDataAvailable && !id}
                      >
                        Publish
                      </Button>
                      {(location.pathname === '/plans' ||
                        location.pathname === '/create-plan' ||
                        location.pathname === `/plans/new/${id}`) && (
                          <Button
                            variant="outlined"
                            size="large"
                            sx={{
                              textTransform: 'capitalize',
                              // fontWeight:600,
                              display: planValues?.status === "ACTIVE" ? "none" : 'block',
                              color: '#3C5AA0',
                              border: '1px solid #3C5AA0',
                              borderRadius: '8px',
                              boxShadow: 'none',
                              fontFamily: 'Montserrat',
                              '&:hover': {
                                cursor: 'pointer',
                                border: '1px solid #3C5AA0',
                                boxShadow: 'none',
                              },
                            }}
                            onChange={(e: any) => setPlanValues(e.target.value)}
                            // onClick={handleSubmit}
                            // eslint-disable-next-line react/jsx-no-duplicate-props
                            onClick={(e) => handleSubmit(e, 'CREATED')}
                            disabled={!isDataAvailable && !id}
                          >
                            {loadingButton ? (
                              <CircularProgress
                                size={24}
                                sx={{ color: 'white' }}
                              />
                            ) : id ? (
                              'Update'
                            ) : (
                              'Create'
                            )}
                          </Button>
                        )}

                      {/* Pop Up for About us Content */}
                      <Dialog
                        fullWidth={fullWidth}
                        maxWidth={maxWidth}
                        open={opensPublish}
                        onClose={handleClickClosesPublish}
                      >
                        <DialogActions sx={{ backgroundColor: '#F0F9FF' }}>
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{ backgroundColor: '#F0F9FF' }}
                          >
                            <Typography
                              sx={{
                                fontSize: { xs: '14px', md: '16px' },
                                fontWeight: 500,
                                textDecoration: 'none',
                                color: '#3C5AA0',
                                fontFamily: 'Montserrat',

                                marginLeft: 2,
                              }}
                            >
                              {'Publish the Plan?'}
                            </Typography>
                            <Box
                              onClick={handleClickClosesPublish}
                              sx={{
                                backgroundColor: '#3C5AA0',
                                padding: '4px',
                                borderRadius: '50%',
                                width: '20px',
                                height: '20px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: 'white',
                                fontSize: '10px',
                                mt: '0.5px',
                                mr: '10px',
                                cursor: 'pointer',
                                '&:hover': {
                                  cursor: 'pointer',
                                  border: 'none',
                                },
                              }}
                            >
                              <Close sx={{ fontSize: '15px' }} />
                            </Box>
                          </Grid>
                        </DialogActions>

                        <DialogContent>
                          <DialogContentText
                            id="alert-dialog-description"
                            sx={{ fontSize: '12px', textAlign: 'center', fontFamily: 'Montserrat', }}
                          >
                            Once you publish your subscription plan, you cannot
                            make any more changes to it. This is to be
                            transparent and build trust with subscribers. Are
                            you ready to publish the plan?.
                          </DialogContentText>
                        </DialogContent>

                        <DialogActions >
                          <Button
                            onChange={(e: any) => setPlanValues(e.target.value)}
                            onClick={(e) => handleSubmit(e, 'ACTIVE')}
                            size="large"
                            variant="contained"
                            sx={{
                              backgroundColor:
                                '#3C5AA0 ',
                              textTransform: 'capitalize',
                              border: 'none',
                              borderRadius: '8px',
                              boxShadow: 'none',
                              color: 'white',
                              fontFamily: 'Montserrat',
                              '&:hover': {
                                backgroundColor:
                                  '#3C5AA0',
                                cursor: 'pointer',
                                color: 'white',
                                border: 'none',
                                boxShadow: 'none',
                              },
                            }}
                          >
                            {loadingButton ? (
                              <CircularProgress
                                size={24}
                                sx={{ color: 'white' }}
                              />
                            ) : (
                              'Publish'
                            )}
                          </Button>
                          <Button
                            size="large"
                            sx={{
                              textTransform: 'capitalize',
                              // fontWeight:600,
                              color: '#3C5AA0',
                              border: '1px solid #3C5AA0',
                              borderRadius: '8px',
                              boxShadow: 'none',
                              fontFamily: 'Montserrat',
                              '&:hover': {
                                cursor: 'pointer',
                                border: '1px solid #3C5AA0',
                                boxShadow: 'none',
                              },
                            }}
                            onClick={handleClickClosesPublish}
                          >
                            Cancel
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Stack>
                  </Grid>
                </Grid>
              </>
            </Box>
          </Stack >
        )}
      </div >
    </>
  );
};
