import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { metaConfig } from "../../../utils/constants";
import { SubNavbar } from "../../Layout/components/SubNavbar";
import { Box, Button, Card, CardContent, Grid, IconButton, Stack, Typography } from "@mui/material";
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { ContentWrapper } from "../../Layout/components/ContentWrapper";
import Loader from "../../Loader/Loader";
import { IColumn } from "../../../models/table.model";
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { AccessAlarmOutlined, CalendarMonthOutlined, CurrencyRupeeOutlined, Person2Outlined, PinDropOutlined, SupervisedUserCircleOutlined } from "@mui/icons-material";
import { EventRequestTable } from "../../tables/EventsRequestTable";
import { useEvents } from "../../../hooks/useEvents";
import { IEventData, IEvents, IUser } from "../../../models/events.models";
import dayjs from "dayjs";
import { NoEventRequest } from "../../AlternatePages/NoEventRequest";
import BreadcrumbDouble from "../../AlternatePages/BreadCumsDouble";


const columns: IColumn[] = [
    { title: 'Name', dataKey: 'name' },
    { title: 'Email', dataKey: 'email' },
    { title: 'Phone', dataKey: 'phone' },
    { title: 'Price', dataKey: 'price' },
    { title: 'Action', dataKey: 'actions' },
];



const EventRequest = () => {

    const { id } = useParams()

    const { getEventRequests, getEventById } = useEvents();

    const [eventRequest, setEventRequest] = React.useState<IEventData>();
    const [requestList, setRequestList] = React.useState<IUser[]>([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [eventDetails, setEventDetails] = React.useState<IEvents>();

    const navigate = useNavigate()

    const eventRequestList = async () => {
        setIsLoading(true);
        try {
            const response = await getEventRequests(id || '');
            setEventRequest(response);
            setRequestList(response?.users)

        } catch (err) {
            console.error('Error fetching live classes:', err);

        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (id) {
            eventRequestList();
        }
    }, []);



    const eventDetail = async () => {
        setIsLoading(true);
        try {
            const response = await getEventById(id || '');
            setEventDetails(response);
        } catch (err) {
            console.error('Error fetching live classes:', err);

        } finally {
            setIsLoading(false);
        }
    };


    useEffect(() => {
        if (id) {
            eventDetail();
        }
    }, []);



    const defaultImages = 'https://upload-community-files.s3.ap-south-1.amazonaws.com/static/community_3.png'



    return (
        <>
            <Helmet>
                <title>{metaConfig.title.appointments}</title>
                <meta name="description" content={metaConfig.description} />
                <meta name="format-detection" content="telephone=no" />
                <meta name="author" content={metaConfig.author} />
                <meta name="keywords" content={metaConfig.keywords} />
            </Helmet>
            <BreadcrumbDouble
                items={[
                    { label: 'Events', path: '/events' },
                    { label: 'Event Requests' }
                ]}
                actionButton={{ label: 'Events', path: '/events' }}
            />

            <Grid container gap={1.5} py={1}>
                <Grid item xs={12} md={12}>
                    <Card elevation={0}
                        sx={{
                            border: '1px solid #D4DDED',
                            borderRadius: '8px'
                        }}
                    >
                        <CardContent
                            sx={{
                                p: 0,
                                "&:last-child": {
                                    pb: '1px',
                                },
                            }}>

                            <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={1} sx={{ p: 1 }}>
                                <Stack flexDirection={'row'} gap={{ xs: 2, md: 8 }} alignItems={'center'} p={2}>
                                    <Stack spacing={0.5} textAlign={'center'}>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Montserrat',
                                                fontSize: '12px',
                                                fontWeight: '500',
                                                color: '#3760A9',
                                            }}
                                        >
                                            {dayjs(eventDetails?.availability?.[0]?.day).format('MMM').toUpperCase()}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Montserrat',
                                                fontSize: '25px',
                                                fontWeight: '600',
                                                color: '#3760A9',
                                            }}
                                        >
                                            {dayjs(eventDetails?.availability?.[0]?.day).format('DD').toUpperCase()}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Montserrat',
                                                fontSize: '12px',
                                                fontWeight: '500',
                                                color: '#3760A9',
                                            }}
                                        >
                                            {dayjs(eventDetails?.availability?.[0]?.day).format('dddd').toUpperCase()}
                                        </Typography>
                                    </Stack>
                                    <Stack spacing={0.3}>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Montserrat',
                                                fontSize: { xs: '13px', md: '14px' },
                                                fontWeight: '600',
                                                color: '#000000',
                                            }}
                                        >
                                            {eventDetails?.title && eventDetails.title.length > 20
                                                ? `${eventDetails.title.slice(0, 17)}...`
                                                : eventDetails?.title || ""}
                                        </Typography>

                                        <Stack direction="row" alignItems="center" gap={1}>
                                            <CalendarMonthOutlined
                                                sx={{ fontSize: '18px', color: '#565656' }}
                                            />
                                            <Typography
                                                sx={{
                                                    fontSize: { xs: '9px', md: '12px' },
                                                    fontWeight: '400',
                                                    fontFamily: 'Montserrat',
                                                    color: '#565656'
                                                }}
                                            >
                                                {dayjs(eventDetails?.availability[0]?.day).format('MMM D, YYYY')} to {dayjs(eventDetails?.availability?.[eventDetails?.availability?.length - 1]?.day).format('MMM D, YYYY')}
                                            </Typography>
                                        </Stack>
                                        <Stack direction="row" alignItems="center" gap={1}>
                                            <AccessAlarmOutlined sx={{ fontSize: '18px', color: '#565656' }} />
                                            <Typography sx={{ fontSize: { xs: '9px', md: '12px' }, fontWeight: '400', fontFamily: 'Montserrat', color: '#565656' }}>
                                                {eventDetails?.availability?.[0]?.availableTimes
                                                    ?.map((time) => `${time?.startTime} - ${time?.endTime}`)
                                                    .join(' to ')}
                                            </Typography>
                                        </Stack>
                                        <Stack direction="row" alignItems="center" gap={1}>
                                            <CurrencyRupeeOutlined
                                                sx={{ fontSize: '18px', color: '#565656' }}
                                            />
                                            <Typography
                                                sx={{
                                                    fontSize: { xs: '9px', md: '12px' },
                                                    fontWeight: '400',
                                                    fontFamily: 'Montserrat',
                                                    color: '#565656'
                                                }}
                                            >
                                                {eventDetails?.pricing ? `${eventDetails.pricing}` : "Free"}
                                            </Typography>
                                        </Stack>
                                        <Stack direction="row" alignItems="center" gap={1}>
                                            <PinDropOutlined
                                                sx={{ fontSize: '18px', color: '#565656' }}
                                            />
                                            <Typography
                                                sx={{
                                                    fontSize: { xs: '9px', md: '12px' },
                                                    fontWeight: '400',
                                                    fontFamily: 'Montserrat',
                                                    color: '#565656'
                                                }}
                                            >
                                                {eventDetails?.location && eventDetails?.location?.length > 20
                                                    ? `${eventDetails?.location.slice(0, 17)}...`
                                                    : eventDetails?.location || ""}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>

                                <Stack sx={{ backgroundColor: '#F0F9FF', borderRadius: '10px', padding: { xs: 0.5, md: 2 } }}>

                                    <Box
                                        component={'img'}
                                        src={eventDetails?.coverImage?.value
                                            ? `${eventDetails.coverImage.value}`
                                            : `${defaultImages}`}
                                        width="100%"
                                        sx={{ borderRadius: '10px', objectFit: 'cover', height: { xs: '50px', md: '80px' } }} />

                                </Stack>
                            </Stack>

                            <Stack sx={{ backgroundColor: '#F2F2F2', p: 2 }}>
                                <Stack direction={'row'} justifyContent={'space-between'}>
                                    <Stack direction="row" spacing={2}>
                                        <Stack direction="row" alignItems="center" gap={0} >
                                            <Person2Outlined
                                                sx={{ fontSize: '15px', color: '#565656' }}
                                            />
                                            <Typography
                                                sx={{
                                                    fontSize: { xs: '10px', md: '12px' },
                                                    fontWeight: '400',
                                                    fontFamily: 'Montserrat',
                                                    color: '#565656'
                                                }}
                                            >
                                                By {eventDetails?.hostedBy}
                                            </Typography>
                                        </Stack>
                                        <Stack direction="row" alignItems="center" gap={0.5}>
                                            <SupervisedUserCircleOutlined
                                                sx={{ fontSize: '15px', color: '#565656' }}
                                            />
                                            <Typography
                                                sx={{
                                                    fontSize: { xs: '10px', md: '12px' },
                                                    fontWeight: '400',
                                                    fontFamily: 'Montserrat',
                                                    color: '#565656'
                                                }}
                                            >
                                                {eventDetails?.limitCapacity || 0}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </CardContent>
                    </Card>
                </Grid>

            </Grid>


            <Stack sx={{ pt: '5px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <ContentWrapper>
                            {isLoading ? (
                                <Loader />
                            ) : (
                                <>
                                    {(!requestList || requestList.length === 0) ? (
                                        <NoEventRequest />
                                    ) : (
                                        <EventRequestTable
                                            eventRequestData={eventRequest || undefined}
                                            data={requestList || []}
                                            columns={columns}
                                            eventRequestList={eventRequestList}
                                        />
                                    )}
                                </>
                            )}
                        </ContentWrapper>
                    </Grid>
                </Grid>
            </Stack>

        </>
    )
}
export default EventRequest;