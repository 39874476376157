import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Stack } from '@mui/system';
import { CustomTableHeader } from './components/CustomTableHeader';
import { IAction, IColumn } from '../../models/table.model';
import { ContactCell } from './components/ContactCell';
import { useEffect, useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Popover,
  TableContainer,
  TablePagination,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Close,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@mui/icons-material';
import React from 'react';
import { getStaticValue } from '../../utils/StaticValues';
import { RoleTypeCell } from './components/RoleTypeCell';
import { CommunityMembers, IPlan } from '../../models/community-user.model';
import { useSelector } from 'react-redux';
import { useCommunity } from '../../hooks/useCommunity';
import { useUsers } from '../../hooks/useUsers';
import { useSnackbar } from 'notistack';
import { AdminPerson, DeleteIcon } from '../../assets/icons';
import { useNavigate } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import { INotes } from '../../models/user.model';

interface ITableProps {
  data: CommunityMembers[];
  columns: IColumn[];
  onActionClick: (
    a: IAction,
    data: any,
    e?: React.MouseEvent<HTMLButtonElement>
  ) => void;
  checkboxes: { [key: string]: boolean };
  //  handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;

  loadUserList: () => void;
}

export const UsersTable = ({
  columns,
  data,
  onActionClick,
  loadUserList,
}: ITableProps) => {
  const [sortedColumns, setSortedColumns] = useState<string[]>([]);
  const [tableData, setTableData] = useState<CommunityMembers[]>(data);

  console.log(data, "data");

  const [sortDirections, setSortDirections] = useState<{
    [key: string]: 'asc' | 'desc' | undefined;
  }>({});

  const loggedInUserData = useSelector((state: any) => state.loggedInUser);

  // console.log(loggedInUserData, "loggedInUserData");

  useEffect(() => {
    setTableData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleSort = (columnTitle: string) => {
    setSortDirections((prevSortDirections) => {
      let newSortDirections: { [key: string]: 'asc' | 'desc' | undefined } = {};
      let newSortedColumns: string[] = [];

      // Toggle the sorting direction for the clicked column
      if (prevSortDirections[columnTitle] === 'asc') {
        newSortDirections[columnTitle] = 'desc';
      } else {
        newSortDirections[columnTitle] = 'asc';
      }

      // Include the clicked column in the list of sorted columns
      newSortedColumns.push(columnTitle);

      setSortedColumns(newSortedColumns);

      return newSortDirections;
    });
  };
  const sortedData = [...tableData];

  const sortedColumnTitles = sortedColumns?.filter(
    (columnTitle) => sortDirections[columnTitle] !== undefined
  );

  if (sortedColumnTitles?.length > 0) {
    sortedData?.sort((a: CommunityMembers, b: CommunityMembers) => {
      for (const columnTitle of sortedColumnTitles) {
        const sortDirection = sortDirections[columnTitle];
        const column = columns?.find((col) => col.title === columnTitle);

        if (column) {
          if (column.dataKey === 'user') {
            // Sorting for the "admin" column based on the "name" property
            const aValue = `${a.user?.firstName} ${a.user?.lastName}`;
            const bValue = `${b.user?.firstName} ${b.user?.lastName}`;

            if (sortDirection === 'asc') {
              return aValue?.localeCompare(bValue);
            } else {
              return bValue?.localeCompare(aValue);
            }
          }
          if (column.dataKey === 'contact') {
            // Sorting for the "admin" column based on the "name" property
            const aValue = a?.user?.emailId;
            const bValue = b?.user?.emailId;

            if (sortDirection === 'asc') {
              return aValue?.localeCompare(bValue);
            } else {
              return bValue?.localeCompare(aValue);
            }
          }
          if (column?.dataKey === 'community') {
            // Sorting for the "admin" column based on the "name" property
            const aValue = a?.community?.name;
            const bValue = b?.community?.name;

            if (sortDirection === 'asc') {
              return aValue?.localeCompare(bValue);
            } else {
              return bValue?.localeCompare(aValue);
            }
          }
          if (column.dataKey === 'subscription') {
            // Sorting for the "admin" column based on the "name" property
            const aValue = a?.subscription;
            const bValue = b?.subscription;

            if (sortDirection === 'asc') {
              return aValue?.localeCompare(bValue);
            } else {
              return bValue?.localeCompare(aValue);
            }
          }
          if (column.dataKey === 'roleType') {
            // Sorting for the "admin" column based on the "name" property
            const aValue = a?.type;
            const bValue = b?.type;

            if (sortDirection === 'asc') {
              return aValue.localeCompare(bValue);
            } else {
              return bValue.localeCompare(aValue);
            }
          } else if (column.dataKey === 'joinedOn') {
            // Sorting for the "category" column
            const aValueDate = new Date(a?.createdAt);
            const bValueDate = new Date(b?.createdAt);

            if (aValueDate.getTime() !== bValueDate.getTime()) {
              // If the dates are not the same, sort based on date
              if (sortDirection === 'asc') {
                return aValueDate.getTime() - bValueDate.getTime();
              } else {
                return bValueDate.getTime() - aValueDate.getTime();
              }
            } else {
              // If dates are the same, include time for sorting
              const aValueTime = new Date(
                `${a?.createdAt}T${a?.createdAtTime}`
              );
              const bValueTime = new Date(
                `${b?.createdAt}T${b?.createdAtTime}`
              );

              if (sortDirection === 'asc') {
                return aValueTime.getTime() - bValueTime.getTime();
              } else {
                return bValueTime.getTime() - aValueTime.getTime();
              }
            }
          } else if (column.dataKey === 'status') {
            // Sorting for the "created/updated" column based on date and time
            const aValue = a?.status;
            const bValue = b?.status;

            if (sortDirection === 'asc') {
              return aValue?.localeCompare(bValue);
            } else {
              return bValue?.localeCompare(aValue);
            }
          } else {
            // If dates are the same, include time for sorting
            // const aValueTime = new Date(
            //   `${a.joinedOn}T${a.joinedOn}`
            // );
            // const bValueTime = new Date(
            //   `${b.joinedOn}T${b.joinedOn}`
            // );
            //   if (sortDirection === "asc") {
            //     return aValueTime.getTime() - bValueTime.getTime();
            //   } else {
            //     return bValueTime.getTime() - aValueTime.getTime();
            //   }
            // }
          }
          // Default sorting for other columns
          // const aValue = a[column.dataKey];
          // const bValue = b[column.dataKey];

          if (sortDirection === 'asc') {
            //  return aValue.localeCompare(bValue);
          } else {
            // return bValue.localeCompare(aValue);
          }
        }
      }
      return 0;
    });
  }

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    // console.log(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    handleChangePage(null, 0);
  }, [data]);
  const startIndex = page * rowsPerPage;
  const endIndex = (page + 1) * rowsPerPage;
  const paginatedData = sortedData.slice(startIndex, endIndex);
  // const maxRowsPerPage = tableData.length;
  // const rowsPerPageOptions = [1, 2, 5, 10].filter(
  //   (option) => option <= maxRowsPerPage
  // );
  const { communityUserRole } = useCommunity();
  const { removeUserFromCommunityUserById, userNotes, getUserNotes, userUpdateNotes } = useUsers();
  // const { leaveCommunityUserById, removeUserFromCommunityUserById } = useUsers();
  const [openModals, setOpenModals] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<CommunityMembers | null>(null);
  const [roleSwitch, setRoleSwitch] = useState<boolean>(false);
  const [slug, setSlug] = useState(selectedRow?.slug);
  const { enqueueSnackbar } = useSnackbar();

  const [description, setDescription] = useState('');
  const [notesData, setNotesData] = useState<INotes>();
  // console.log(description);
  const [notesLoading, setNotesLoading] = useState(false);

  // console.log(description, "notes");

  const handleOpen = (row: CommunityMembers) => {
    setSelectedRow(row);
    if (loggedInUserData.user.id !== selectedRow?.user._id) {
      setOpenModals(true);
      setRoleSwitch(row.type === 'Admin' || row.type === 'ADMIN');
      setSlug(row.slug);
    }
  };

  const handleClose = () => {
    setOpenModals(false);
  };

  const HandleAssignSubmit = async () => {
    if (selectedRow) {
      try {
        setIsLoading(true);
        const type = roleSwitch ? 'ADMIN' : 'USER';
        const userId = selectedRow._id;
        const community_id = selectedRow.community._id;
        const formData = {
          type: type,
          slug: slug,
        };
        await communityUserRole(community_id, userId, formData);

        enqueueSnackbar('Role Set Successfully', { variant: 'success' });
        loadUserList();
        const updatedTableData = tableData.map((row: CommunityMembers) =>
          row._id === selectedRow._id
            ? { ...row, type: type, slug: slug || '' }
            : row
        );
        setTableData(updatedTableData);
      } catch (error) {
        console.log('error', error);
        enqueueSnackbar('An error occured while setting a role', {
          variant: 'error',
        });
      } finally {
        setIsLoading(false);
        handleClose();
      }
    }
  };



  const handleNotesSubmit = async () => {
    if (selectedRow) {
      try {
        setNotesLoading(true);
        // Extract necessary fields
        const userId = selectedRow?.user?._id;
        const community_id = selectedRow.community._id;

        // Prepare the notes data
        const notesData: INotes = {
          description: description ?? "",
          community: community_id,
          user: userId,
        };

        const existingNotesResponse = await getUserNotes(community_id, userId);
        const notesId = existingNotesResponse?._id;

        let response;

        if (notesId) {
          // Update notes if they already exist
          response = await userUpdateNotes(notesId, community_id, notesData?.description);
          if (response) {
            enqueueSnackbar('Notes updated successfully', { variant: 'success' });
          }
        } else {
          response = await userNotes(notesData);
          if (response) {
            enqueueSnackbar('Notes saved successfully', { variant: 'success' });
          }
        }
        loadUserList();
        fetchUserNotes();
      } catch (error) {
        console.error('Error while submitting notes:', error);
        enqueueSnackbar('An error occurred while saving the notes', { variant: 'error' });
      } finally {
        setNotesLoading(false);
        handleClose();
      }
    }
  };




  const fetchUserNotes = async () => {
    if (selectedRow) {
      try {
        const userId = selectedRow?.user?._id;
        const community_id = selectedRow.community._id;
        const response = await getUserNotes(community_id, userId)
        console.log(response?.description, "response")
        setNotesData(response)
      } catch (err) {
        console.log(err, "error")
      }
    }
  }
  useEffect(() => {
    if (selectedRow) {
      fetchUserNotes();
    }
  }, [selectedRow]);



  //delete user
  const [deleteAnchorEl, setDeleteAnchorEl] = useState<null | HTMLElement>(
    null
  );

  const openDeletePopover = Boolean(deleteAnchorEl);
  const idDeletePopover = openDeletePopover ? 'delete-popover' : undefined;

  const handleDeleteUser = async (e: React.MouseEvent<HTMLButtonElement>) => {
    setDeleteAnchorEl(e.currentTarget);
  };

  const handleDeleteClosePopover = () => {
    setDeleteAnchorEl(null);
    setSelectedRow(null);
  };

  // const handleDeleteUserById = async () => {
  //   if (selectedRow) {
  //     await leaveCommunityUserById(selectedRow._id);
  //     handleDeleteClosePopover();
  //   }
  // };

  const selectedCommunity = useSelector(
    (state: any) => state.selectedCommunity
  );

  // console.log("selectedRow", selectedRow?.user?._id,);

  //status

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [statusPopoverAnchor, setStatusPopoverAnchor] = useState<null | HTMLElement>(null);
  const openPopOver = Boolean(statusPopoverAnchor);
  const idPopOver = openPopOver ? 'simple-popover' : undefined;
  const [isLoading, setIsLoading] = useState(false);
  const [notesOpen, setOpenNotes] = useState(false);

  const handleOpenNotes = () => {
    setOpenNotes(true)
  }

  const handleCloseNotes = () => {
    setOpenNotes(false);
  }

  const handleStatusClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handlePopOverClick = () => {
    //  if( selectedRow?.addedBy !== selectedRow?.user?._id){
    setStatusPopoverAnchor(anchorEl);
    handleMenuClose();
    //  }
  };
  const handleCloseStatusPopOver = () => {
    setStatusPopoverAnchor(null);
  };
  const handleStatusUpdate = async () => {
    if (selectedRow) {
      try {
        setIsLoading(true);
        const userId = selectedRow._id;
        const communityId = selectedRow?.community._id;
        const newStatus = selectedRow.status === 'ACTIVE' ? 'BLOCK' : 'ACTIVE';
        const formData = {
          status: newStatus,
        };
        await communityUserRole(communityId, userId, formData);
        enqueueSnackbar('Status updated Successfully', { variant: 'success' });
        handleCloseStatusPopOver();
        setSelectedRow(null);

        const updatedData = data.map((item) =>
          item._id === selectedRow._id ? { ...item, status: newStatus } : item
        );
        setTableData(updatedData);
        loadUserList();
      } catch (error) {
        // console.log('error', error);
        enqueueSnackbar('An error occured while updating a Status', {
          variant: 'error',
        });
      } finally {
        setIsLoading(false);
      }
    }
  };
  const handleRemoveUserByMappingId = async () => {
    if (selectedRow) {
      try {
        setIsLoading(true);
        await removeUserFromCommunityUserById(
          selectedCommunity?.selectedCommunity?._id,
          selectedRow.user?._id
        );
        handleDeleteClosePopover();
        loadUserList();
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const navigate = useNavigate();
  const handlenavigateUser = (id: string) => {
    navigate(`/posts/profile/${id}`);
  };

  // useEffect(() => {
  //   if (notesData?.description) {
  //     setDescription(notesData?.description);
  //   }
  // }, [notesData]);


  return (
    <>
      {' '}
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openModals}
        onClose={() => handleClose()}
        PaperProps={{
          style: {
            width: '100%',
          },
        }}
      >
        <>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ pt: '3px', backgroundColor: '#F0F9FF', height: '50px' }}
          >
            <Typography
              sx={{
                color: '#3C5AA0',
                fontSize: { xs: '16px', md: '18px' },
                fontWeight: '500',
                backgroundColor: '#F0F9FF',
                fontFamily: 'Montserrat',
                px: 2,
              }}
            >
              Assign Role / Identity
            </Typography>

            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              sx={{
                backgroundColor: '#3C5AA0',
                padding: '4px',
                borderRadius: '50%',
                width: '20px',
                height: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                fontSize: '10px',
                mt: '0.5px',
                mr: '10px',
                cursor: 'pointer',
              }}
            >
              <IconButton onClick={() => handleClose()}>
                <Close sx={{ fontSize: '15px', color: '#FFFFFF' }} />
              </IconButton>
            </Stack>
          </Grid>
          {selectedRow && (
            <Stack
              display={'flex'}
              // alignItems={"center"}
              // justifyContent={"center"}
              spacing={2}
            >
              <Typography
                sx={{ color: 'black', fontSize: { xs: '12px', md: '14px' }, pt: 1, px: 2, textAlign: 'center', fontFamily: 'Montserrat', }}>
                Assign identity that {selectedRow?.user?.firstName} holds in the community. e.g. Instructor
              </Typography>
              <Stack
                sx={{
                  paddingLeft: '5px',
                  // backgroundColor: "#B9B9B9",
                  width: '100%',
                  borderRadius: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  px: 2,
                }}
              >
                <Stack
                  gap={1}
                  flexDirection={'row-reverse'}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    component={'button'}
                    sx={{
                      cursor:
                        loggedInUserData.user.id !== selectedRow.user._id
                          ? 'pointer'
                          : null,
                      color: roleSwitch ? '#000000' : '#FFFFFF',
                      border: '1px solid #F0F0F0',
                      backgroundColor: roleSwitch ? '#FFFFFF' : '#3C5AA0',
                      borderRadius: '100px',
                      px: 1.2,
                      minHeight: { xs: '30px', md: '35px' },
                      maxHeight: { xs: '30px', md: '35px' },
                      minWidth: { md: '75%', xs: '70%' },
                      maxWidth: { md: '75%', xs: '70%' },
                      fontFamily: 'Montserrat',
                      fontSize: { xs: '14px', md: '16px' }

                    }}
                    onClick={() => {
                      if (loggedInUserData.user.id !== selectedRow.user._id)
                        setRoleSwitch(false);
                    }}
                  >
                    Member
                  </Typography>
                  <Typography
                    component="button"
                    sx={{
                      cursor: 'pointer',
                      color: roleSwitch ? '#FFFFFF' : '#000000',
                      border: '1px solid #F0F0F0',
                      backgroundColor: roleSwitch ? '#3C5AA0' : '#FFFFFF',
                      px: 1.2,
                      borderRadius: '100px',
                      minHeight: { xs: '30px', md: '35px' },
                      maxHeight: { xs: '30px', md: '35px' },
                      minWidth: { md: '75%', xs: '70%' },
                      maxWidth: { md: '75%', xs: '70%' },
                      fontFamily: 'Montserrat',
                      fontSize: { xs: '14px', md: '16px' }
                    }}
                    onClick={() => setRoleSwitch(true)}
                  >
                    Admin
                  </Typography>
                </Stack>

                <Typography sx={{ fontSize: '13px', pt: 1, fontFamily: 'Montserrat', }}>OR</Typography>
              </Stack>
              <Stack
                sx={{
                  px: 2,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <TextField
                  label="Enter a custom Role Tag"
                  placeholder="Eg. Gym Trainer"
                  size="small"
                  fullWidth
                  InputLabelProps={{ style: { fontSize: '13px', fontFamily: 'Montserrat', } }}
                  InputProps={{
                    style: { fontSize: '12px', fontFamily: 'Montserrat', },

                  }}
                  variant="outlined"
                  sx={{
                    borderRadius: '30px', maxWidth: '300px',
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '8px',
                    },
                  }}
                  value={slug}
                  onChange={(e: any) => setSlug(e.target.value)}
                />
              </Stack>

              <Box
                sx={{
                  mt: 1.5,
                  display: 'flex',
                  px: 2,
                  pb: 2,
                  flexDirection: { xs: 'column', sm: 'row' },
                  gap: { xs: 1, sm: 2, md: 2 },
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    textTransform: 'capitalize',
                    borderRadius: '8px',
                    boxShadow: 'none',
                    fontFamily: 'Montserrat',
                    backgroundColor: "#3C5AA0",
                    '&:hover': {
                      backgroundColor: '#50A1CA',
                      cursor: 'pointer',
                      border: 'none',
                    },
                  }}
                  // onClick={() => {
                  //   handleAssign(order, alignment || "", slug || "");
                  //   handleClose();
                  // }}
                  onClick={HandleAssignSubmit}
                >
                  {isLoading ? (
                    <CircularProgress size={24} sx={{ color: '#ffffff' }} />
                  ) : (
                    'Assign'
                  )}
                </Button>
              </Box>
            </Stack>
          )}
        </>
      </Dialog>

      <Dialog open={notesOpen}
        fullWidth
        maxWidth="md"
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ pt: '3px', backgroundColor: '#F0F9FF', height: '50px' }}
        >
          <Typography
            sx={{
              color: '#3C5AA0',
              fontSize: { xs: '14px', md: '16px' },
              fontWeight: '500',
              backgroundColor: '#F0F9FF',
              textTransform: 'capitalize',
              fontFamily: 'Montserrat',
              px: 2,
            }}
          >
            Write About {selectedRow?.user?.firstName}
          </Typography>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            sx={{
              backgroundColor: '#3C5AA0',
              padding: '4px',
              borderRadius: '50%',
              width: '20px',
              height: '20px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
              fontSize: '10px',
              mt: '0.5px',
              mr: '10px',
              cursor: 'pointer',
            }}
          >
            <IconButton onClick={() => handleCloseNotes()}>
              <Close sx={{ fontSize: '15px', color: '#FFFFFF' }} />
            </IconButton>
          </Stack>
        </Grid>
        <DialogContent>
          <TextField
            label="Write about member"
            size="small"
            fullWidth
            multiline
            rows={15}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            sx={{
              fontFamily: 'Montserrat',
              '& .MuiInputLabel-root': {
                fontFamily: 'Montserrat',
              },
            }}
          />
          <Stack display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
            <Button

              variant="contained"
              sx={{
                mt: 2,
                textTransform: 'capitalize',
                borderRadius: '8px',
                boxShadow: 'none',
                width: '150px',
                fontFamily: 'Montserrat',
                backgroundColor: ' #3C5AA0 ',
                '&:hover': {
                  backgroundColor: '#3C5AA0',
                  cursor: 'pointer',
                  border: 'none',
                  boxShadow: 'none',
                },
              }}
              onClick={handleNotesSubmit}
            >
              {notesLoading ? (
                <CircularProgress size={24} sx={{ color: 'white' }} />
              ) : (
                `${notesData?.description && notesData?.description.length > 0 ? "Update" : "Submit"}`
              )}

            </Button>
          </Stack>
        </DialogContent>
      </Dialog>



      <PerfectScrollbar>
        <Box sx={{ display: { xs: 'none', md: 'block' }, borderRadius: '12px !important', border: '1px solid #E7EBF1', }}>
          <TableContainer
            component={Paper}
            elevation={0}
            sx={{
              height: { xs: 'calc(100vh - 38vh)', md: 'calc(100vh - 24vh)' },

            }}
          >
            <Table
              sx={{
                '& th': {
                  fontSize: '12px',
                  fontWeight: 700,
                  color: '#565656',
                  p: 1,
                  fontFamily: 'Montserrat',
                },
                '& td': {
                  fontSize: '15px',
                  fontWeight: 500,
                  fontFamily: 'Montserrat',
                  color: '#1A1A1A',
                  p: '5px 10px',
                  '& .MuiTypography-root': { m: 0, fontSize: '11px' },
                },
              }}
            >
              <TableHead
                style={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 1,
                  background: '#ffffff',
                }}
              >
                <TableRow sx={{}}>
                  {columns?.map((column: IColumn, i: number) => (
                    <TableCell
                      key={i}
                      onClick={() => handleSort(column.title)}
                      sx={{}}
                    >
                      <CustomTableHeader
                        title={column.title}
                        sortDirection={sortDirections[column.title]}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {/* <NoPosts /> */}
                {paginatedData?.map((item: CommunityMembers, i: number) => (
                  <TableRow hover key={i}>
                    <TableCell>
                      <Stack
                        flexDirection={'row'}
                        alignItems={'center'}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => handlenavigateUser(item?.user?._id)}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Avatar
                            src={item?.user?.avatar}
                            sx={{
                              height: '30px',
                              width: '30px',
                              fontSize: '11px',
                            }}
                            alt={`${item?.user?.firstName}`}
                          />
                          <Typography
                            style={{
                              marginLeft: '8px',
                              fontWeight: 600,
                              fontSize: '11px !important',
                              color: '#1A1A1A',
                              flexDirection: 'row',
                              minWidth: 100,
                              fontFamily: 'Montserrat',
                            }}
                          >
                            <Tooltip
                              title={`${item?.user?.firstName} ${item?.user?.lastName}`}
                              placement="bottom"
                            >
                              <Stack>
                                {item?.user?.firstName?.length > 15
                                  ? `${item?.user?.firstName?.substring(
                                    0,
                                    15
                                  )}...`
                                  : `${item?.user?.firstName || " "} ${item?.user?.lastName || " "}`}
                              </Stack>
                            </Tooltip>

                            <Stack sx={{ p: 0 }}>
                              {item?.slug === 'Owner/Director' && (
                                <AdminPerson />
                              )}
                            </Stack>
                          </Typography>
                        </div>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      {columns[1].dataKey === 'contact' && (
                        <Tooltip
                          title={
                            <Stack>
                              <Typography sx={{ fontSize: '11px', fontFamily: 'Montserrat' }}>
                                {' '}
                                {item?.user?.phoneNumber}
                              </Typography>
                              <Typography sx={{ fontSize: '11px', fontFamily: 'Montserrat' }}>
                                {item?.user?.emailId}
                              </Typography>
                            </Stack>
                          }
                          placement="bottom"
                        >
                          <Stack>
                            <ContactCell
                              list={[
                                {
                                  item: item?.user?.phoneNumber,
                                  isVerified: item?.user?.isPhoneVerified,
                                },
                                {
                                  item:
                                    item?.user?.emailId?.length > 20
                                      ? `${item?.user?.emailId?.substring(
                                        0,
                                        20
                                      )}...`
                                      : item.user?.emailId,
                                  isVerified: item?.user?.isEmailVerified,
                                },
                              ]}
                            />
                          </Stack>
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell>
                      {columns[2].dataKey === 'community' && (
                        // <TextContentCell
                        // title={item.community.name}
                        // subTitle={`${item.} ${item.joinedOn}`}
                        // />
                        <Tooltip
                          title={item.community.name}
                          placement="left-start"
                          arrow
                          sx={{ fontFamily: 'Montserrat' }}
                        >
                          <Avatar src={item?.community.logo}></Avatar>
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell>
                      {item.plans && Array.isArray(item.plans) && item.plans.length > 0 ? (
                        <Tooltip
                          title={item.plans.length > 1 ? item.plans.map(plan => plan.name).join(', ') : ''}
                          arrow
                        >
                          <Typography
                            sx={{ textTransform: 'capitalize', fontFamily: 'Montserrat', cursor: item.plans.length > 2 ? 'pointer' : 'default' }}
                          >
                            {item.plans.slice(0, 1).map((plan: IPlan, index: number) => (
                              <React.Fragment key={index}>
                                {index > 0 && ', '}
                                <span>{plan.name}</span>
                              </React.Fragment>
                            ))}
                            {item.plans.length > 1 && (
                              <>
                                {','} (+{item.plans.length - 1})
                              </>
                            )}
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography sx={{ fontFamily: 'Montserrat' }}>NA</Typography>
                      )}
                    </TableCell>
                    <TableCell
                      key={i}
                      onClick={() => {
                        if (item.slug !== "Owner/Director") {
                          handleOpen(item);
                        }
                      }}
                      sx={{ textTransform: 'capitalize', cursor: 'pointer' }}
                    >
                      <RoleTypeCell
                        text={item.type}
                        type={
                          getStaticValue(item.type) === 'Admin' ||
                            item.type === 'Admin'
                            ? 'success'
                            : item.type === 'User' || 'USER'
                              ? 'warning'
                              : 'error'
                        }
                        slug={item.slug}
                      />
                    </TableCell>

                    <TableCell>
                      <Stack sx={{ minWidth: 65 }}>
                        <Typography sx={{ fontFamily: 'Montserrat', }}>{item?.createdAt}</Typography>
                        <Typography
                          sx={{ m: 0, fontSize: '10px', color: '#56565680', fontFamily: 'Montserrat', }}
                        >
                          {item.createdAtTime}
                        </Typography>
                      </Stack>
                    </TableCell>

                    <TableCell>
                      <Grid container>
                        <Grid item xs={12} md={12} lg={8}>
                          <Box
                            sx={{
                              fontSize: '13px',
                              fontWeight: '400',
                              width: '100px',
                              boxShadow: 'none',
                              textAlign: 'center',
                              borderRadius: '4px',
                              textTransform: 'capitalize',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              fontFamily: 'Montserrat',
                              p: 0.2,
                              cursor: selectedRow?.community?.createdBy === selectedRow?.user?._id ? null : 'pointer',
                              backgroundColor: item.status === 'BLOCK' ? '#ffa87d1a' : '#10a00d1a',
                              color: item.status === 'BLOCK' ? '#FF8143' : '#10A00D',
                              border: item.status === 'BLOCK' ? '1px solid #ffa87d4d' : '1px solid rgba(16, 160, 13, 0.3)',
                            }}
                            onClick={(e) => {
                              handleStatusClick(e);
                              setSelectedRow(item);
                            }}
                          >
                            <ArrowDropDownIcon sx={{ cursor: 'pointer' }} />
                            <Typography
                              sx={{
                                textAlign: 'center',
                                pr: 1,
                                fontSize: '13px',
                                fontFamily: 'Montserrat',
                              }}
                            >
                              {getStaticValue(item.status)}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>

                      {/* Conditionally render the Menu */}
                      {selectedRow?.community?.createdBy !== selectedRow?.user?._id && (
                        <Menu
                          id={`menu-${i}`}
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleMenuClose}
                          elevation={0}
                          MenuListProps={{
                            'aria-labelledby': 'basic-button',
                            disablePadding: true,
                          }}
                        >
                          <MenuItem
                            onClick={() => handlePopOverClick()}
                            sx={{
                              backgroundColor: selectedRow?.status === 'ACTIVE' ? '#ffa87d1a' : '#10a00d1a',
                              color: selectedRow?.status === 'ACTIVE' ? '#FF8143' : '#10A00D',
                              border: selectedRow?.status === 'ACTIVE' ? '1px solid #ffa87d4d' : '1px solid rgba(16, 160, 13, 0.3)',
                              px: 2,
                              borderRadius: '4px',
                              boxShadow: 'none',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              textAlign: 'center',
                              width: '100px',
                              minHeight: '30px',
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: 'Montserrat',
                                fontSize: '12px',
                                textAlign: 'center',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                              }}>
                              {selectedRow?.status === 'ACTIVE' ? 'Block' : 'Active'}
                            </Typography>
                          </MenuItem>
                        </Menu>
                      )}
                    </TableCell>

                    <TableCell>
                      <Button
                        onClick={(e: any) => {
                          handleDeleteUser(e);
                          setSelectedRow(item);
                        }}
                        disabled={item?.type === 'ADMIN'}
                        sx={{ color: 'gray' }}
                      >
                        <MoreVertIcon />
                      </Button>
                    </TableCell>

                    <TableCell>
                      <Button
                        onClick={(e: any) => {
                          handleOpenNotes()
                          setSelectedRow(item);
                        }}
                        // disabled={item?.type === 'ADMIN'}
                        sx={{ color: 'gray', }}
                      >
                        <NoteAltOutlinedIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack
            direction={'row-reverse'}
            alignItems={'center'}
            sx={{
              color: '#A5A5A5',
              fontSize: { xs: '10px', md: '1.5vh' },
              pr: { xs: '0px', md: '25px' },
            }}
          >
            <Typography sx={{ color: '#A5A5A5', fontSize: '13px', fontFamily: 'Montserrat', }}>
              {data?.length > 0
                ? `Showing ${Math.min(
                  rowsPerPage,
                  data.length - page * rowsPerPage
                )} of ${data.length} entries`
                : 'Showing 0 of 0 Entries'}
            </Typography>
            <TablePagination
              // rowsPerPageOptions={rowsPerPageOptions}
              rowsPerPageOptions={[5, 10, 15, 20]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={
                <span style={{ fontFamily: 'Montserrat', fontSize: '14px' }}>View</span>
              }
              labelDisplayedRows={() => null}
              SelectProps={{
                SelectDisplayProps: {
                  style: { border: '1px solid #A5A5A5', borderRadius: '5px' },
                },
                // You can also customize the style of the menu options if desired
                MenuProps: {
                  PaperProps: { style: { border: '1px solid #A5A5A5' } },
                },
              }}
              ActionsComponent={({ onPageChange, page }) => {
                const totalPages = Math.ceil(data.length / rowsPerPage);

                return (
                  <Stack
                    direction={'row'}
                    display="flex"
                    alignItems="center"
                    justifyContent={'space-between'}
                  >
                    {/* <IconButton
                      onClick={(event) => onPageChange(event, 0)} // Go to the first page
                      disabled={page === 0}
                      aria-label="go to first page"
                    >
                      <KeyboardDoubleArrowLeftIcon />
                    </IconButton> */}

                    <IconButton
                      onClick={(event) => onPageChange(event, page - 1)} // Go to the previous page
                      disabled={page === 0}
                      aria-label="go to previous page"
                    >
                      <KeyboardArrowLeft />
                    </IconButton>

                    {[...Array(3)].map((_, index) => {
                      const pageNumber = page + index;
                      if (pageNumber < totalPages) {
                        return (
                          <IconButton
                            key={pageNumber}
                            onClick={(event) => onPageChange(event, pageNumber)}
                            style={{
                              fontSize: '1.9vh',
                              border:
                                page === pageNumber
                                  ? '1px solid #3C5AA0'
                                  : 'none', // Highlight the active page with a border
                              color:
                                page === pageNumber ? '#3C5AA0' : '#000000', // Highlight the active page with a color
                              width: page === pageNumber ? '30px' : '30px',
                              height: page === pageNumber ? '30px' : '30px',
                            }}
                          >
                            {pageNumber + 1}
                          </IconButton>
                        );
                      } else {
                        return null; // Don't render the button if it's out of bounds
                      }
                    })}

                    <IconButton
                      onClick={(event) => onPageChange(event, page + 1)} // Go to the next page
                      disabled={page >= totalPages - 1}
                      aria-label="next page"
                    >
                      <KeyboardArrowRight />
                    </IconButton>

                    {/* <IconButton
                      onClick={(event) => onPageChange(event, totalPages - 1)} // Go to the last page
                      aria-label="go to last page"
                    >
                      <KeyboardDoubleArrowRightIcon />
                    </IconButton> */}
                  </Stack>
                );
              }}
            />
          </Stack>
        </Box>
      </PerfectScrollbar >
      <Stack
        sx={{
          backgroundColor: 'white',
          borderRadius: '10px',
          display: { xs: 'block', md: 'none' },
          mb: 12,
        }}
      >
        {paginatedData?.map((item: CommunityMembers, i: number) => (
          <Accordion sx={{ boxShadow: 'none' }} key={i}>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Grid container sx={{ alignItems: 'center' }}>
                <Grid item xs={6}>
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    <Avatar
                      src={item?.user?.avatar}
                      sx={{
                        height: '30px',
                        width: '30px',
                        fontSize: '11px',
                        fontFamily: 'Montserrat',
                      }}
                      alt={`${item?.user?.firstName}`}
                    />
                    <Typography
                      sx={{
                        fontSize: '12px',
                        fontFamily: 'Montserrat',
                        fontWeight: 600,
                        display: 'flex',
                      }}
                    >
                      {item?.user?.firstName}
                      {item?.slug === 'Owner/Director' && <AdminPerson />}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="caption"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      textTransform: 'capitalize',
                      justifyContent: 'end',
                      fontFamily: 'Montserrat',
                    }}
                  >
                    <Stack
                      key={i}
                      onClick={() => {
                        if (
                          item?.slug !== 'Owner/Director'
                        ) {
                          handleOpen(item);
                        }
                      }}
                      sx={{
                        textTransform: 'capitalize',
                        cursor: 'pointer',
                        fontSize: '15px',
                        fontFamily: 'Montserrat',
                      }}
                    >
                      <RoleTypeCell
                        text={item.type}
                        type={
                          getStaticValue(item.type) === 'Admin' ||
                            item.type === 'Admin'
                            ? 'success'
                            : item.type === 'User' || 'USER'
                              ? 'warning'
                              : 'error'
                        }
                        slug={item.slug}
                      />
                    </Stack>
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item xs={6}>
                  <Stack display={'flex'} flexDirection={'column'}>
                    {/* <Typography sx={{ color: "#565656", fontSize: "10px" }}>
                      {" "}
                      {item?.user?.phoneNumber}
                    </Typography>
                    <Typography sx={{ color: "#565656", fontSize: "10px" }}>
                      {item?.user?.emailId}
                    </Typography> */}
                    <Stack>
                      <ContactCell
                        list={[
                          {
                            item: item?.user?.phoneNumber,
                            isVerified: item?.user?.isPhoneVerified,
                          },
                          {
                            item: item?.user?.emailId,
                            isVerified: item?.user?.isEmailVerified,
                          },
                        ]}
                      />
                    </Stack>{' '}
                    {item.plans &&
                      Array.isArray(item.plans) &&
                      item.plans.length > 0 ? (
                      <Typography
                        sx={{
                          textTransform: 'capitalize',
                          color: '#000000',
                          fontSize: '9px',
                          fontFamily: 'Montserrat',
                        }}
                      >
                        Plan :
                        {item.plans
                          .slice(0, 2)
                          .map((plan: IPlan, index: number) => (
                            <React.Fragment key={index}>
                              {index > 0 && ', '}
                              <span>{plan.name}</span>
                            </React.Fragment>
                          ))}
                        {item.plans.length > 2 && (
                          <>
                            {','} (+{item.plans.length - 2})
                          </>
                        )}
                      </Typography>
                    ) : (
                      <Typography sx={{ color: '#565656', fontSize: '10px', fontFamily: 'Montserrat', }}>
                        Plan : NA
                      </Typography>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack display={'flex'} flexDirection={'column'}>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={8}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',

                        }}
                      >
                        <Box
                          sx={{
                            fontSize: '11px',
                            fontWeight: '400',
                            // px: "8px",
                            boxShadow: 'none',
                            textAlign: 'center',
                            borderRadius: '4px',
                            textTransform: 'capitalize',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100px',
                            fontFamily: 'Montserrat',
                            height: '35px',
                            p: 0.2,
                            cursor:
                              loggedInUserData?.user?.id === item.user?._id
                                ? null
                                : 'pointer',
                            backgroundColor:
                              item.status === 'BLOCK'
                                ? '#ffa87d1a'
                                : '#10a00d1a',
                            color:
                              item.status === 'BLOCK' ? '#FF8143' : '#10A00D',
                            border:
                              item.status === 'BLOCK'
                                ? '1px solid #ffa87d4d'
                                : '1px solid rgba(16, 160, 13, 0.3)',
                          }}
                          onClick={(e) => {
                            handleStatusClick(e);
                            setSelectedRow(item);
                          }}
                        >
                          <ArrowDropDownIcon sx={{ cursor: 'pointer' }} />
                          <Typography
                            sx={{
                              textAlign: 'center',
                              pr: 1,
                              fontSize: '11px',
                              fontFamily: 'Montserrat',
                            }}
                          >
                            {getStaticValue(item.status)}
                          </Typography>
                        </Box>
                        <IconButton
                          onClick={(e: any) => {
                            handleDeleteUser(e);
                            setSelectedRow(item);
                          }}
                          disabled={item?.type === 'ADMIN'}
                          sx={{ color: 'gray', ml: 1, }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                    <Typography
                      sx={{
                        color: '#565656',
                        fontSize: '10px',
                        ml: 1.5,
                        fontFamily: 'Montserrat',
                      }}
                    >
                      Joined : {item?.createdAt}
                    </Typography>

                  </Stack>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
        <Divider></Divider>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20]}
          component="div"
          count={data?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={
            <span style={{ fontFamily: 'Montserrat', fontSize: '14px' }}>View</span>
          }
          labelDisplayedRows={() => null}
          SelectProps={{
            SelectDisplayProps: {
              style: {
                border: '1px solid #A5A5A5',
                borderRadius: '5px',
                backgroundColor: 'white',
              },
            },

            MenuProps: {
              PaperProps: { style: { border: '1px solid #A5A5A5' } },
            },
          }}
          ActionsComponent={({ onPageChange, page }) => {
            const totalPages = Math.ceil(data?.length / rowsPerPage);
            return (
              <Stack
                direction={'row'}
                display="flex"
                alignItems="center"
                justifyContent={'space-between'}
                sx={{ p: 0 }}
              >
                {/* <IconButton
                  onClick={(event) => onPageChange(event, 0)}
                  disabled={page === 0}
                  aria-label="go to first page"
                >
                  <KeyboardDoubleArrowLeftIcon />
                </IconButton> */}

                <IconButton
                  onClick={(event) => onPageChange(event, page - 1)}
                  disabled={page === 0}
                  aria-label="go to previous page"
                >
                  <KeyboardArrowLeft />
                </IconButton>

                {[...Array(2)].map((_, index) => {
                  const pageNumber = page + index;
                  if (pageNumber < totalPages) {
                    return (
                      <IconButton
                        key={pageNumber}
                        onClick={(event) => onPageChange(event, pageNumber)}
                        style={{
                          fontSize: '15px',
                          border:
                            page === pageNumber ? '1px solid #3C5AA0' : 'none',
                          color: page === pageNumber ? '#3C5AA0' : '#000000',
                          width: page === pageNumber ? '30px' : '30px',
                          height: page === pageNumber ? '30px' : '30px',
                        }}
                      >
                        {pageNumber + 1}
                      </IconButton>
                    );
                  } else {
                    return null;
                  }
                })}

                <IconButton
                  onClick={(event) => onPageChange(event, page + 1)}
                  disabled={page >= totalPages - 1}
                  aria-label="next page"
                >
                  <KeyboardArrowRight />
                </IconButton>

                {/* <IconButton
                  onClick={(event) => onPageChange(event, totalPages - 1)}
                  aria-label="go to last page"
                >
                  <KeyboardDoubleArrowRightIcon />
                </IconButton> */}
              </Stack>
            );
          }}
        />
      </Stack>
      <Popover
        id={idDeletePopover}
        open={openDeletePopover}
        anchorEl={deleteAnchorEl}
        onClose={handleDeleteClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography
          sx={{
            p: 2,
            color: '#494949',
            fontSize: '16px',
            textAlign: 'center',
            fontWeight: '600',
            fontFamily: 'Montserrat',
          }}
        >
          Confirm Removal ?
        </Typography>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '11px',
            fontFamily: 'Montserrat',
            padding: '0px 16px 16px 16px',
            maxWidth: '275px',
            color: '#565656',
          }}
        >
          Are you sure you want to remove {selectedRow?.user?.firstName}{' '}
          {selectedRow?.user?.lastName} from the <b>{selectedRow?.community?.name}</b>{' '} community
          ?<br></br>
          <b>Note:</b> This action will permanently delete all associated user
          data.
        </Typography>
        <Divider />
        <Stack
          direction="row"
          spacing={2}
          justifyContent={'flex-end'}
          sx={{ padding: '16px' }}
        >
          <Button
            variant="outlined"
            size="small"
            sx={{
              textTransform: 'capitalize',
              borderColor: '#3C5AA0',
              color: '#3C5AA0',
              fontFamily: 'Montserrat',
              borderRadius: '8px',
              '&:hover': {
                borderColor: '#3C5AA0',
              },
            }}
            onClick={() => {
              handleDeleteClosePopover();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            sx={{
              backgroundColor: '#3C5AA0',
              textTransform: 'capitalize',
              fontFamily: 'Montserrat',
              boxShadow: 'none',
              borderRadius: '8px',
              '&:hover': {
                backgroundColor: '#3C5AA0',
                borderColor: '#3C5AA0',
                fontFamily: 'Montserrat',
                boxShadow: 'none',
              },
            }}
            onClick={handleRemoveUserByMappingId}
          >
            Remove
          </Button>
        </Stack>
      </Popover >
      <Popover
        id={idPopOver}
        open={openPopOver}
        anchorEl={statusPopoverAnchor}
        onClose={() => setStatusPopoverAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography
          sx={{
            p: 2,
            color: '#494949',
            fontSize: '16px',
            textAlign: 'center',
            fontWeight: '600',
            fontFamily: 'Montserrat',
          }}
        >
          {selectedRow?.status === 'ACTIVE' ? 'Block User?' : 'Active User?'}
        </Typography>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '11px',
            fontFamily: 'Montserrat',
            padding: '0px 16px 16px 16px',
            maxWidth: '275px',
            color: '#565656',
          }}
        >
          {selectedRow?.status === 'ACTIVE'
            ? 'Do you really want to Block this User?'
            : 'Do you really want to make this user active?'}
        </Typography>
        <Divider />
        <Stack direction="row" sx={{ padding: '16px' }}>
          <Button
            variant="outlined"
            size="small"
            onClick={handleCloseStatusPopOver}
            sx={{
              textTransform: 'capitalize',
              borderColor: '#3C5AA0',
              color: '#3C5AA0',
              borderRadius: '8px',
              fontFamily: 'Montserrat',
              '&:hover': {
                borderColor: '#3C5AA0',
                borderRadius: '8px',
              },
            }}
          >
            Cancel
          </Button>
          <Stack direction="row-reverse" flexGrow={1}>
            <Button
              variant="contained"
              size="small"
              onClick={handleStatusUpdate}
              sx={{
                backgroundColor: ' #3C5AA0',
                textTransform: 'capitalize',
                fontFamily: 'Montserrat',
                boxShadow: 'none',
                borderRadius: '8px',
                '&:hover': {
                  backgroundColor: '#3C5AA0',
                  borderColor: '#3C5AA0',
                  boxShadow: 'none',
                  borderRadius: '8px',
                },
              }}
            >
              {isLoading ? (
                <CircularProgress size={24} sx={{ color: 'white' }} />
              ) : (
                <>{selectedRow?.status === 'ACTIVE' ? 'Block' : 'Active'}</>
              )}
            </Button>
          </Stack>
        </Stack>
      </Popover>
    </>
  );
};
