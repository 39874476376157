import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useEffect, useState } from 'react';
import { CustomTableHeader } from '../tables/components/CustomTableHeader';
import { IColumn } from '../../models/table.model';
import Typography from '@mui/material/Typography';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Popover,
  Stack,
  TableBody,
  TableCell,
  TablePagination,
  TextField,
  Tooltip,
} from '@mui/material';
import PopUp from './PopUp';
import { ClearOutlined, Close, DeleteOutline } from '@mui/icons-material';
import SendIcon from '@mui/icons-material/Send';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import { useSuperAdmin } from '../../hooks/useSuperAdmin';
import { BankDetailsforAdmin } from '../../models/BankAcc.models';
import Loader from '../Loader/Loader';
import { useBankAccounts } from '../../hooks/useBankAccounts';
import { getStaticValue } from '../../utils/StaticValues';
import { SearchIcon1 } from '../../assets/icons';

const SuperAdmin = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [tableData, setTableData] = useState<BankDetailsforAdmin | null>(null);
  const [tableRowsData, setTableRowsData] = useState<string[]>([]);
  const [bankDetails, setBankDetails] = useState<BankDetailsforAdmin[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [sortedColumns, setSortedColumns] = useState<string[]>([]);
  const [sortDirections, setSortDirections] = useState<{
    [key: string]: 'asc' | 'desc' | undefined;
  }>({});
  const [deleteCheckboxPopOverAnchor, setDeleteCheckboxPopOverAnchor] =
    useState<null | HTMLElement>(null);
  const { getAllSuperAdminBanks } = useSuperAdmin();
  const { deleteManyBankDetailsIds } = useBankAccounts();
  const FetchData = async () => {
    try {
      setIsLoading(true);
      const response = await getAllSuperAdminBanks();
      if (response) {
        setBankDetails(response.data);
      }
    } catch (error) {
      // Handle the error here
      console.error('Error fetching data:', error);
      // Optionally, you can set an error state or display a message to the user
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (bankDetails.length === 0) {
      FetchData();
    }
    // eslint-disable-next-line
  }, []);

  const columns: IColumn[] = [
    { title: 'Community', dataKey: 'community' },
    { title: 'Admin Name', dataKey: 'admin name' },
    { title: ' Mobile Number', dataKey: 'mobile number' },
    { title: 'Bank Name', dataKey: 'bank name' },
    // { title: "Branch Name", dataKey: "branch name" },
    { title: 'Account Number', dataKey: 'account number' },
    { title: 'IFSC Code', dataKey: 'ifsc' },
    { title: 'Label', dataKey: 'label' },
    { title: 'Payout %', dataKey: 'payout percentage' },
    // { title: 'Deduction Account', dataKey: 'deduction account' },
    { title: 'Status', dataKey: 'status' },
  ];

  const handleAddDetailsClick = (row: BankDetailsforAdmin) => {
    setOpenModal(true);
    setTableData(row);
  };

  const handleCloseRejectPopup = () => {
    setOpenRejectModal(false);
    setTableData(null);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setTableData(null);
  };

  const handleRowsSelect = (_id: string, row: BankDetailsforAdmin) => {
    const isSelected = tableRowsData.includes(_id);
    let newSelectedRows = [];
    if (isSelected) {
      // If the row is already selected, remove it from the selected rows
      newSelectedRows = tableRowsData.filter((id) => id !== _id);
    } else {
      // If the row is not selected, add it to the selected rows
      newSelectedRows = [...tableRowsData, _id];
    }
    setTableRowsData(newSelectedRows);
    // console.log('Selected Rows:', newSelectedRows);
  };

  const handleClearClick = () => {
    setTableRowsData([]); // Clear all selected rows
  };

  const handleDeleteSelected = (e: any) => {
    if (tableRowsData.length > 0) {
      setDeleteCheckboxPopOverAnchor(e.currentTarget);
    }
  };

  const openDeletePopOver = Boolean(deleteCheckboxPopOverAnchor);
  const idCheckBoxDeletePopover = openDeletePopOver
    ? 'delete-multiple-popover'
    : undefined;

  const handleCloseCheckboxDeletePopover = () => {
    setDeleteCheckboxPopOverAnchor(null);
  };
  const handleDeleteAll = async () => {
    const selectedRowsData: BankDetailsforAdmin[] =
      bankDetails?.filter((inv) => tableRowsData?.includes(inv._id)) || [];

    const selectedRowIds = selectedRowsData.map(
      (selectedRow) => selectedRow._id
    );

    setIsLoading(true);
    try {
      const payload = {
        ids: selectedRowIds,
      };

      await deleteManyBankDetailsIds(payload);
      await FetchData();
      handleClearClick();
      handleCloseCheckboxDeletePopover();
    } catch (error) {
      console.error('Error deleting requests:', error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
  };
  // const filteredUsers =.filter(
  //   (community) =>
  //     community?.name?.toLowerCase().includes(searchQuery) ||
  //     community?.location?.toLowerCase().includes(searchQuery)
  // );

  const handleSort = (columnTitle: string) => {
    setSortDirections((prevSortDirections) => {
      let newSortDirections: { [key: string]: 'asc' | 'desc' | undefined } = {};
      let newSortedColumns: string[] = [];

      if (prevSortDirections[columnTitle] === 'asc') {
        newSortDirections[columnTitle] = 'desc';
      } else {
        newSortDirections[columnTitle] = 'asc';
      }

      newSortedColumns.push(columnTitle);
      setSortedColumns(newSortedColumns);
      return newSortDirections;
    });
  };

  const startIndex = page * rowsPerPage;
  const endIndex = (page + 1) * rowsPerPage;
  const paginatedData = bankDetails.slice(startIndex, endIndex);
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    setTableData(null);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setTableData(null);
  };
  const sortedData = [...paginatedData];
  const sortedColumnTitles = sortedColumns.filter(
    (columnTitle) => sortDirections[columnTitle] !== undefined
  );
  if (sortedColumnTitles.length > 0) {
    sortedData?.sort((a: any, b: any) => {
      for (const columnTitle of sortedColumnTitles) {
        const sortDirection = sortDirections[columnTitle];
        const column = columns.find((col) => col.title === columnTitle);
        if (column) {
          if (column.dataKey === 'community') {
            const aValue = a?.community?.name;
            const bValue = b?.community?.name;
            if (sortDirection === 'asc') {
              return aValue?.localeCompare(bValue);
            } else {
              return bValue?.localeCompare(aValue);
            }
          } else if (column?.dataKey === 'name') {
            const aValue = a?.fullName;
            const bValue = b?.fullName;
            if (sortDirection === 'asc') {
              return aValue?.localeCompare(bValue);
            } else {
              return bValue?.localeCompare(aValue);
            }
          } else if (column?.dataKey === 'mobilenumber') {
            const aValue = a?.phoneNumber;
            const bValue = b?.phoneNumber;

            if (sortDirection === 'asc') {
              return typeof aValue === 'number' && typeof bValue === 'number'
                ? aValue - bValue
                : 0;
            } else {
              return typeof aValue === 'number' && typeof bValue === 'number'
                ? bValue - aValue
                : 0;
            }
          } else if (column.dataKey === 'bankname') {
            const aValue = a?.bankName;
            const bValue = b?.bankName;
            if (sortDirection === 'asc') {
              return aValue.localeCompare(bValue);
            } else {
              return bValue.localeCompare(aValue);
            }
          } else if (column.dataKey === 'accountnumber') {
            const aValue = a?.accountNumber;
            const bValue = b?.accountNumber;
            if (sortDirection === 'asc') {
              return typeof aValue === 'number' && typeof bValue === 'number'
                ? aValue - bValue
                : 0;
            } else {
              return typeof aValue === 'number' && typeof bValue === 'number'
                ? bValue - aValue
                : 0;
            }
          } else if (column.dataKey === 'ifsc') {
            const aValue = a?.ifsc;
            const bValue = b?.ifsc;
            if (sortDirection === 'asc') {
              return aValue.localeCompare(bValue);
            } else {
              return bValue.localeCompare(aValue);
            }
          } else if (column.dataKey === 'status') {
            const aValue = a?.status;
            const bValue = b?.status;
            if (sortDirection === 'asc') {
              return aValue.localeCompare(bValue);
            } else {
              return bValue.localeCompare(aValue);
            }
          } else if (column.dataKey === 'label') {
            const aValue = a?.label;
            const bValue = b?.label;
            if (sortDirection === 'asc') {
              return aValue.localeCompare(bValue);
            } else {
              return bValue.localeCompare(aValue);
            }
          } else if (column.dataKey === 'status') {
            const aValueDate = new Date(a?.updatedAt);
            const bValueDate = new Date(b.updatedAt);
            if (aValueDate.getTime() !== bValueDate.getTime()) {
              // If the dates are not the same, sort based on date
              if (sortDirection === 'asc') {
                return aValueDate.getTime() - bValueDate.getTime();
              } else {
                return bValueDate.getTime() - aValueDate.getTime();
              }
            } else {
              // If dates are the same, include time for sorting
              // const aValueTime = new Date(
              //   `${a.createdAt}T${a.createdAt}`
              // );
              // const bValueTime = new Date(
              //   `${b.updated.date}T${b.updated.time}`
              // );
              // if (sortDirection === "asc") {
              //   return aValueTime.getTime() - bValueTime.getTime();
              // } else {
              //   return bValueTime.getTime() - aValueTime.getTime();
              // }
            }
          } else {
            // const aValue = a[column?.dataKey];
            // const bValue = b[column?.dataKey];

            if (sortDirection === 'asc') {
              //  return aValue.localeCompare(bValue);
            } else {
              //  return bValue.localeCompare(aValue);
            }
          }
        }
      }
      return 0;
    });
  }

  const filteredData = sortedData.filter((rowData) => {
    const searchQueryLower = searchQuery.toLowerCase();
    return (
      rowData?.community?.name.toLowerCase().includes(searchQueryLower) ||
      rowData?.fullName.toLowerCase().includes(searchQueryLower) ||
      rowData.phoneNumber.toString().toLowerCase().includes(searchQueryLower) ||
      `${rowData.bankName} ${rowData.branchName}`
        .toLowerCase()
        .includes(searchQueryLower) ||
      rowData.accountNumber
        .toString()
        .toLowerCase()
        .includes(searchQueryLower) ||
      rowData.ifsc.toLowerCase().includes(searchQueryLower) ||
      rowData.label.toLowerCase().includes(searchQueryLower)
    );
  });

  return (
    <div>
      <h2
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '16px',
        }}
      >
        <Grid xl={6}>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 600,
              color: '#000000',
              fontFamily: 'Montserrat',
              mt: 2,
            }}
          >
            Bank Details
          </Typography>
        </Grid>

        <Grid xl={6}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              placeholder="Search "
              variant="outlined"
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon1 sx={{ mt: 1, }} />
                  </InputAdornment>
                ),
                sx: {
                  borderRadius: '8px',
                  backgroundColor: '#F9F9F9',
                  fontSize: { xs: '12px', md: '15px' },
                  height: '37px',
                  width: { xs: '100%', md: '300px' },
                  color: '#000000',
                  fontFamily: 'Montserrat',
                  ml: { xs: -1 },
                  '& input::placeholder': {
                    fontFamily: 'Montserrat',
                    fontSize: '14px',
                    color: '#9e9e9e',
                    opacity: 1,
                  },
                },
              }}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </Grid>
      </h2>

      <div style={{ background: '#fff', minHeight: '75vh' }}>
        {tableRowsData.length > 0 && bankDetails.length > 0 && (
          <Stack
            sx={{
              paddingLeft: '16px',
              display: 'flex',
              alignItems: 'center',
              mt: 0,
              backgroundColor: '#ffffff',
            }}
            spacing={3}
            direction={'row'}
          >
            <ClearOutlined
              sx={{ cursor: 'pointer', color: '#1C1B1F' }}
              onClick={handleClearClick}
            />

            <Typography sx={{ color: '#1C1B1F', fontWeight: '500' }}>
              {`${tableRowsData.length} Selected `}
            </Typography>
            <IconButton
              aria-label="delete"
              onClick={(e) => handleDeleteSelected(e)}
            >
              <DeleteOutline sx={{ color: '#000000' }} />
            </IconButton>
          </Stack>
        )}
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{ height: 'calc(100vh - 30vh)', backgroundColor: 'white', borderRadius: '8px' }}
        >
          <Table
            sx={{
              '& th': {
                fontSize: '12px',
                fontWeight: 700,
                color: '#565656',
                p: 1,
              },
              '& td': {
                fontSize: '15px',
                fontWeight: 500,

                color: '#1A1A1A',
                p: '5px 10px',
                '& .MuiTypography-root': { m: 0, fontSize: '11px' },
                '& .MuiTypography-root:nth-child(2)': {
                  m: 0,
                  fontSize: '6px',
                  color: '#56565680',
                  fontWeight: 600,
                },
              },
            }}
          >
            <TableHead
              style={{
                position: 'sticky',
                top: 0,
                zIndex: 1,
                background: '#ffffff',
              }}
            >
              <TableRow sx={{ ml: 0 }}>
                <TableCell padding="checkbox">
                  <Checkbox
                    size="small"
                    sx={{ pl: '13px', mt: '03px' }}
                    style={{
                      transform: 'scale(1)',
                    }}
                    checked={tableRowsData.length === bankDetails?.length}
                    onChange={() => {
                      if (tableRowsData.length === bankDetails?.length) {
                        setTableRowsData([]);
                      } else {
                        setTableRowsData(bankDetails?.map((row) => row._id));
                      }
                    }}
                  />
                </TableCell>
                {columns.map((column: IColumn, i: number) => (
                  <TableCell key={i} onClick={() => handleSort(column.title)}>
                    <CustomTableHeader
                      title={column.title}
                      sortDirection={sortDirections[column.title]}
                    />
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={12}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Loader />
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {filteredData.map((rowData, index) => (
                    <TableRow key={index}>
                      <TableCell sx={{}} padding="checkbox">
                        <Checkbox
                          size="small"
                          sx={{
                            transform: 'scale(1)',
                            ml: '3px',
                          }}
                          checked={tableRowsData.includes(rowData._id)}
                          onChange={() => {
                            handleRowsSelect(rowData._id, rowData);
                          }}
                        />
                      </TableCell>
                      {columns[0].dataKey === 'community' && (
                        <TableCell>
                          <Tooltip
                            title={rowData?.community?.name}
                            placement="right-start"
                          >
                            <img
                              src={rowData?.community?.logo}
                              alt="logo"
                              style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '50%',
                                objectFit: 'cover',
                              }}
                            />
                          </Tooltip>
                        </TableCell>
                      )}
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '11px',
                          fontWeight: '400',
                        }}
                      >
                        <Tooltip title={rowData?.fullName}>
                          <Typography sx={{ fontFamily: 'Montserrat', }}>
                            {rowData?.fullName.length > 20
                              ? `${rowData?.fullName.substring(0, 20)}...`
                              : rowData?.fullName}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '11px',
                          fontWeight: '400',
                        }}
                      >
                        {rowData?.phoneNumber}
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '11px',
                          fontWeight: '400',
                        }}
                      >
                        <Tooltip
                          title={`${rowData.bankName} ${rowData.branchName}`}
                        >
                          <Typography sx={{ fontFamily: 'Montserrat', }}>
                            {rowData.bankName?.length +
                              rowData.branchName.length >
                              20
                              ? `${rowData.bankName.substring(0, 10)} - ${rowData.branchName.substring(0, 10)}...`
                              : `${rowData.bankName} ${rowData.branchName}`}
                          </Typography>
                        </Tooltip>
                      </TableCell>

                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '11px',
                          fontWeight: '400',
                        }}
                      >
                        {rowData.accountNumber}
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '11px',
                          fontWeight: '400',
                        }}
                      >
                        {rowData.ifsc}
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '11px',
                          fontWeight: '400',
                        }}
                      >
                        {rowData.label}
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '11px',
                          fontWeight: '400',
                        }}
                      >
                        {rowData?.percentageChares
                          ? `${rowData.percentageChares}%`
                          : rowData.fixedChares
                            ? `${rowData.fixedChares} (fix)`
                            : 'NA'}
                      </TableCell>

                      {/* <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '11px',
                          fontWeight: '400',
                        }}
                      >
                        {rowData.deductionAccount}
                      </TableCell> */}
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '11px',
                          fontWeight: '400',
                          position: 'relative',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            border:
                              rowData.status === 'ACTIVE'
                                ? '1px solid green'
                                : rowData.status === 'CREATED'
                                  ? '1px solid #FF4F00'
                                  : rowData.status === 'PENDING'
                                    ? '1px solid #DAA44D'
                                    : rowData.status === 'VALIDATING'
                                      ? '1px solid #50A1CA'
                                      : rowData.status === 'REJECT'
                                        ? '1px solid #DA0242'
                                        : '1px solid #DA0242',
                            borderRadius: '5px',
                            padding: '2px 5px',
                            color:
                              rowData.status === 'ACTIVE'
                                ? 'green'
                                : rowData.status === 'CREATED'
                                  ? '#FF4F00'
                                  : rowData.status === 'PENDING'
                                    ? '#DAA44D'
                                    : rowData.status === 'VALIDATING'
                                      ? '#50A1CA'
                                      : rowData.status === 'REJECT'
                                        ? '#DA0242'
                                        : 'grey',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            backgroundColor:
                              rowData.status === 'ACTIVE'
                                ? '#E8F5E9' // Very light green
                                : rowData.status === 'CREATED'
                                  ? '#FFF3E0' // Very light orange
                                  : rowData.status === 'PENDING'
                                    ? '#FFF9C4' // Very light yellow
                                    : rowData.status === 'VALIDATING'
                                      ? '#E1F5FE' // Very light blue
                                      : rowData.status === 'REJECT'
                                        ? '#FFEBEE' // Very light red
                                        : '#FFFFFF', // Light grey (fallback color)
                          }}
                          onClick={() => handleAddDetailsClick(rowData)}
                        >
                          <SendIcon
                            sx={{
                              fontSize: '18px',
                              marginRight: '5px', // Adjusted margin to add space between icon and text
                              // color: "orange",
                            }}
                          />
                          {getStaticValue(rowData.status)}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog
          fullWidth
          maxWidth="sm"
          open={openModal}
          onClose={() => handleCloseModal()}
          PaperProps={{
            sx: {
              width: '100%',
            },
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ backgroundColor: '#F0F9FF', height: '50px', px: 2 }}
          >
            <Typography
              variant="h6"
              sx={{
                color: '#3C5AA0',
                fontSize: '20px',
                fontWeight: '500',
                backgroundColor: '#F0F9FF',
              }}
            >
              Status
            </Typography>
            <Box
              sx={{
                backgroundColor: '#3C5AA0',
                padding: '4px',
                borderRadius: '50%',
                width: '20px',
                height: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={() => handleCloseModal()}
            >
              <Close
                sx={{ fontSize: '15px', color: '#FFFFFF', cursor: 'pointer' }}
              />
            </Box>
          </Grid>
          <DialogContent>
            <PopUp
              onCloseModal={handleCloseModal}
              tableRowData={tableData}
              FetchData={FetchData}
            />
          </DialogContent>
        </Dialog>

        <Dialog
          fullWidth
          maxWidth="sm"
          open={openRejectModal}
          onClose={() => handleCloseRejectPopup()}
          PaperProps={{
            sx: {
              width: '100%',
            },
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ backgroundColor: '#F0F9FF', height: '50px', px: 2 }}
          >
            <Typography
              variant="h6"
              sx={{
                color: '#3C5AA0',
                fontSize: '20px',
                fontWeight: '500',
                backgroundColor: '#F0F9FF',
              }}
            >
              Reject a Bank Detail
            </Typography>
            <Box
              sx={{
                backgroundColor: '#3C5AA0',
                padding: '4px',
                borderRadius: '50%',
                width: '20px',
                height: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={() => handleCloseRejectPopup()}
            >
              <Close sx={{ fontSize: '15px', color: '#FFFFFF' }} />
            </Box>
          </Grid>
          <DialogContent>
            <TextField
              id="comments"
              multiline
              rows={2}
              fullWidth
              variant="outlined"
              label="Enter comments..."
              // onChange={(event) => handleCommentsChange(event.target.value)}
              sx={{ marginTop: '5px' }}
            />

            <Grid
              container
              direction="row"
              spacing={2}
              sx={{
                display: 'flex',
                alignItems: 'rightf',
                justifyContent: 'right',
                marginTop: '16px',
              }}
            >
              <Grid item>
                <Button
                  variant="contained"
                  sx={{
                    px: 2,
                    textTransform: 'capitalize',
                    borderRadius: '4px',
                    background:
                      'linear-gradient(to right bottom, #3C5AA0, #50A1CA)',
                    '&:hover': {
                      backgroundColor: '#50A1CA',
                      cursor: 'pointer',
                      border: 'none',
                    },
                  }}
                // onClick={handleSubmit}
                // disabled={!isFormComplete}
                >
                  Reject
                </Button>
              </Grid>

              <Grid item>
                <Button
                  variant="contained"
                  sx={{
                    px: 2,
                    textTransform: 'capitalize',
                    borderRadius: '4px',
                    background:
                      'linear-gradient(to right bottom, #3C5AA0, #50A1CA)',
                    '&:hover': {
                      backgroundColor: '#50A1CA',
                      cursor: 'pointer',
                      border: 'none',
                    },
                  }}
                // onClick={handleButtonClick}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>

      <Stack
        direction={'row-reverse'}
        alignItems={'center'}
        sx={{
          color: '#A5A5A5',
          fontSize: { xs: '10px', md: '1.5vh' },
          pr: { xs: '0px', md: '25px' },
          fontFamily: 'Montserrat',
          backgroundColor: 'white'
        }}
      >
        Showing {Math.min(rowsPerPage, bankDetails.length - page * rowsPerPage)}{' '}
        of {bankDetails.length} entries
        <TablePagination
          // rowsPerPageOptions={rowsPerPageOptions}
          rowsPerPageOptions={[10, 15, 20]}
          component="div"
          count={bankDetails.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={
            <span style={{ fontFamily: 'Montserrat', fontSize: '14px' }}>View</span>
          }
          labelDisplayedRows={() => null}
          SelectProps={{
            SelectDisplayProps: {
              style: { border: '1px solid #A5A5A5', borderRadius: '5px', backgroundColor: 'white', },
            },
            // You can also customize the style of the menu options if desired
            MenuProps: {
              PaperProps: { style: { border: '1px solid #A5A5A5' } },
            },
          }}
          ActionsComponent={({ onPageChange, page }) => {
            const totalPages = Math.ceil(bankDetails.length / rowsPerPage);

            return (
              <Stack
                direction={'row'}
                display="flex"
                alignItems="center"
                justifyContent={'space-between'}
              >
                {/* <IconButton
                  onClick={(event) => onPageChange(event, 0)} // Go to the first page
                  disabled={page === 0}
                  aria-label="go to first page"
                >
                  <KeyboardDoubleArrowLeftIcon />
                </IconButton> */}

                <IconButton
                  onClick={(event) => onPageChange(event, page - 1)} // Go to the previous page
                  disabled={page === 0}
                  aria-label="go to previous page"
                >
                  <KeyboardArrowLeft />
                </IconButton>

                {[...Array(3)].map((_, index) => {
                  const pageNumber = page + index;
                  if (pageNumber < totalPages) {
                    return (
                      <IconButton
                        key={pageNumber}
                        onClick={(event) => onPageChange(event, pageNumber)}
                        style={{
                          fontSize: '1.9vh',
                          border:
                            page === pageNumber ? '1px solid #3C5AA0' : 'none', // Highlight the active page with a border
                          color: page === pageNumber ? '#3C5AA0' : '#000000', // Highlight the active page with a color
                          width: page === pageNumber ? '30px' : '30px',
                          height: page === pageNumber ? '30px' : '30px',
                        }}
                      >
                        {pageNumber + 1}
                      </IconButton>
                    );
                  } else {
                    return null; // Don't render the button if it's out of bounds
                  }
                })}

                <IconButton
                  onClick={(event) => onPageChange(event, page + 1)} // Go to the next page
                  disabled={page >= totalPages - 1}
                  aria-label="next page"
                >
                  <KeyboardArrowRight />
                </IconButton>

                {/* <IconButton
                  onClick={(event) => onPageChange(event, totalPages - 1)} // Go to the last page
                  aria-label="go to last page"
                >
                  <KeyboardDoubleArrowRightIcon />
                </IconButton> */}
              </Stack>
            );
          }}
        />
      </Stack>

      <Popover
        id={idCheckBoxDeletePopover}
        open={openDeletePopOver}
        anchorEl={deleteCheckboxPopOverAnchor}
        onClose={() => setDeleteCheckboxPopOverAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography
          sx={{
            p: 2,
            color: '#494949',
            fontSize: '16px',
            textAlign: 'center',
            fontWeight: '600',
            fontFamily: 'Montserrat',
          }}
        >
          Are you sure?
        </Typography>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '11px',
            fontFamily: 'Montserrat',
            padding: '0px 16px 16px 16px',
            maxWidth: '275px',
            color: '#565656',
          }}
        >
          Are you sure you want to delete selected Payment Requests?
        </Typography>
        <Divider />
        <Stack direction="row" sx={{ padding: '16px' }}>
          <Button
            variant="outlined"
            size="small"
            onClick={handleCloseCheckboxDeletePopover}
            sx={{
              textTransform: 'capitalize',
              borderColor: '#3C5AA0',
              color: '#3C5AA0',
              fontFamily: 'Montserrat',
              curser: 'pointer',
              '&:hover': {
                borderColor: '#3C5AA0',
              },
            }}
          >
            Cancel
          </Button>
          <Stack direction="row-reverse" flexGrow={1}>
            <Button
              variant="contained"
              size="small"
              onClick={handleDeleteAll}
              sx={{
                background: 'linear-gradient(140deg, #3C5AA0 0%, #50A1CA 100%)',
                textTransform: 'capitalize',
                '&:hover': {
                  backgroundColor: '#3C5AA0',
                  borderColor: '#3C5AA0',
                },
              }}
            >
              {isLoading ? (
                <CircularProgress size={24} sx={{ color: 'white' }} />
              ) : (
                'Delete'
              )}
            </Button>
          </Stack>
        </Stack>
      </Popover>
    </div>
  );
};

export default SuperAdmin;
