import React from 'react';
import { Stack, Typography, IconButton, Button } from '@mui/material';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { useNavigate } from 'react-router-dom';

interface BreadcrumbProps {
    items: { label: string; path?: string }[];
    actionButton?: { label: string; path: string };
}

const BreadcrumbDouble: React.FC<BreadcrumbProps> = ({ items, actionButton }) => {
    const navigate = useNavigate();

    return (
        <>
            {/* Mobile View */}
            <Stack
                flexGrow={1}
                sx={{ display: { xs: 'flex', md: 'none' }, pb: 0 }}
                flexDirection="row"
                gap={1}
                alignItems="center"
            >
                {items.length > 1 && (
                    <IconButton sx={{ p: 0.5 }} onClick={() => navigate(items[items.length - 2]?.path || '/')}>
                        <ArrowBackIosNewOutlinedIcon sx={{ fontSize: '18px' }} />
                    </IconButton>
                )}
                <Typography
                    sx={{
                        fontSize: { xs: '14px', md: '16px' },
                        fontWeight: 600,
                        color: '#000000',
                        fontFamily: 'Montserrat',
                    }}
                >
                    {items[items.length - 1].label}
                </Typography>
            </Stack>

            {/* Desktop View */}
            <Stack
                direction="row"
                flexGrow={1}
                alignItems="center"
                sx={{ display: { xs: 'none', md: 'flex' } }}
                gap={1}
            >
                {items.map((item, index) => (
                    <React.Fragment key={index}>
                        <Typography
                            component={item.path ? 'a' : 'span'}
                            onClick={() => item.path && navigate(item.path)}
                            sx={{
                                fontSize: { xs: '14px', md: '16px' },
                                fontWeight: index === items.length - 1 ? 600 : 500,
                                color: '#000000',
                                fontFamily: 'Montserrat',
                                cursor: item.path ? 'pointer' : 'default',
                            }}
                        >
                            {item.label}
                        </Typography>
                        {index < items.length - 1 && (
                            <ArrowBackIosNewOutlinedIcon sx={{ fontSize: { xs: '14px', md: '18px' } }} />
                        )}
                    </React.Fragment>
                ))}

                {actionButton && (
                    <Stack direction="row" sx={{ justifyContent: 'flex-end', flexGrow: 1 }} gap={2}>
                        <Button
                            variant="contained"
                            endIcon={<OpenInNewOutlinedIcon />}
                            sx={{
                                textTransform: 'capitalize',
                                borderRadius: '8px',
                                backgroundColor: '#3C5AA0',
                                fontFamily: 'Montserrat',
                                boxShadow: 'none',
                                '&:hover': {
                                    backgroundColor: '#3C5AA0',
                                    cursor: 'pointer',
                                },
                            }}
                            onClick={() => navigate(actionButton.path)}
                        >
                            {actionButton.label}
                        </Button>
                    </Stack>
                )}
            </Stack>
        </>
    );
};

export default BreadcrumbDouble;