//export const BASE_URL = "http://localhost:5002/api/v1";
//export const BASE_URL_V2 = "http://localhost:5002/api/v2.0";
export const BASE_URL      = "https://api.onecommunn.com/api/v1"
export const BASE_URL_V2   = "https://api.onecommunn.com/api/v2.0";
// export const USER_APP_BASE_URL = "http://16.171.111.22:3001"
export const USER_APP_BASE_URL = "https://member.onecommunn.com"
export const USER_APP = "https://member.onecommunn.com"
export const FB_APP_ID="678099697734127"
//AI APP URL --------------------------------------------------------------------------------------------
export const AI_APP_BASE_URL = 'https://ai.onecommunn.com'

//PROD
export const USER_LOGIN = "https://member.onecommunn.com/login"