import axios from "axios";
import { BASE_URL_V2 } from "../configurations/url.config";
import { IAddEventDetails } from "../models/events.models";



export const fetchUpComingEvents = async (
    token: string,
    communityId: string
  ) => {
    try {
      const response = await axios.get(
        `${BASE_URL_V2}/event/all-admin-upcoming/community/${communityId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data?.events;
    } catch (err) {
      return { status: 500, data: [] };
    }
  };


  export const fetchPastEvents = async (
    token: string,
    communityId: string
  ) => {
    try {
      const response = await axios.get(
        `${BASE_URL_V2}/event/all-admin-past/community/${communityId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data?.events;
    } catch (err) {
      return { status: 500, data: [] };
    }
  };



  export const fetchEventRequest = async (
    token: string,
    eventId: string
  ) => {
    try {
      const response = await axios.get(
        `${BASE_URL_V2}/event/${eventId}/request-by-event`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      // console.log(response?.data?.event,'response')
      return response.data?.event;
    } catch (err) {
      return { status: 500, data: [] };
    }
  };



  export const createEvents = async (
    token: string,
    details: IAddEventDetails
  ) => {
    try {
      const response = await axios.post(
        `${BASE_URL_V2}/event/schedule-event`,
        details,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      return response;
    } catch (error) {
      console.error('Error scheduling class:');
    }
};


export const requestAcceptOrReject = async (
  token: string,
  eventId:string,
  userId:string,
  status: string,
) => {
  try {
    const response = await axios.post(
      `${BASE_URL_V2}/event/${eventId}/user-request/${userId}`,
      {
        status,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response;
  } catch (error) {
    console.error('Error scheduling class:');
  }
};




export const updateEvent = async (
    token: string,
    details: IAddEventDetails,
    event: string,
  ) => {
    try {
      const response = await axios.put(
        `${BASE_URL_V2}/event/${event}`,
        details,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      // console.log(response, "response");
      return response;
    } catch (error) {
      console.error('Error scheduling class:');
    }
  };


  export const fetchEventById = async (
    eventId: string
  ) => {
    try {
      const response = await axios.get(
        `${BASE_URL_V2}/events/${eventId}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data?.events;
    } catch (err) {
      return { status: 500, data: [] };
    }
  };