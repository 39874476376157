import { Box, Button, InputAdornment, Stack, Tab, TextField, Typography } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import AllClass from './Components/AllClasses';
import React, { useState } from 'react';
import PastClasses from './Components/PastClasses';
import { Helmet } from 'react-helmet';
import { metaConfig } from '../../utils/constants';
import { SubNavbar } from '../Layout/components/SubNavbar';
import { useNavigate } from 'react-router-dom';
import { SearchIcon1 } from '../../assets/icons';
import { AddCircleOutlineOutlined } from '@mui/icons-material';

const LiveClass = () => {
  const [value, setValue] = React.useState('1');

  const navigate = useNavigate()

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const [searchQuery, setSearchQuery] = useState('');

  return (
    <>
      <Helmet>
        <title>{metaConfig.title.plans}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.author} />
        <meta name="keywords" content={metaConfig.keywords} />
      </Helmet>

      <Stack flexGrow={1} sx={{ display: { xs: 'flex', md: 'none' }, pb: 1 }} flexDirection={'row'} gap={1} display={'flex'} justifyItems={'center'} alignItems={'center'}>
        {/* <ArrowBackIosNewOutlinedIcon sx={{ fontSize: '18px' }} onClick={() => {
                          navigate('/your-target-route');
                      }} /> */}
        <Typography
          sx={{
            fontSize: { xs: '14px' },
            fontWeight: 600,
            color: '#000000',
            fontFamily: 'Montserrat',
          }}
        >
          Meetings
        </Typography>
      </Stack>

      <SubNavbar>
        <Stack direction={'row'} flexGrow={1} alignItems={'center'} ml={0}>
          <Stack direction={'row'} flexGrow={1} sx={{ display: { xs: 'none', md: 'block' } }}>
            <Typography
              sx={{
                fontSize: { xs: '14px', md: '16px' },
                fontWeight: 600,
                color: '#000000',
                fontFamily: 'Montserrat',
              }}
            >
              Meetings
            </Typography>
          </Stack>
          <Stack direction={'row'} flexGrow={1} sx={{ justifyContent: { xs: 'space-between', md: 'flex-end' } }} gap={2} >
            <TextField
              placeholder="Search "
              variant="outlined"
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon1 sx={{ mt: 1, }} />
                  </InputAdornment>
                ),
                sx: {
                  borderRadius: '8px',
                  backgroundColor: '#F9F9F9',
                  fontSize: { xs: '12px', md: '15px' },
                  height: '37px',
                  width: { xs: '100%', md: '300px' },
                  color: '#000000',
                  fontFamily: 'Montserrat',
                  ml: { xs: -1 },
                  '& input::placeholder': {
                    fontFamily: 'Montserrat',
                    fontSize: '14px',
                    color: '#9e9e9e',
                    opacity: 1,
                  },
                },
              }}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Button
              variant="contained"
              endIcon={<AddCircleOutlineOutlined />}
              sx={{
                textTransform: 'capitalize',
                borderRadius: '8px',
                backgroundColor: '#3C5AA0',
                fontFamily: 'Montserrat',
                boxShadow: 'none',
                '&:hover': {
                  backgroundColor: '#3C5AA0',
                  cursor: 'pointer',
                  border: 'none',
                  boxShadow: 'none',
                },
              }}
              onClick={() => {
                navigate('/create-meeting')
              }}
            >
              Add New
            </Button>
          </Stack>
        </Stack >
      </SubNavbar>
      <Box sx={{ width: '100%', mt: 2, }}>
        <TabContext value={value}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              backgroundColor: 'white',
              borderRadius: '5px',
            }}
          >
            <TabList onChange={handleChange}
              aria-label="lab API tabs example"
              sx={{
                '& .MuiTabs-indicator': {
                  backgroundColor: '#3760A9',
                },
              }}>
              <Tab
                label="Present meetings"
                value="1"
                sx={{
                  fontSize: '15px',
                  fontWeight: 400,
                  textTransform: 'capitalize',
                  color: '#92929D',
                  fontFamily: 'Montserrat',
                  '&.Mui-selected': {
                    color: '#3760A9',
                    fontWeight: 600,
                  },
                }}
              />
              <Tab
                label="Past meetings"
                value="2"
                sx={{
                  fontSize: '15px',
                  fontWeight: 400,
                  textTransform: 'capitalize',
                  color: '#92929D',
                  fontFamily: 'Montserrat',
                  '&.Mui-selected': {
                    color: '#3760A9',
                    fontWeight: 600,
                  },
                }}
              />
            </TabList>
          </Box>
          <TabPanel value="1" sx={{ px: 0, py: 2 }}>
            <AllClass />
          </TabPanel>
          <TabPanel value="2" sx={{ px: 0, py: 2 }}>
            <PastClasses />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
};

export default LiveClass;
