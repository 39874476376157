import Stack from '@mui/material/Stack';
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import FilterListIcon from '@mui/icons-material/FilterList';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { EditIcon, Group2Icon, AttachementIcon } from '../../assets/icons';
import { useCommunity } from '../../hooks/useCommunity';
import { CommunityTable } from '../tables/CommunityTable';
import { ICommunity, ICommunityList } from '../../models/communities.modal';
import { useNavigate } from 'react-router-dom';
import { IColumn } from '../../models/table.model';
import { ContentWrapper } from '../Layout/components/ContentWrapper';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Avatar,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Select,
  // SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import Loader from '../Loader/Loader';
// import store from "./../../store";

import { Helmet } from 'react-helmet';
import { metaConfig } from '../../utils/constants';
import { AddCircleOutlineOutlined } from '@mui/icons-material';
import { SubNavbar } from '../Layout/components/SubNavbar';

export const CommunitiesList = () => {
  const [checkboxes, setCheckboxes] = useState<{
    [key: string]: boolean;
  }>({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
    checkbox4: false,
    checkbox5: false,
    checkbox6: false,
    checkbox7: false,
    checkbox8: false,
  });
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [name]: checked,
    }));
    if (name === 'checkbox1' || name === 'checkbox2') {
      const bothChecked =
        (name === 'checkbox1' && checked && checkboxes.checkbox2) ||
        (name === 'checkbox2' && checked && checkboxes.checkbox1);
      if (bothChecked) {
        setMarkedAsFilter('');
      } else if (name === 'checkbox1') {
        setMarkedAsFilter(
          checked ? 'PUBLIC' : checkboxes.checkbox2 ? 'PRIVATE' : ''
        );
      } else if (name === 'checkbox2') {
        setMarkedAsFilter(
          checked ? 'PRIVATE' : checkboxes.checkbox1 ? 'PUBLIC' : ''
        );
      }
    }
  };

  const [markedAsFilter, setMarkedAsFilter] = useState<string>('');
  const [categoryFilter, setCategoryFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [membershipFilter, setMembershipFilter] = useState<string[]>([]);

  const [sorting] = useState<'asc' | 'desc'>('asc'); // Sorting state

  const navigate = useNavigate();
  const { isLoading, getCommunitiesList } = useCommunity();
  const [communities, setCommunities] = useState<ICommunity[]>([]);
  useEffect(() => {
    const getList = async () => {
      const communitiesDataList = await getCommunitiesList();

      setCommunities(communitiesDataList);
    };
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log(communities, " HERE");

  const handleActionClick = () => { };

  const handleCreateNew = () => {
    navigate('/create-community');
  };

  const handleRowClick = (selectedCommunity: ICommunity) => {
    const communityURL = generateCommunityURL(selectedCommunity?._id);

    navigate(communityURL);
  };

  const columns: IColumn[] = [
    { title: 'Title', dataKey: 'title' },
    { title: 'Category', dataKey: 'category' },
    { title: 'Visibility', dataKey: 'markedAs' },
    { title: 'Membership', dataKey: 'membership' },
    { title: 'Creator', dataKey: 'admin' },
    // { title: "Earnings(₹)", dataKey: "earnings" },
    { title: 'Created/Updated', dataKey: 'created/updated' },
    { title: 'Status', dataKey: 'status' },
    { title: '', dataKey: '' },
  ];

  function generateCommunityURL(communityId: string) {
    return `/communities/build/${communityId}`;
  }

  const buildButton = () => (
    <Avatar
      onClick={() => navigate(`/communities/build/`)}
      sx={{
        width: 20,
        height: 20,
        backgroundColor: 'white',
        border: '2px solid #4578F980',
        borderRadius: '5px',
        cursor: 'pointer',
        transition: 'all 0.3s',
        '&:hover': { transform: 'scale3d(1.5, 1.5, 1.5)' },
      }}
      variant="square"
    >
      <AttachementIcon sx={{ fontSize: '14px' }} />
    </Avatar>
  );

  const EditButton = () => (
    <Avatar
      onClick={() => navigate(`/home`)}
      sx={{
        width: 20,
        height: 20,
        backgroundColor: 'white',
        border: '2px solid #4578F980',
        borderRadius: '5px',
        cursor: 'pointer',
        transition: 'all 0.3s',
        '&:hover': { transform: 'scale3d(1.5, 1.5, 1.5)' },
      }}
      variant="square"
    >
      <EditIcon sx={{ fontSize: '14px' }} />
    </Avatar>
  );

  const attachActions = (
    data: ICommunity[],
    selectedValue: string | null
  ): ICommunityList[] => {
    if (selectedValue === null) {
      return data
        .filter((community) => {
          const markedAsMatch =
            markedAsFilter === '' || community.markedAs === markedAsFilter;

          return markedAsMatch;
        })
        .map((d: ICommunity, i: number) => {
          return {
            ...d,
            actions: [
              { title: '', action: '', tooltip: 'Delete', icon: Group2Icon },
              {
                title: 'Edit',
                action: 'editNow',
                tooltip: 'Edit',
                icon: EditButton,
              },
              {
                title: '',
                action: 'buildNow',
                tooltip: 'Build',
                icon: buildButton,
              },
            ],
            membership: d.collectSubscription === 'YES' ? 'Paid' : 'Free',
          };
        });
    } else {
      // Return filtered communities based on the selected value
      return data
        .filter((d: ICommunity) => d.title === selectedValue)
        .map((d: ICommunity, i: number) => {
          return {
            ...d,
            actions: [
              { title: '', action: '', tooltip: 'Delete', icon: Group2Icon },
              {
                title: 'Edit',
                action: 'editNow',
                tooltip: 'Edit',
                icon: EditButton,
              },
              {
                title: '',
                action: 'buildNow',
                tooltip: 'Build',
                icon: buildButton,
              },
            ],
            membership: d.collectSubscription === 'YES' ? 'Paid' : 'Free',
          };
        });
    }
  };

  const communityTitles = communities
    ? communities.map((community) => community.title)
    : [];
  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const sortedCommunities = [...communities].sort((a, b) => {
    if (sorting === 'asc') {
      return (
        new Date(a.joinedDate).getTime() - new Date(b.joinedDate).getTime()
      );
    } else {
      return (
        new Date(b.joinedDate).getTime() - new Date(a.joinedDate).getTime()
      );
    }
  });
  const handleStatusFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value, checked } = event.target;
    console.log('Checkbox value:', value);
    console.log('Checkbox checked:', checked);
    if (checked) {
      setStatusFilter((prevFilters) => [...prevFilters, value]);
    } else {
      setStatusFilter((prevFilters) =>
        prevFilters.filter((filter) => filter !== value)
      );
    }
    console.log('Status Filters:', statusFilter);
  };
  const handleMembershipFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value, checked } = event.target;
    if (checked) {
      setMembershipFilter((prevFilters) => [...prevFilters, value]);
    } else {
      setMembershipFilter((prevFilters) =>
        prevFilters.filter((filter) => filter !== value)
      );
    }
  };
  const filteredData = sortedCommunities.filter((item: ICommunity) => {
    if (statusFilter.length === 0 && membershipFilter.length === 0) {
      return true;
    }
    // Check if the community's status matches any of the selected status filters
    const statusMatch =
      statusFilter.length === 0 || statusFilter.includes(item.status);
    const membershipMatch =
      membershipFilter.length === 0 ||
      membershipFilter.includes(item.collectSubscription);

    // Check if the community's membership matches the selected membership filter

    // Return true only if both status and membership match the filters
    return statusMatch && membershipMatch;
  });

  const handleClearButtonClick = () => {
    // Reset all filter states to their default values
    setMarkedAsFilter('');
    setCategoryFilter('');
    setStatusFilter([]);
    setMembershipFilter([]);
    setCheckboxes({
      checkbox1: false,
      checkbox2: false,
      checkbox3: false,
      checkbox4: false,
      checkbox5: false,
      checkbox6: false,
      checkbox7: false,
      checkbox8: false,
    });
  };
  const [open, setOpen] = useState(false);
  const [openxs, setOpenxs] = useState(false);

  const handleDoneButtonClick = () => {
    setOpen(false);
    setOpenxs(false);
    // Close the select
  };
  // Function to handle sorting based on column title

  return (
    <>
      <Helmet>
        <title>{metaConfig.title.communities}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.author} />
        <meta name="keywords" content={metaConfig.keywords} />
      </Helmet>
      <Stack direction={'column'} spacing={1} sx={{ height: '100%' }}>
        {/* for large screen filters */}
        <Stack
          direction="row"
          display={{
            xs: 'none',
            sm: 'none',
            md: 'flex',
            lg: 'flex',
            xl: 'flex',
          }}
        >
          <Stack direction={'row'} flexGrow={1} spacing={1} alignItems={'center'}>
            <Stack flexGrow={1} sx={{ display: { xs: 'flex', md: 'none' }, pb: 0 }} flexDirection={'row'} gap={1} display={'flex'} justifyItems={'center'} alignItems={'center'}>
              {/* <ArrowBackIosNewOutlinedIcon sx={{ fontSize: '18px' }} onClick={() => {
                    navigate('/your-target-route');
                }} /> */}
              <Typography
                sx={{
                  fontSize: { xs: '14px' },
                  fontWeight: 600,
                  color: '#000000',
                  fontFamily: 'Montserrat',
                }}
              >
                Communities
              </Typography>
            </Stack>
            <SubNavbar>
              <Stack direction={'row'} flexGrow={1} alignItems={'center'} ml={0} justifyItems={'center'}>
                <Stack direction={'row'} flexGrow={1} alignItems={'center'} sx={{ display: { xs: 'none', md: 'block' } }}>
                  <Typography
                    sx={{
                      fontSize: { xs: '15px', md: '16px' },
                      fontWeight: 600,
                      color: '#000000',
                      fontFamily: 'Montserrat',
                    }}
                  >
                    Communities
                  </Typography>
                </Stack>
              </Stack >
            </SubNavbar>
          </Stack>
          <Stack direction={'row-reverse'} flexGrow={1} spacing={2}>
            <Box component="form" noValidate autoComplete="off">
              <FormControl size="small">
                <Button
                  variant="contained"
                  onClick={handleCreateNew}
                  endIcon={<AddCircleOutlineOutlined />}
                  sx={{
                    textTransform: 'capitalize',
                    borderRadius: '8px',
                    backgroundColor: '#3C5AA0',
                    fontFamily: 'Montserrat',
                    boxShadow: 'none',
                    '&:hover': {
                      backgroundColor: '#3C5AA0',
                      cursor: 'pointer',
                      border: 'none',
                    },
                  }}
                >
                  Create Community
                </Button>
              </FormControl>
            </Box>
            <Box component="form" noValidate autoComplete="off">
              <FormControl size="small">
                <Select
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        // borderTop: "none",
                      },
                    },
                  }}
                  open={open}
                  onOpen={() => setOpen(true)}
                  onClose={() => setOpen(false)}
                  sx={{
                    backgroundColor: '#f9f9f9',
                    // height:'5vh',
                    borderRadius: '8px',
                    '& .MuiSelect-icon': {
                      color: '#757575',
                    },
                    //  fontSize: "11px" ,font:'Roboto'
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <FilterListIcon />
                      <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px' }}> Filters</Typography>
                    </InputAdornment>
                  }
                >
                  <Accordion
                    disableGutters={true}
                    sx={{ padding: '0px' }}
                    elevation={0}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography sx={{ fontSize: '13px', fontFamily: 'Montserrat' }}>
                        Marked as
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ paddingTop: '0px' }}>
                      <Stack>
                        <FormControlLabel
                          sx={{
                            '& .MuiFormControlLabel-label': {
                              fontSize: '13px',
                              fontFamily: 'Montserrat'
                            },
                          }}
                          control={
                            <Checkbox
                              name="checkbox1"
                              checked={checkboxes.checkbox1}
                              onChange={handleCheckboxChange}
                              size="small"
                            />
                          }
                          label="Public"
                        />
                        <FormControlLabel
                          sx={{
                            '& .MuiFormControlLabel-label': {
                              fontSize: '13px',
                              fontFamily: 'Montserrat'
                            },
                          }}
                          control={
                            <Checkbox
                              name="checkbox2"
                              checked={checkboxes.checkbox2}
                              onChange={handleCheckboxChange}
                              size="small"
                            />
                          }
                          label="Private"
                        />
                      </Stack>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion disableGutters={true} elevation={0}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography sx={{ fontSize: '13px', fontFamily: 'Montserrat' }}>
                        Membership
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ paddingTop: '0px' }}>
                      <Stack>
                        <FormControlLabel
                          sx={{
                            '& .MuiFormControlLabel-label': {
                              fontSize: '13px',
                              fontFamily: 'Montserrat'
                            },
                          }}
                          control={
                            <Checkbox
                              checked={membershipFilter.includes('YES')}
                              onChange={handleMembershipFilterChange}
                              value="YES"
                              size="small"
                            />
                          }
                          label="Paid"
                        />
                        <FormControlLabel
                          sx={{
                            '& .MuiFormControlLabel-label': {
                              fontSize: '13px',
                              fontFamily: 'Montserrat'
                            },
                          }}
                          control={
                            <Checkbox
                              checked={membershipFilter.includes('NO')}
                              onChange={handleMembershipFilterChange}
                              value="NO"
                              size="small"
                            />
                          }
                          label="Free"
                        />
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion disableGutters={true} elevation={0}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography sx={{ fontSize: '13px', fontFamily: 'Montserrat' }}>
                        Status
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ paddingTop: '0px' }}>
                      <Stack>
                        {/* <FormControlLabel
                        control={
                          <Checkbox
                            checked={statusFilter.includes("ACTIVE")}
                            onChange={handleStatusFilterChange}
                            value="ACTIVE"
                          />
                        }
                        label="Active"
                      /> */}
                        {/* <FormControlLabel
                        control={
                          <Checkbox
                            checked={statusFilter.includes("IN_ACTIVE")}
                            onChange={handleStatusFilterChange}
                            value="IN_ACTIVE"
                          />
                        }
                        label="Inactive"
                      /> */}
                        <FormControlLabel
                          sx={{
                            '& .MuiFormControlLabel-label': {
                              fontSize: '13px',
                              fontFamily: 'Montserrat'
                            },
                          }}
                          control={
                            <Checkbox
                              checked={statusFilter.includes('CREATED')}
                              onChange={handleStatusFilterChange}
                              value="CREATED"
                              size="small"
                            />
                          }
                          label="Created"
                        />
                        <FormControlLabel
                          sx={{
                            '& .MuiFormControlLabel-label': {
                              fontSize: '13px',
                              fontFamily: 'Montserrat'
                            },
                          }}
                          control={
                            <Checkbox
                              checked={statusFilter.includes('PUBLISHED')}
                              onChange={handleStatusFilterChange}
                              value="PUBLISHED"
                              size="small"
                            />
                          }
                          label="Published"
                        />
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                  {open && (
                    <Stack direction={'row-reverse'}>
                      <Button
                        onClick={handleClearButtonClick}
                        variant="outlined"
                        size="small"
                        sx={{
                          m: 1,
                          color: '#3C5AA0',
                          textTransform: 'capitalize',
                          fontFamily: 'Montserrat'
                        }}
                      >
                        Clear
                      </Button>
                      <Button
                        variant="contained"
                        onClick={handleDoneButtonClick}
                        size="small"
                        sx={{
                          m: 1,
                          backgroundColor: '#2952A2',
                          textTransform: 'capitalize',
                          fontFamily: 'Montserrat'
                        }}
                      >
                        Done
                      </Button>
                    </Stack>
                  )}
                </Select>
              </FormControl>
            </Box>

            <Autocomplete
              options={communityTitles}
              componentsProps={{
                paper: {
                  sx: {
                    padding: '0px',
                  },
                },
              }}
              size="small"
              sx={{
                width: '16vw',
                backgroundColor: '#f9f9f9',
                borderRadius: '8px',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                },
                '& .MuiChip-root': {
                  borderRadius: '8px',
                },
              }}
              ListboxProps={{ sx: { fontSize: '13px', font: 'Roboto' } }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Community"
                  size="small"
                  sx={{
                    padding: '0px', color: 'red', fontFamily: 'Montserrat',
                    fontSize: '13px',
                    '& .MuiInputLabel-root': {
                      fontFamily: 'Montserrat',
                      fontSize: '15px',
                    },
                    '& input::placeholder': {
                      fontFamily: 'Montserrat',
                      fontSize: '14px',
                      color: '#9e9e9e',
                      opacity: 1,
                    },
                  }}

                />
              )}
              onChange={(event, newValue) => {
                setSelectedValue(newValue); // Update the selected value
              }}
            />
          </Stack>
        </Stack>
        {/* for small screen filters  */}
        <Stack
          direction="row"
          display={{
            xs: 'flex',
            sm: 'flex',
            md: 'none',
            lg: 'none',
            xl: 'none',
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Box component="form" noValidate autoComplete="off">
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 600,
                    color: '#000000',
                    fontFamily: 'Montserrat',
                    // ml: 2,
                  }}
                >
                  Communities
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} display={'flex'} justifyContent={'end'}>
              <Box component="form" noValidate autoComplete="off">
                <FormControl size="small" >
                  <Button
                    variant="contained"
                    onClick={handleCreateNew}
                    endIcon={<AddCircleOutlineOutlined />}
                    sx={{
                      textTransform: 'capitalize',
                      borderRadius: '8px',
                      backgroundColor: '#2952A2',
                      flexShrink: 0,
                      fontSize: '12px',
                      fontFamily: 'Montserrat',
                      boxShadow: 'none',
                      '&:hover': {
                        backgroundColor: '#50A1CA',
                        cursor: 'pointer',
                        border: 'none',
                      },
                    }}
                  >
                    Create
                  </Button>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box component="form" noValidate autoComplete="off">
                <FormControl size="small" fullWidth>
                  <Select
                    MenuProps={{
                      PaperProps: {
                        sx: {},
                      },
                    }}
                    open={openxs}
                    onOpen={() => setOpenxs(true)}
                    onClose={() => setOpenxs(false)}
                    sx={{
                      backgroundColor: '#f9f9f9',
                      borderRadius: '8px',
                      '& .MuiSelect-icon': {
                        color: '#757575',
                      },
                    }}
                    startAdornment={
                      <InputAdornment position="start">
                        <FilterListIcon sx={{ fontSize: { xs: '16px' } }} />
                        <Typography sx={{ fontSize: { xs: '12px' }, fontFamily: 'Montserrat' }}> Filters</Typography>
                      </InputAdornment>
                    }
                  >
                    <Accordion
                      disableGutters={true}
                      sx={{ padding: '0px' }}
                      elevation={0}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography sx={{ fontSize: '13px', fontFamily: 'Montserrat' }}>
                          Marked as
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{ paddingTop: '0px' }}>
                        <Stack>
                          <FormControlLabel
                            sx={{
                              '& .MuiFormControlLabel-label': {
                                fontSize: '13px',
                                fontFamily: 'Montserrat'
                              },
                            }}
                            control={
                              <Checkbox
                                name="checkbox1"
                                checked={checkboxes.checkbox1}
                                onChange={handleCheckboxChange}
                                size="small"
                              />
                            }
                            label="Public"
                          />
                          <FormControlLabel
                            sx={{
                              '& .MuiFormControlLabel-label': {
                                fontSize: '13px',
                                fontFamily: 'Montserrat'
                              },
                            }}
                            control={
                              <Checkbox
                                name="checkbox2"
                                checked={checkboxes.checkbox2}
                                onChange={handleCheckboxChange}
                                size="small"
                              />
                            }
                            label="Private"
                          />
                        </Stack>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion disableGutters={true} elevation={0}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography sx={{ fontSize: '13px', fontFamily: 'Montserrat' }}>
                          Membership
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{ paddingTop: '0px' }}>
                        <Stack>
                          <FormControlLabel
                            sx={{
                              '& .MuiFormControlLabel-label': {
                                fontSize: '13px',
                                fontFamily: 'Montserrat'
                              },
                            }}
                            control={
                              <Checkbox
                                checked={membershipFilter.includes('YES')}
                                onChange={handleMembershipFilterChange}
                                value="YES"
                                size="small"
                              />
                            }
                            label="Paid"
                          />
                          <FormControlLabel
                            sx={{
                              '& .MuiFormControlLabel-label': {
                                fontSize: '13px',
                                fontFamily: 'Montserrat'
                              },
                            }}
                            control={
                              <Checkbox
                                checked={membershipFilter.includes('NO')}
                                onChange={handleMembershipFilterChange}
                                value="NO"
                                size="small"
                              />
                            }
                            label="Free"
                          />
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion disableGutters={true} elevation={0}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography sx={{ fontSize: '13px', fontFamily: 'Montserrat' }}>
                          Status
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{ paddingTop: '0px' }}>
                        <Stack>
                          <FormControlLabel
                            sx={{
                              '& .MuiFormControlLabel-label': {
                                fontSize: '13px',
                                fontFamily: 'Montserrat'
                              },
                            }}
                            control={
                              <Checkbox
                                checked={statusFilter.includes('CREATED')}
                                onChange={handleStatusFilterChange}
                                value="CREATED"
                                size="small"
                              />
                            }
                            label="Created"
                          />
                          <FormControlLabel
                            sx={{
                              '& .MuiFormControlLabel-label': {
                                fontSize: '13px',
                                fontFamily: 'Montserrat'
                              },
                            }}
                            control={
                              <Checkbox
                                checked={statusFilter.includes('PUBLISHED')}
                                onChange={handleStatusFilterChange}
                                value="PUBLISHED"
                                size="small"
                              />
                            }
                            label="Published"
                          />
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                    {openxs && (
                      <Stack direction={'row-reverse'}>
                        <Button
                          onClick={handleClearButtonClick}
                          variant="outlined"
                          size="small"
                          sx={{
                            m: 1,
                            color: '#3C5AA0',
                            textTransform: 'capitalize',
                            fontFamily: 'Montserrat'
                          }}
                        >
                          Clear
                        </Button>
                        <Button
                          variant="contained"
                          onClick={handleDoneButtonClick}
                          size="small"
                          sx={{
                            m: 1,
                            backgroundColor: '#2952A2',
                            textTransform: 'capitalize',
                            fontFamily: 'Montserrat'
                          }}
                        >
                          Done
                        </Button>
                      </Stack>
                    )}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                fullWidth
                options={communityTitles}
                componentsProps={{
                  paper: {
                    sx: {
                      padding: '0px',
                    },
                  },
                }}
                size="small"
                sx={{
                  width: 'auto',
                  backgroundColor: '#f9f9f9',
                  borderRadius: '8px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '8px',
                  },
                  '& .MuiChip-root': {
                    borderRadius: '8px',
                  },
                }}
                ListboxProps={{
                  sx: { fontSize: '13px', font: 'Roboto', padding: '0px' },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Community"
                    size="small"
                    sx={{
                      padding: '0px', fontSize: '5px', color: 'red',
                      '& .MuiInputLabel-root': {
                        fontFamily: 'Montserrat',
                        fontSize: '12px',
                      },
                      '& input::placeholder': {
                        fontFamily: 'Montserrat',
                        fontSize: '12px',
                        color: '#9e9e9e',
                        opacity: 1,
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        fontSize: { xs: '12px', md: '15px', },
                        mt: 0.3
                      }
                    }}
                  />
                )}
                onChange={(event, newValue) => {
                  setSelectedValue(newValue);
                }}
              />
            </Grid>
          </Grid>

          <Stack direction={'row-reverse'} flexGrow={1} spacing={2}></Stack>
        </Stack>

        <Stack>
          <ContentWrapper >
            {isLoading ? (
              <Loader />
            ) : (
              <CommunityTable
                onRowClick={handleRowClick}
                data={attachActions(filteredData, selectedValue)}
                columns={columns}
                onActionClick={handleActionClick}
                checkboxes={checkboxes}
                handleCheckboxChange={handleCheckboxChange}
                markedAsFilter={markedAsFilter}
                categoryFilter={categoryFilter}
                statusFilter={statusFilter}
                membershipFilter={membershipFilter}
              />
            )}
          </ContentWrapper>
        </Stack>
      </Stack>
    </>
  );
};
