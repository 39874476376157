import { ICommunity, ICreateCommunity } from "../../../models/communities.modal";
import { useState } from "react";
import React from "react";
import { useSnackbar } from "notistack";
import { Box, Button, Grid, IconButton, Stack, TextField, Typography } from "@mui/material";
import TypographyField from "../../AppComponents/Buttons/Typography";
import Logo from '../../../assets/images/Communn-favicon.png'
// import { EditIcon } from "../../../assets/icons";
// import { LinkOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { LinkShareIcon } from "../../../assets/icons";
import { useCommunity } from "../../../hooks/useCommunity";
import { useDispatch } from "react-redux";
import { loadSelectedCommunity } from "../../../store/slices/CommunitySlice";


interface IProps {
    CommunityData: ICreateCommunity | null;
    updateCommunityData: (data: Partial<ICreateCommunity>) => void;
    setCommunityData: any;

}


export interface IUpdateCommunity {
    community: string;
    zipCode: number;
    fullAddress: string;
    description: string;
    vision: string;
    mission: string;
    adminMessage: string;
    directorMessage: string;
    phoneNumber: number;
    email: string;
    location: string;
    mobileNumber: number;
    logo: string;
    banner: string;
    gallery: string[];

}



const Congrats = ({ CommunityData,
    updateCommunityData,
    setCommunityData,
}: IProps) => {


    const [page, setPage] = useState(3);


    const { enqueueSnackbar } = useSnackbar();


    const { getCommunityById } = useCommunity();


    const handleChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        updateCommunityData({ [name]: value });
    };


    const handleSubmit = async (e: any) => {
        e.preventDefault();
        try {
        } catch (error) {
            console.log('An error occurred:', error);
        }
    };



    const navigate = useNavigate()

    const handleClickLink = () => {
        navigate(`/get-started`)
    }


    const dispatch = useDispatch();


    // const handleButtonClick = () => {
    //     const { id } = CommunityData;
    //     navigate('/get-started', { state: { id } });
    //     dispatch(loadSelectedCommunity(CommunityData));
    // };




    return (
        <>
            {page === 3 && (
                <>
                    <Stack direction={'column'} spacing={1} height={'100%'}>
                        <Grid container sx={{ height: '100vh', p: 2 }} gap={2}>
                            <Grid
                                item
                                xs={12}
                                md={5.9}
                                sx={{
                                    backgroundColor: '#F9F9F9',
                                    display: { xs: 'none', md: 'flex' },
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            ></Grid>
                            <Grid
                                item
                                xs={12}
                                md={5.9}
                                sx={{
                                    display: 'flex',
                                    justifyContent: { xs: 'flex-start', md: 'center' },
                                    flexDirection: 'column',
                                    p: { xs: 1, md: 5 },
                                    pt: { xs: 10, md: 0 }
                                }}
                            >
                                <Stack display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <Box
                                        component={'img'}
                                        src={Logo}
                                        width={50}
                                        mb={2}
                                    />
                                    <TypographyField
                                        name="Congratulations!"
                                        fontSize="22px"
                                        fontWeight={600}
                                        textAlign="center"
                                    />

                                    <Typography
                                        sx={{
                                            fontSize: { xs: "12px", md: "14px", },
                                            fontFamily: "Montserrat",
                                            fontWeight: '400',
                                            textAlign: 'center'
                                        }}>
                                        Your community is ready to go. Start inviting members and
                                        <br>
                                        </br>building connections now!
                                    </Typography>
                                </Stack>
                                <Stack sx={{ mt: 5 }}>

                                    <Typography
                                        sx={{
                                            fontSize: { xs: "12px", md: "14px", },
                                            fontFamily: "Montserrat",
                                            fontWeight: '500',
                                            textAlign: 'justify'
                                        }}>
                                        Community page URL
                                    </Typography>
                                    <Stack display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} gap={2}>
                                        <TextField
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            fullWidth
                                            sx={{
                                                mt: 1,
                                                color: '#646464',
                                                fontWeight: '500',
                                                borderRadius: '8px',
                                                backgroundColor: 'white',
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: '8px',
                                                    '& fieldset': {
                                                        borderColor: '#E7EBF1',
                                                        borderRadius: '8px',
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: '#E7EBF1',
                                                    },
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#E7EBF1',
                                                },
                                            }}
                                            placeholder="Community or business type"
                                            inputProps={{
                                                style: {
                                                    padding: 11,
                                                    fontSize: '12px',
                                                    color: '#646464',
                                                    fontWeight: 400,
                                                    fontFamily: 'Montserrat',
                                                },
                                            }}
                                            size="small"
                                            variant="outlined"
                                            onChange={handleChanges}
                                            value={`https://onecommunn.com/${CommunityData?.name}`}
                                            InputLabelProps={{
                                                shrink: true,
                                                style: { zIndex: 0 },
                                            }}
                                        />
                                        {/* <IconButton sx={{ mt: 1, border: '1px solid #E7EBF1', borderRadius: '10px' }}>
                                            <EditIcon />
                                        </IconButton> */}
                                        <IconButton
                                            onClick={handleClickLink}
                                            sx={{ mt: 1, border: '1px solid #E7EBF1', borderRadius: '10px' }} >
                                            <LinkShareIcon sx={{ color: 'black' }} />
                                        </IconButton>
                                    </Stack>
                                </Stack>
                                <Stack sx={{ pt: 2 }} spacing={2}>
                                    <Button
                                        fullWidth
                                        onClick={handleSubmit}
                                        variant="contained"

                                        sx={{
                                            boxShadow: 'none',
                                            backgroundColor: '#2653A3',
                                            textTransform: 'capitalize',
                                            borderRadius: '8px',
                                            fontFamily: 'Montserrat',
                                            border: '1px solid #2653A3',
                                            fontWeight: 500,
                                            color: 'white',
                                            '&:hover': {
                                                backgroundColor: 'white',
                                                cursor: 'pointer',
                                                boxShadow: 'none',
                                                color: '#2653A3',
                                            },
                                        }}
                                    >
                                        Continue to Portal
                                    </Button>
                                    {/* <Button
                                        fullWidth
                                        onClick={handleSubmit}
                                        variant="outlined"

                                        sx={{
                                            boxShadow: 'none',

                                            textTransform: 'capitalize',
                                            borderRadius: '8px',
                                            fontFamily: 'Montserrat',
                                            border: '1px solid #2653A3',
                                            fontWeight: 500,
                                            color: '#2653A3',
                                            '&:hover': {
                                                backgroundColor: '#2653A3',
                                                cursor: 'pointer',
                                                boxShadow: 'none',
                                                color: 'white',
                                            },
                                        }}
                                    >
                                        Skip to Dashboard
                                    </Button> */}
                                </Stack>
                            </Grid>
                        </Grid>
                    </Stack>
                </>

            )}

        </>
    )
}
export default Congrats;