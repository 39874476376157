import React, { useRef, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import {
  Autocomplete,
  Avatar,
  Button,
  Card,
  CardMedia,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { EditIcon } from '../../../assets/icons';
import { DeleteIcon } from '../../../assets/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useUsers } from '../../../hooks/useUsers';
import { IEditUser } from '../../../models/user.model';
import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';
import {
  EMPTY_STRING,
  INVALID_OTP,
  OTP_LIMIT_REACHED,
  OTP_RESENT,
  OTP_SEND_FAILED,
  OTP_SENT,
} from '../../../utils/UdfMessages';
import { getOtp, verifyOtp } from '../../../services/otp.service';
import { useOtpAuth } from '../../../hooks/otpAuth';
import Close from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import BreadcrumbDouble from '../../AlternatePages/BreadCumsDouble';

// import store from "../../../store";

interface PanImageProps {
  imageUrl: string;
  onRemove: () => void;
}

interface FormValues {
  id: string;
  firstName: string;
  lastName: string;
  emailId: string;
  phoneNumber: string;
  isPhoneVerified: boolean;
  isEmailVerified: boolean;
  description: string;
  pincode: string;
  city: string;
  address: string;
  aadhar: string;
  pan: string;
  userName: string;
  avatar: string;
  about: string;
  whatsappNumber: number
}

const PanImage: React.FC<PanImageProps> = ({ imageUrl, onRemove }) => (
  <div style={{ position: 'relative', margin: '20px 15px' }}>
    <Grid item xs={12} md={12}>
      <Card sx={{ maxWidth: 232, cursor: 'pointer' }}>
        <CardMedia
          component="img"
          height="120vh"
          image={imageUrl}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          alt="Not found"
        />
      </Card>
    </Grid>
    <div
      style={{
        position: 'absolute',
        cursor: 'pointer',
        top: '10px',
        right: '10px',
        color: 'red',
      }}
      onClick={onRemove}
    >
      <DeleteIcon />
    </div>
  </div>
);

export const ProfileEdit = () => {
  // dp image change//
  // const userData = useLocation();
  const { id } = useParams();
  const isMobile = useMediaQuery('(max-width:600px)');
  const { loadUser, editUsers, isUserExists } = useUsers();
  const { sendOtpEmail, verifyEmailOtp } = useOtpAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [userId, setUserId] = useState<string>('');
  const [selectedPanUrl, setSelectedPanUrl] = useState<string>('');
  const panUploadRef = useRef<HTMLInputElement>(null);
  const [selectedAadharUrl, setSelectedAadharUrl] = useState<string>('');
  const AadharUploadRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const [profileImage, setProfileImage] = useState<File | null>(null);
  const [avatarImage, setAvatarImage] = useState('');
  const [userNames] = useState<string[]>([]);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [error, setError] = useState('');
  const [userExistError, setUserExistError] = useState('');

  //const [phoneNumber, setPhoneNumber] = useState("");
  // const [validPhoneNumber, setValidPhoneNumber] = useState(false);
  const [enableGetOTP] = useState(false);
  // eslint-disable-next-line
  const [otpReceived, setOtpReceived] = useState(false);
  // eslint-disable-next-line
  const [otpSuccessMessage, setOtpSuccessMessage] = useState('');
  // const [enteredOpt, setEnteredOtp] = useState("");
  const [resendCount, setResendCount] = useState(0);
  const [remainingTime, setRemainingTime] = useState(60);
  const [timerRunning, setTimerRunning] = useState(false);
  const [isBlocked] = useState(false);
  // eslint-disable-next-line
  const [actionButton, setActionButton] = useState('Login');
  //const [information] = React.useState("");
  //const authContex = useContext(AuthContext);
  const communitiesFromStore = useSelector((state: any) => state?.communities);

  const { communities } = communitiesFromStore;

  const NUMBER_3 = 3;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setConfirm(true);
  };
  const handleConfirmOpen = () => {
    setConfirm(true);
  };
  const handleConfirmClose = () => {
    setConfirm(false);
  };

  // if (!community_id) {
  //   community_id = singleCommunity?._id;
  // }

  const { getUsersList } = useUsers();
  // const [userList, setUserList] = useState<any[]>([]);
  const [userLists, setUserLists] = useState<any>({});
  const loadUserList = async (communityId: string) => {
    // if (open === false) {
    //   return;
    // }
    try {
      setLoading(true);
      const users = await getUsersList(communityId);

      return users || [];
    } catch (error) {
      console.error('Error loading user list:', error);
      return [];
    } finally {
      setLoading(false);
    }
  };
  // const filteredCommunities = communities.filter(
  //   (community: { members: string | any[] }) =>
  //     community?.members && community?.members?.length > 1
  // );
  useEffect(() => {
    // if (open === false) {
    //   return;
    // }
    const fetchUsersForAllCommunities = async () => {
      const lists: any = {};

      for (const community of communities) {
        const users = await loadUserList(community._id);

        lists[community._id] = users;
      }
      setUserLists(lists);
    };

    fetchUsersForAllCommunities();
  }, [communities]);

  const generateOTP = async () => {
    if (resendCount < NUMBER_3) {
      const { data, status } = await getOtp(formValues?.phoneNumber);

      let number = formValues?.phoneNumber.replace(/^.{6}/g, '******');
      setError('');
      if (data && status === 200) {
        setOtpReceived(true);
        setActionButton('Verify OTP');
        setResendCount(resendCount + 1);
        setTimerRunning(true);
        // setGeneratedOTP(data?.opt);
        //console.log(generatedOTP);
        if (resendCount === 0) {
          setOtpSuccessMessage(OTP_SENT.replace('#PHONE_NUMBER', number));
        } else {
          setOtpSuccessMessage(OTP_RESENT.replace('#PHONE_NUMBER', number));
        }
      } else {
        setOtpSuccessMessage('');
        setError(OTP_SEND_FAILED.replace('#PHONE_NUMBER', number));
      }
    } else if (resendCount === 3) {
      setOtpSuccessMessage(OTP_LIMIT_REACHED);
      setTimerRunning(false);
    }
  };

  const handleClickGetMobileOTP = async () => {
    const phoneNumber = formValues?.phoneNumber;
    const userExists = await isUserExists(phoneNumber);
    if (userExists) {
      setUserExistError('User with this phone number already exists.');
      //console.log("User Exist");
      return;
    }
    setOpenDialogs(true);
    generateOTP();
  };

  const handleCloseMobileDialog = () => {
    setOpenDialogs(false);
  };

  useEffect(() => {
    if (timerRunning && remainingTime > 0) {
      const interval = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(interval);
    } else {
      setTimerRunning(false);
      setRemainingTime(60);
    }
  }, [timerRunning, remainingTime]);

  const handleVerifyMobileOTP = async (data: any) => {
    setLoading(true);
    const value = data;
    //console.log(generatedOTP);
    // console.log(value);
    // if (!isNaN(value) && value.toString().length === 6) {
    //   setLoading(true);
    // Call verify OTP to verify
    const verifyResponse = await verifyOtp(formValues?.phoneNumber, value);
    // console.log(verifyResponse);
    if (verifyResponse.status === 200) {
      setActionButton('Verified');
      handleCloseMobileDialog();
      handleEditProfile();
    } else {
      // OTP
      setOtpSuccessMessage(EMPTY_STRING);
      setError(INVALID_OTP);
      setLoading(false);
    }
    setLoading(false);
    // } else {

    // }
    setLoading(false);
  };

  const generateEmailOTP = async () => {
    if (resendCount < NUMBER_3) {
      const response = await sendOtpEmail(formValues?.emailId);
      if (response?.data && response?.status === 200) {
        setOtpReceived(true);
        setActionButton('Verify OTP');
        setResendCount(resendCount + 1);
        setTimerRunning(true);
        if (resendCount === 0) {
          setOtpSuccessMessage(
            OTP_SENT.replace('#EMAIL_ID', formValues?.emailId)
          );
        } else {
          setOtpSuccessMessage(
            OTP_RESENT.replace('#EMAIL_ID', formValues?.emailId)
          );
        }
      } else {
        setOtpSuccessMessage('');
        setError(OTP_SEND_FAILED.replace('#EMAIL_ID', formValues?.emailId));
      }
    } else if (resendCount === 3) {
      setOtpSuccessMessage(OTP_LIMIT_REACHED);
      setTimerRunning(false);
    }
  };

  const handleVerifyEmailOTP = async () => {
    const otp = '123456';
    const response = await verifyEmailOtp(otp, formValues?.emailId);
    if (response?.data && response?.status === 200) {
      handleCloseDialog();
      setActionButton('Verified');
      handleCloseDialog();
      handleEditProfile();
      // if (resendCount === 0) {
      //   setOtpSuccessMessage(OTP_SENT.replace("#EMAIL_ID", formValues?.emailId));
      // } else {
      //   setOtpSuccessMessage(OTP_RESENT.replace("#EMAIL_ID", formValues?.emailId));
      //   handleCloseDialog();
      // }x`
    }
  };

  useEffect(() => {
    if (timerRunning && remainingTime > 0) {
      const interval = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(interval);
    } else {
      setTimerRunning(false);
      setRemainingTime(60);
    }
  }, [timerRunning, remainingTime]);

  const [formValues, setFormValues] = useState<FormValues>({
    firstName: '',
    id: '',
    lastName: '',
    emailId: '',
    phoneNumber: '',
    isPhoneVerified: true,
    isEmailVerified: true,
    userName: '',
    description: '',
    pincode: '',
    city: '',
    address: '',
    aadhar: '',
    pan: '',
    avatar: '',
    about: '',
    whatsappNumber: 0,
  });

  const [usersName, setuserName] = useState<any>(true);
  const [mobileNumber, setmobileNumber] = useState<any>(true);
  // eslint-disable-next-line
  const [userName, setUserName] = useState<string>('');

  useEffect(() => {
    if (!id) return;

    const fetchUser = async () => {
      try {
        if (!id) return;
        setUserId(id);
        const response = await loadUser(id);

        // Update the formValues state with the response data
        setFormValues({
          firstName: response?.firstName ?? ' ',
          lastName: response?.lastName ?? ' ',
          emailId: response?.emailId ?? ' ',
          phoneNumber: response?.phoneNumber ?? ' ',
          isPhoneVerified: response?.isPhoneVerified ?? ' ',
          isEmailVerified: response?.isEmailVerified ?? ' ',
          userName: response.userName ?? ' ',
          description: response.description ?? '',
          pincode: response.pincode ?? '',
          city: response.city ?? '',
          address: response?.address ?? ' ',
          aadhar: response?.aadhar ?? ' ',
          pan: response?.pan ?? ' ',
          avatar: response?.avatar ?? ' ',
          id: response?.id ?? ' ',
          about: response?.about ?? '',
          whatsappNumber: response?.whatsappNumber ?? 0
        });
        setAvatarImage(response.avatar);

        if (response.phoneNumber === 9999999999) {
          setmobileNumber(false);
        }
        if (response.userName === '' || response?.userName === undefined) {
          setuserName(false);
        }
      } catch (error) {
        // Handle errors if needed
        console.error('Error fetching user data:', error);
      }
    };

    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // << Function definition  starts ------------------------------
  const generateUserName = (event: React.ChangeEvent<HTMLInputElement>) => {
    //const { name, value } = event.target;
    if (formValues.lastName.length >= 0) {
      let count = 4;
      while (count > 0) {
        let uname =
          formValues.firstName +
          formValues.lastName.slice(0, 3) +
          Math.floor(1000 + Math.random() * 9000);
        userNames.push(uname.toLocaleLowerCase());
        // count = count -1;
        --count;
      }
      // console.log(userNames)
    }
  };

  const handleUserName = (event: any) => {
    const { outerText } = event.target;
    setUserName(outerText);
    setFormValues({ ...formValues, userName: outerText });
  };
  const handleChangess = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setUserExistError('');
    // console.log(name, value);
    setFormValues((formValues) => ({
      ...formValues,
      [name]: value,
    }));
  };

  const handleImageChange = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';

    fileInput.addEventListener('change', (event) => {
      const file = (event.target as HTMLInputElement).files?.[0];
      if (file) {
        setProfileImage(file);
        const reader = new FileReader();
        reader.onload = (e) => {
          const newProfileImage = e.target?.result as string;
          setAvatarImage(newProfileImage);
        };
        reader.readAsDataURL(file);
      }
    });

    fileInput.click();
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required('Email is required')
      .email('Email is invalid'),
    aadharNumber: Yup.string()
      .length(12, 'Aadhar Number must be 12 digits')
      .required('Aadhar Number is required'),
    //     password: Yup.string()
    //       .required("Password is required")
    //       .min(4, "Password must be at least 6 characters")
    //       .max(40, "Password must not exceed 40 characters"),
  });
  const {
    register,
    // control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  // const handlePanUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = event.target.files?.[0];
  //   if (file) {
  //     const imageUrl = URL.createObjectURL(file);
  //     setSelectedPanUrl(imageUrl);
  //   }
  // };

  // const handlePanRemove = () => {
  //   setSelectedPanUrl('');
  // };

  // const handleClickOnPanUpload = () => {
  //   if (panUploadRef.current) {
  //     panUploadRef.current.click();
  //   }
  // };

  // Aadhar card Logics

  // const handleAadharUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = event.target.files?.[0];
  //   if (file) {
  //     const imageUrl = URL.createObjectURL(file);
  //     setSelectedAadharUrl(imageUrl);
  //   }
  // };

  // const handleAadharRemove = () => {
  //   setSelectedAadharUrl('');
  // };

  // const handleClickOnAadharUpload = () => {
  //   if (AadharUploadRef.current) {
  //     AadharUploadRef.current.click();
  //   }
  // };

  // Toolpit text for KYC icon
  const longText = `
  Verifying KYC is crucial for trust and credibility when creating a community. 
  Without KYC verification, you may not have the necessary means to initiate the community creation process. 
  Rest assured, your KYC details are kept completely safe and secure.
`;

  // get OTP dialog box

  const [openDialog, setOpenDialog] = useState(false);
  //const otpInputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const [openDialogs, setOpenDialogs] = useState(false);
  // const otpInputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleClickGetEmailOTP = async (email: string) => {
    setOpenDialog(true);
    generateEmailOTP();
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
    //resetOtpInputs();
  };

  const handleEditProfile = async () => {
    const profileData = formValues;
    // console.log(profileData)
    const editProfile: IEditUser = profileData;
    const response = await editUsers(userId, editProfile, profileImage);
    if (response) {
      // alert("Edit profile successfully");
      if (isExploreCommunitiesPage) {
        navigate('/explore-communities'); // Navigate to a specific path for Explore Communities
      } else {
        navigate('/home'); // Navigate to the default path for other cases
      }
      enqueueSnackbar(' Profile Updated Successfully', { variant: 'success' });
    }
    // console.log(response);
  };

  const location = useLocation();
  const isExploreCommunitiesPage = /^\/profile-edit\/\d+/.test(location.pathname);

  // const onSubmit = async (data: any) => {
  //   setLoading(true);
  //   const value = data.password;
  //   //console.log(generatedOTP);
  //   console.log(value);
  //   if (!isNaN(value) && value.toString().length === 6) {
  //     setLoading(true);
  //     // Call verify OTP to verify
  //     const verifyResponse= await verifyOtp(mobileNumber,value);
  //   setLoading(false);
  // };
  const handleClick = () => {
    if (communities.length <= 0) {
      handleConfirmOpen();
    } else {
      handleOpen();
    }
  };
  return (
    <>
      <Helmet>
        <title>Edit Profile - Communn - Build & Manage your community</title>
        <meta
          name="description"
          content="Automate Community Management with AI-Powered
          Subscription effortlessly
          Our AI-powered platform enhances community management with tools for user data management, interaction,communication, subscription and payment processing, content management, and advanced analytics"
        />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="author"
          content="Automate Community Management with AI-Powered
          Subscription effortlessly
          Our AI-powered platform enhances community management with tools for user data management, interaction,communication, subscription and payment processing, content management, and advanced analytics"
        />
        <meta
          name="keywords"
          content="Automate Community Management with AI-Powered
          Subscription effortlessly
          Our AI-powered platform enhances community management with tools for user data management, interaction,communication, subscription and payment processing, content management, and advanced analytics"
        />
      </Helmet>
      <Stack
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'row',
          mb: 1
        }}
      >
        <BreadcrumbDouble
          items={[
            { label: 'Home', path: '/explore-communities' },
            { label: ` Profile Details` }
          ]}
        />
      </Stack>
      <Grid
        sx={{
          backgroundColor: '#ffffff',
          pb: { xs: 3, md: 1 },
          borderRadius: '12px !important', border: '1px solid #E7EBF1',
          justifyContent: 'center',
          pt: 2,
          pl: { xs: 1, md: 0 },
          pr: { xs: 1, md: 0 },
          height: { md: "83vh" }
        }}
      >
        <Grid container spacing={4} sx={{ pl: { lg: 2.5 } }}>
          <Grid item lg={6} xs={12} md={6} xl={6} sx={{}}>
            <Paper
              elevation={0}
              sx={{
                backgroundColor: '#FAFAFA',
                borderRadius: '10px',
                pb: 1,
                // height: { xs: '45vh', md: '50vh' },
              }}
            >
              <Stack
                direction={'row'}
                spacing={10}
                alignContent={'space-evenly'}
                sx={{ marginRight: '15px' }}
              >
                <Stack sx={{ justifyContent: 'center' }}>
                  <IconButton
                    aria-label="Edit Profile Image"
                    component="span"
                    onClick={handleImageChange}
                    sx={{
                      width: { xs: '25px', md: '40px' },
                      height: { xs: '25px', md: '40px' },
                      objectFit: 'cover',
                      color: 'white',
                      backgroundColor: 'black',
                      position: 'relative',
                      top: { xs: '130px', md: '73%' },
                      left: { xs: '75%', md: '73%' },
                      zIndex: 1,
                    }}
                  >
                    <EditIcon sx={{ fontSize: { xs: '15px', md: '25px' } }} />
                  </IconButton>
                  <Typography
                    sx={{
                      fontWeight: '600',
                      textTransform: 'uppercase',
                      fontSize: '15px',
                      textAlign: 'center',
                      pb: 0.5,
                      pl: 2,
                    }}
                  ></Typography>
                  <Box
                    component={'img'}
                    src={avatarImage}
                    sx={{
                      width: {
                        xs: '80px',
                        sm: '80px',
                        md: '120px',
                        lg: '120px',
                      },
                      height: {
                        xs: '80px',
                        sm: '80px',
                        md: '120px',
                        lg: '120px',
                      },
                      borderRadius: '90px',
                      marginTop: {
                        xs: '50px',
                        sm: '50px',
                        md: '0px',
                        lg: '0px',
                      },
                      marginLeft: '10px',
                      objectFit: 'cover',
                      background:
                        'linear-gradient(to right bottom, #3C5AA0, #50A1CA) ',
                    }}
                  ></Box>
                </Stack>

                <Stack
                  spacing={3}
                  sx={{
                    pt: { md: 6, sm: 10, xs: 10 },
                    mr: { md: 0, sm: 2, xs: 2 },
                  }}
                  alignContent={'center'}
                >
                  <TextField
                    focused
                    value={formValues?.firstName}
                    name="firstName"
                    placeholder="First Name"
                    size="small"
                    label="First Name"
                    fullWidth
                    onChange={handleChangess}
                    InputLabelProps={{
                      style: { zIndex: 0 },
                    }}
                    sx={{
                      width: '100%',
                      '& .MuiInputLabel-root': {
                        fontFamily: 'Montserrat',
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: '#575757',
                      },
                      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                      {
                        border: '1.5px solid #D6D6D6',
                      },
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '8px',
                      },
                    }}
                  />
                  <TextField
                    focused
                    name="lastName"
                    value={formValues?.lastName}
                    label="Last Name"
                    size="small"
                    fullWidth
                    // sx={{ }}
                    onChange={handleChangess}
                    InputLabelProps={{
                      style: { zIndex: 0 },
                    }}
                    sx={{
                      width: '100%',
                      '& .MuiInputLabel-root': {
                        fontFamily: 'Montserrat',
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: '#575757',
                      },
                      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                      {
                        border: '1.5px solid #D6D6D6',
                      },
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '8px',
                      },
                    }}
                  />
                </Stack>
              </Stack>

              <Box sx={{ pt: 4, pl: 2, pr: 2 }}>
                <TextField
                  focused
                  name="userName"
                  value={formValues?.userName}
                  size="small"
                  fullWidth
                  label="User Name"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChangess(e);
                    generateUserName(e);
                  }}
                  InputProps={{
                    readOnly: usersName,
                  }}
                  InputLabelProps={{
                    style: { zIndex: 0 },
                  }}
                  sx={{
                    '& .MuiInputLabel-root': {
                      fontFamily: 'Montserrat',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: '#575757',
                    },
                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                    {
                      border: '1.5px solid #D6D6D6',
                    },
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '8px',
                    },
                  }}
                />
              </Box>

              {userNames.length > 0 ? (
                <Stack>
                  <Grid container spacing={2} sx={{ pt: 2, pl: 2 }}>
                    <Grid item xs={12}>
                      <Typography
                        sx={{ fontSize: '15px', fontWeight: 400, pb: 1 }}
                      >
                        Available usernames
                      </Typography>

                      <Grid container spacing={1}>
                        {userNames.slice(0, 4).map((e, index) => (
                          <Grid item key={index} xs={6} sm={6} md={6} lg={3}>
                            <Chip
                              label={e}
                              component="a"
                              sx={{
                                color: '#50A1CA',
                                border: '1px solid #50A1CA',
                                marginBottom: 1,
                              }}
                              variant="outlined"
                              onClick={(e: any) => handleUserName(e)}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Stack>
              ) : (
                ''
              )}

              {/* <Stack>
                    <Grid container spacing={2} sx={{ pt: 4 }}>
                      <Grid item xs={12}>
                        <TextField
                          value={formValues.userName}
                          fullWidth
                          label="Create User Name"
                          size="small"
                          name="userName"
                          onChange={handleChangess}
                          InputProps={{
                            readOnly: usersName,
                          }}
                        />
                       }
                      </Grid>
                    </Grid>
                  </Stack> */}

              <Box sx={{ pt: 4, pl: 2, pr: 2 }}>
                <TextField
                  focused
                  onChange={handleChangess}
                  name="phoneNumber"
                  value={formValues?.phoneNumber}
                  size="small"
                  fullWidth
                  label="Mobile Number"
                  inputProps={{
                    pattern: '[0-9]*',
                    minLength: 10,
                    maxLength: 10,
                  }}
                  InputLabelProps={{
                    style: { zIndex: 0 },
                  }}
                  sx={{
                    '& .MuiInputLabel-root': {
                      fontFamily: 'Montserrat',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: '#575757',
                    },
                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                    {
                      border: '1.5px solid #D6D6D6',
                    },
                  }}
                  InputProps={{
                    readOnly: mobileNumber,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Divider
                          sx={{ height: 28, m: 0.5 }}
                          orientation="vertical"
                        />
                        {formValues?.isPhoneVerified ? (
                          <Button
                            variant="text"
                            sx={{
                              textTransform: 'capitalize',
                              color: '#10A00D',
                              fontFamily: 'Montserrat',

                            }}
                          >
                            Verified
                          </Button>
                        ) : (
                          <Button
                            variant="text"
                            sx={{
                              textTransform: 'capitalize',
                              color: '#50A1CA',
                            }}
                            onClick={handleClickGetMobileOTP}
                            disabled={enableGetOTP || isBlocked || timerRunning}
                          // onClick={generateOTP}
                          >
                            {timerRunning
                              ? `Resend OTP in ${remainingTime}s`
                              : resendCount === 0
                                ? `Get OTP`
                                : `Resend OTP`}
                          </Button>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
                <Typography sx={{ fontSize: '12px', color: 'red' }}>
                  {userExistError}
                </Typography>

                <Dialog
                  open={openDialogs}
                  onClose={handleCloseMobileDialog}
                  maxWidth="sm"
                  fullWidth
                >
                  <DialogActions sx={{ backgroundColor: '#F0F9FF' }}>
                    <Stack
                      direction={'row'}
                      justifyContent={'space-evenly'}
                      alignItems={'center'}
                      sx={{ backgroundColor: '#F0F9FF' }}
                    >
                      {/* <Stack>Enter OTP</Stack> */}
                      <Stack></Stack>
                      <Stack sx={{ backgroundColor: '#F0F9FF' }}>
                        <Box
                          onClick={handleCloseMobileDialog}
                          sx={{
                            backgroundColor: '#3C5AA0',
                            padding: '4px',
                            borderRadius: '50%',
                            width: '20px',
                            height: '20px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: 'white',
                            fontSize: '10px',
                            mt: '0.5px',
                            mr: '10px',
                            cursor: 'pointer',
                            '&:hover': {
                              // backgroundColor: "#50A1CA", // Background color on hover
                              cursor: 'pointer',
                              border: 'none',
                            },
                          }}
                        >
                          <Close sx={{ fontSize: '15px' }} />
                        </Box>
                      </Stack>
                    </Stack>
                  </DialogActions>
                  <DialogContent sx={{ textAlign: 'center' }}>
                    <Typography
                      variant="subtitle1"
                      sx={{ mb: 2, fontWeight: 800, fontSize: '18px' }}
                    >
                      Please enter OTP to verify your mobile number
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 3, pl: 2 }}>
                      A OTP has been sent to {formValues?.phoneNumber}
                    </Typography>
                    <Box
                      sx={{ display: 'flex', justifyContent: 'center' }}
                    //  component={"form"}
                    //  onSubmit={onSubmit}
                    >
                      <TextField
                        size="small"
                        type="text"
                        placeholder="Enter OTP"
                        inputProps={{
                          maxLength: 6, // Limit input to six characters
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="start"
                              sx={{
                                cursor: 'pointer',
                                backgroundColor: '#E5E3EF',
                                borderRadius: '100px',
                              }}
                            >
                              <Stack
                                divider={
                                  <Divider orientation="vertical" flexItem />
                                }
                              ></Stack>
                            </InputAdornment>
                          ),
                          style: {
                            borderRadius: '30px',
                            backgroundColor: '#ffffff',
                          },
                        }}
                      ></TextField>
                    </Box>
                  </DialogContent>
                  <DialogActions sx={{ justifyContent: 'center' }}>
                    <Button
                      onClick={handleVerifyMobileOTP}
                      variant="contained"
                      sx={{
                        background:
                          'linear-gradient(to right bottom, #3C5AA0, #50A1CA) ',
                        borderRadius: '60px',
                        textTransform: 'capitalize',
                        pl: 5,
                        pr: 5,
                        fontFamily: 'Montserrat',

                      }}
                    >
                      Verify OTP
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>

              <Box sx={{ pt: 4, pl: 2, pr: 2 }}>
                <TextField
                  focused
                  onChange={handleChangess}
                  name="whatsappNumber"
                  value={formValues?.whatsappNumber}
                  size="small"
                  fullWidth
                  label="Whatsapp Number"
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                    minLength: 10,
                    maxLength: 10,
                    onInput: (e: any) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    },
                  }}
                  InputLabelProps={{
                    style: { zIndex: 0 },
                  }}
                  sx={{
                    '& .MuiInputLabel-root': {
                      fontFamily: 'Montserrat',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: '#575757',
                    },
                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {

                      border: "1.5px solid #D6D6D6"
                    },
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '8px',
                    },
                  }}
                />
              </Box>

              <Box sx={{ pt: 4, pl: 2, pr: 2 }}>
                <TextField
                  focused
                  value={formValues.emailId}
                  size="small"
                  label={'Email'}
                  fullWidth
                  InputLabelProps={{
                    style: { zIndex: 0 },
                  }}
                  sx={{

                    width: '100%',
                    '& .MuiInputLabel-root': {
                      fontFamily: 'Montserrat',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: '#575757',
                    },
                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                    {
                      border: '1.5px solid #D6D6D6',
                    },
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '8px',
                    },
                  }}
                  {...register('username')}
                  error={errors.username ? true : false}
                  helperText={
                    errors.username ? (errors.username.message as string) : ''
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Divider
                          sx={{ height: 28, m: 0.5 }}
                          orientation="vertical"
                        />
                        {formValues?.isEmailVerified ? (
                          <Button
                            variant="text"
                            sx={{
                              textTransform: 'capitalize',
                              color: '#10A00D',
                              fontFamily: 'Montserrat',
                              '& .MuiInputLabel-root': {
                                fontFamily: 'Montserrat',
                              },
                            }}
                          >
                            Verified
                          </Button>
                        ) : (
                          // <Button
                          //   variant="text"
                          //   sx={{
                          //     textTransform: "capitalize",
                          //     color: "#50A1CA",
                          //   }}
                          //   onClick={() =>
                          //     handleClickGetEmailOTP(formValues?.emailId)
                          //   }
                          // >
                          //   Get OTP
                          // </Button>
                          <Button
                            variant="text"
                            sx={{
                              textTransform: 'capitalize',
                              color: '#10A00D',
                              fontFamily: 'Montserrat',

                            }}
                          >
                            Verified
                          </Button>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
                {/* <Stack direction={"row"} spacing={2} sx={{ mt: 3 }}> */}
                {/* <Grid item xs={12} sm={12} xl={6} md={6}> */}
                {/* <TextField
                      focused
                      value={formValues.password}
                      onChange={handleChange}
                      size="small"
                      name="password"
                      fullWidth
                       type={createShowPassword ? "text" : "password"}
                      label={"Create Password"}
                      placeholder="Create Password"

                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                             onClick={handleClickCreateShowPassword}
                            position="start"
                            sx={{ cursor: "pointer" }}
                          >
                            {createShowPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        style: { zIndex: 0 },
                      }}
                      sx={{
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: '#575757',
                        },
                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {

                          border: "1.5px solid #D6D6D6"
                        },
                        "& .MuiInputBase-root-MuiOutlinedInput-root": {
                          pr: 0,
                        },
                      }}
                    /> */}
                {/* {formErrors.password && (
                          <Stack sx={error}>{formErrors.password}
                          </Stack>
                        )} */}
                {/* </Grid> */}
                {/* <Grid item xs={12} xl={6}> */}
                {/* <TextField
                      value={formValues.confirmPassword}
                      onChange={handleChange}
                      focused
                      name="confirmPassword"
                      size="small"
                      fullWidth
                    type={confirmShowPassword ? "text" : "password"}
                      label={"Confirm Password"}
                     placeholder="Password"
                      InputLabelProps={{
                        style: { zIndex: 0 },
                      }}


                      sx={{
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: '#575757',
                        },
                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {

                          border: "1.5px solid #D6D6D6"
                        },
                        "& .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root":
                        {
                          pr: 0,
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                             onClick={handleClickConfirmShowPassword}
                            position="start"
                            sx={{ cursor: "pointer" }}
                          >
                            {confirmShowPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                          </InputAdornment>
                        ),
                      }}
                    /> */}
                {/* {formErrors.confirmPassword && (
                          <Stack sx={error}>{formErrors.confirmPassword}</Stack>
                        )} */}
                {/* </Grid> */}
                {/* </Stack> */}
                {/* Email Verify OTP Popup */}
                <Dialog
                  open={openDialog}
                  onClose={handleCloseDialog}
                  maxWidth="sm"
                  fullWidth
                >
                  <DialogActions sx={{ backgroundColor: '#F0F9FF' }}>
                    <Stack
                      direction={'row'}
                      justifyContent={'space-evenly'}
                      alignItems={'center'}
                      sx={{ backgroundColor: '#F0F9FF' }}
                    >
                      {/* <Stack>Enter OTP</Stack> */}
                      <Stack></Stack>
                      <Stack sx={{ backgroundColor: '#F0F9FF' }}>
                        <Box
                          onClick={handleCloseDialog}
                          sx={{
                            backgroundColor: '#3C5AA0',
                            padding: '4px',
                            borderRadius: '50%',
                            width: '20px',
                            height: '20px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: 'white',
                            fontSize: '10px',
                            mt: '0.5px',
                            mr: '10px',
                            cursor: 'pointer',
                            '&:hover': {
                              // backgroundColor: "#50A1CA", // Background color on hover
                              cursor: 'pointer',
                              border: 'none',
                            },
                          }}
                        >
                          <Close sx={{ fontSize: '15px' }} />
                        </Box>
                      </Stack>
                    </Stack>
                  </DialogActions>
                  {/* <DialogTitle>
                    <IconButton
                      sx={{ position: "absolute", top: 8, right: 8 }}
                      onClick={handleCloseDialog}
                    >
                      <CloseIcon />
                    </IconButton>
                    Enter OTP
                  </DialogTitle> */}
                  {/* <Divider /> */}
                  <DialogContent sx={{ textAlign: 'center' }}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        mb: 2, fontWeight: 800, fontSize: '18px', '& .MuiInputLabel-root': {
                          fontFamily: 'Montserrat',
                        },
                      }}
                    >
                      Please enter OTP to verify your Email ID
                    </Typography>
                    <Typography variant="body2" sx={{
                      mb: 3, pl: 2, '& .MuiInputLabel-root': {
                        fontFamily: 'Montserrat',
                      },
                    }}>
                      A OTP has been sent to {formValues?.emailId}
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      {/* {[0, 1, 2, 3, 4, 5].map((index) => (
                        <TextField
                          key={index}
                          autoFocus={index === 0}
                          size="small"
                          inputRef={(ref) =>
                            (otpInputRefs.current[index] = ref)
                          }
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleOtpChange(
                              index,
                              event as  React.ChangeEvent<HTMLInputElement>
                            )
                          }
                          inputProps={{ maxLength: 1 }}
                          sx={{ mx: 1, textAlign: "center", width: 40 }}
                        />
                      ))} */}

                      <TextField
                        size="small"
                        required
                        defaultValue=""
                        type="text"
                        placeholder="Enter OTP"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="start"
                              sx={{
                                cursor: 'pointer',
                                backgroundColor: '#E5E3EF',
                                borderRadius: '100px',
                              }}
                            >
                              <Stack
                                divider={
                                  <Divider orientation="vertical" flexItem />
                                }
                              ></Stack>
                            </InputAdornment>
                          ),
                          style: {
                            borderRadius: '30px',
                            backgroundColor: '#ffffff',
                          },
                        }}
                        inputProps={{
                          maxLength: 6, // Limit input to 6 characters
                        }}
                      ></TextField>
                    </Box>
                  </DialogContent>
                  <DialogActions sx={{ justifyContent: 'center' }}>
                    <Button
                      onClick={handleVerifyEmailOTP}
                      variant="contained"
                      sx={{
                        background:
                          'linear-gradient(to right bottom, #3C5AA0, #50A1CA) ',
                        borderRadius: '60px',
                        textTransform: 'capitalize',
                        boxShadow: 'none',
                        pl: 9,
                        pr: 9,
                        fontFamily: 'Montserrat',

                      }}
                    >
                      Verify OTP
                    </Button>
                  </DialogActions>

                  <DialogActions sx={{ justifyContent: 'center', pb: 2 }}>
                    {/* <Link
                      style={{ color: "#A3A3A3", textDecoration: "none" }}
                      onClick={handleResendOTP}
                      to={""}
                    >
                      Resend OTP
                    </Link> */}
                  </DialogActions>
                </Dialog>
              </Box>


            </Paper>
          </Grid>

          <Grid
            item
            md={6}
            xl={6}
            lg={6}
            xs={12}
            sx={{ pr: { lg: 2.5 }, pb: 2, mt: { xs: 12, sm: 12, md: 0 } }}
          >
            <Grid container spacing={isMobile ? 20 : 0}></Grid>
            <Paper
              elevation={0}
              sx={{
                pt: 2,
                pb: 2,
                borderRadius: '10px',
                backgroundColor: '#FAFAFA',
                // height: { xs: '45vh', md: '50vh' },

              }}
            >
              <Stack
                direction="row"
                spacing={2}
                justifyContent="center"
                sx={{ mx: 2, pt: 4, pb: 2.5 }}
              >
                <TextField
                  focused
                  onChange={handleChangess}
                  name="about"
                  value={formValues.about}
                  size="small"
                  fullWidth
                  label="About Me"
                  multiline
                  rows={3}
                  InputLabelProps={{
                    style: { zIndex: 0 },
                  }}
                  sx={{
                    '& .MuiInputLabel-root': {
                      fontFamily: 'Montserrat',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: '#575757',
                    },
                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                    {
                      border: '1.5px solid #D6D6D6',
                    },
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '8px',
                    },
                  }}
                />
              </Stack>
              <Box sx={{ pl: 2, pr: 2 }}></Box>

              <Typography
                sx={{ fontWeight: 500, pt: 1, mx: 2, pb: 2, fontSize: '14px', fontFamily: 'Montserrat' }}
              >
                Address
              </Typography>
              <Stack spacing={3} justifyContent="center" sx={{ mt: 1, mx: 2 }}>
                <TextField
                  focused
                  onChange={handleChangess}
                  name="address"
                  value={formValues.address}
                  multiline
                  rows={3.5}
                  size="small"
                  fullWidth
                  label="Address Line"
                  id="fullWidth"
                  InputLabelProps={{
                    style: { zIndex: 0 },
                  }}
                  sx={{
                    '& .MuiInputLabel-root': {
                      fontFamily: 'Montserrat',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: '#575757',
                    },
                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                    {
                      border: '1.5px solid #D6D6D6',
                    },
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '8px',
                    },
                  }}
                />
                <Stack direction="row">
                  <TextField
                    focused
                    onChange={handleChangess}
                    name="pincode"
                    value={formValues.pincode}
                    size="small"
                    fullWidth
                    label="Pin/Zip code"
                    id="fullWidth"
                    InputLabelProps={{
                      style: { zIndex: 0 },
                    }}
                    sx={{
                      fontSize: '12px',
                      '& .MuiInputLabel-root': {
                        fontFamily: 'Montserrat',
                      },
                      '& fieldset': {
                        borderTopRightRadius: '0',
                        borderBottomRightRadius: '0',
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: '#575757',
                      },
                      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                      {
                        border: '1.5px solid #D6D6D6',
                      },
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '8px',
                      },
                    }}
                  />
                  <TextField
                    focused
                    onChange={handleChangess}
                    name="city"
                    value={formValues.city}
                    size="small"
                    fullWidth
                    label="City"
                    id="fullWidth"
                    InputLabelProps={{
                      style: { zIndex: 0 },
                    }}
                    sx={{
                      fontSize: '12px',
                      '& .MuiInputLabel-root': {
                        fontFamily: 'Montserrat',
                      },
                      '& fieldset': {
                        borderTopLeftRadius: '0',
                        borderBottomLeftRadius: '0',
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: '#575757',
                      },
                      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                      {
                        border: '1.5px solid #D6D6D6',
                      },
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '8px',
                      },
                    }}
                  />
                </Stack>
              </Stack>
              {/* <Stack direction="row">
                <Typography
                  sx={{ fontWeight: 500, pt: 2, mx: 2, fontSize: '14px' }}
                >
                  KYC Verification
                </Typography>
                <Tooltip
                  title={longText}
                  placement="right-start"
                  arrow
                  sx={{ ':hover': { backgroundColor: '#FAFAFA' } }}
                >
                  <IconButton sx={{ pt: 2 }}>
                    <InfoIcon sx={{ fontSize: '18px' }} />
                  </IconButton>
                </Tooltip>
              </Stack>

              <Grid container sx={{ pb: 3.5, pt: 2.5 }} spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    focused
                    onChange={handleChangess}
                    value={formValues?.aadhar}
                    size="small"
                    fullWidth
                    label="12-digit Aadhar Card Number"
                    InputLabelProps={{
                      style: { zIndex: 0, paddingLeft: '8px' },
                    }}
                    sx={{
                      pt: 0,
                      pb: 0,
                      pl: 1,
                      pr: 1,
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: '#575757',
                      },
                      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                      {
                        border: '1.5px solid #D6D6D6',
                      },
                    }}
                    name="aadhar"
                  />
                  <Box sx={{ pt: 2, pb: 0, pl: 1, pr: 1 }}>
                    <Stack>
                      <input
                        type="file"
                        hidden
                        ref={AadharUploadRef}
                        onChange={handleAadharUpload}
                        accept="image/png, image/jpeg, image/WebP"
                      />
                      {selectedAadharUrl ? (
                        <PanImage
                          imageUrl={selectedAadharUrl}
                          onRemove={handleAadharRemove}
                        />
                      ) : (
                        <Box
                          onClick={handleClickOnAadharUpload}
                          onDragEnter={(e: any) => console.log(e)}
                          sx={{
                            border: '2px dotted #E0E0E0',
                            borderRadius: '10px',
                            p: 2,
                            mt: 2,
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            md={12}
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: '12px',
                                color: '#868686',
                                textAlign: 'center',
                              }}
                            >
                              Upload picture of Aadhar Card
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: '9px',
                                pt: 1,
                                color: '#868686',
                                textAlign: 'center',
                              }}
                            >
                              Upload the front side of the Document Supports
                              JPG, PNG
                            </Typography>
                            <Stack
                              component="img"
                              width="30px"
                              height="30px"
                              src={require('../../../assets/images/uploads.png')}
                            ></Stack>
                            <Chip
                              label="Choose a File"
                              variant="outlined"
                              sx={{ cursor: 'pointer' }}
                            />
                          </Grid>
                        </Box>
                      )}
                    </Stack>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    focused
                    onChange={handleChangess}
                    value={formValues?.pan}
                    size="small"
                    fullWidth
                    label="10-digit PAN Card Number"
                    name="pan"
                    InputLabelProps={{
                      style: { zIndex: 0, paddingLeft: '8px' },
                    }}
                    sx={{
                      pt: 0,
                      pb: 0,
                      pl: 1,
                      pr: 1,
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: '#575757',
                      },
                      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                      {
                        border: '1.5px solid #D6D6D6',
                      },
                    }}
                  />
                  <Box sx={{ pt: 2, pb: 0, pl: 1, pr: 1 }}>
                    <Stack>
                      <input
                        type="file"
                        hidden
                        ref={panUploadRef}
                        onChange={handlePanUpload}
                        accept="image/png, image/jpeg, image/WebP"
                      />
                      {selectedPanUrl ? (
                        <PanImage
                          imageUrl={selectedPanUrl}
                          onRemove={handlePanRemove}
                        />
                      ) : (
                        <Box
                          onClick={handleClickOnPanUpload}
                          onDragEnter={(e: any) => console.log(e)}
                          sx={{
                            border: '2px dotted #E0E0E0',
                            borderRadius: '10px',
                            p: 2,
                            mt: 2,
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            sm={3}
                            md={12}
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: '12px',
                                color: '#868686',
                                textAlign: 'center',
                              }}
                            >
                              Upload picture of PAN Card
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: '9px',
                                pt: 1,
                                color: '#868686',
                                textAlign: 'center',
                              }}
                            >
                              Upload the front side of the Document Supports
                              JPG, PNG
                            </Typography>
                            <Stack
                              component="img"
                              width="30px"
                              height="30px"
                              src={require('../../../assets/images/uploads.png')}
                            ></Stack>
                            <Chip
                              label="Choose a File"
                              variant="outlined"
                              sx={{ cursor: 'pointer' }}
                            />
                          </Grid>
                        </Box>
                      )}
                    </Stack>
                  </Box>
                </Grid>
              </Grid> */}
            </Paper>
          </Grid>
        </Grid>

        <Stack
          direction={'row'}
          justifyItems={'right'}
          spacing={2}
          justifyContent={isMobile ? 'center' : 'right'}
          sx={{ mr: 2, ml: 2 }}
        >
          {/* <Button
            variant="text"
            size={isMobile ? "large" : "large"}
            sx={{
              borderRadius: "30px",
              textTransform: "capitalize",
              border: "none",
              boxShadow: "none",
              textDecoration: "underline",
              color: "#DA0242",
              "&:hover": {
                background: "none",
                cursor: "pointer",
                border: "none",
                boxShadow: "none",
                textDecoration: "underline",
              },
            }}
            onClick={handleClick}
          >
            Delete Account
          </Button> */}
          <Button
            variant="contained"
            size={isMobile ? 'large' : 'large'}
            sx={{

              borderRadius: '8px',
              textTransform: 'capitalize',
              border: 'none',
              boxShadow: 'none',
              fontFamily: 'Montserrat',
              backgroundColor: ' #3C5AA0',
              mr: 5,
              '&:hover': {
                backgroundColor:
                  ' #3C5AA0', // Background color on hover
                cursor: 'pointer',
                border: 'none',
                boxShadow: 'none',
              },
            }}
            onClick={() => handleEditProfile()}
          >
            Save Profile
          </Button>
        </Stack>
      </Grid>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: '#F0F9FF',
            pt: 0.5,
            pb: 0.5,
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: '15px', md: '20px' },
              fontWeight: 600,
              textDecoration: 'none',
              color: '#3C5AA0',
              fontFamily: 'Montserrat',
              textAlign: 'center',
              // textTransform: "capitalize",
            }}
          >
            Delete Account
          </Typography>
          <Box
            onClick={() => {
              setOpen(false);
            }}
            sx={{
              backgroundColor: '#3C5AA0',

              borderRadius: '50%',
              width: '20px',
              height: '20px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
              fontSize: '10px',

              cursor: 'pointer',
              '&:hover': {
                // backgroundColor: "#50A1CA", // Background color on hover
                cursor: 'pointer',
                border: 'none',
              },
            }}
          >
            <Close sx={{ fontSize: '15px' }} />
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container spacing={1.5}>
              <Grid item xs={12} md={12} lg={12} xl={12}>
                <Stack spacing={1}>
                  <Typography
                    sx={{ color: 'black', fontSize: '13px', pt: 1 }}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    As the owner/creator of 2 communities, you must transfer
                    ownership to another member before deleting your account.
                    Please select the individual to whom you would like to
                    transfer ownership from the options below.
                  </Typography>
                  <Typography
                    sx={{ color: '', fontSize: '11px' }}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'flex-start'}
                  >
                    Note: Once you transfer ownership and delete your account,
                    you will no longer be part of the community.
                  </Typography>
                </Stack>
              </Grid>

              <Grid
                container
                sx={{
                  border: '1px solid  gray',
                  alignItems: 'center',
                  display: 'flex',
                  p: 1,
                  maxHeight: { xs: '80vh', md: '40vh', lg: '30vh', xl: '25vh' },
                  overflow: 'auto',
                  backgroundColor: '#F8F8F8',
                  mt: 1,
                  ml: 1,
                  mr: 1,
                }}
                spacing={1}
              >
                {communities?.map((community: any) => (
                  <>
                    <Grid item xs={6} md={6} xl={6}>
                      <Stack
                        direction={'row'}
                        display={'flex'}
                        alignItems={'center'}
                        spacing={1}
                      >
                        <Avatar src={community?.logo}></Avatar>
                        <Typography sx={{ fontsize: '13px' }}>
                          {' '}
                          {(community?.title || community?.name)?.length > 20
                            ? `${(community?.title || community?.name).slice(
                              0,
                              20
                            )}...`
                            : community?.title || community?.name}
                        </Typography>
                      </Stack>
                    </Grid>
                    {/* <Grid item xs={6} md={6} xl={6}>
                      {userLists[community._id]?.length > 0 ?
                      <Autocomplete
                        options={userLists[community._id]||[] }
                         
                        fullWidth
                        // options={communityTitles}
                        componentsProps={{
                          paper: {
                            sx: {
                              padding: "0px",
                            },
                          },
                        }}
                        size="small"
                        sx={{ width: "auto", backgroundColor: "white" }}
                        getOptionLabel={(option: any) =>
                          option.user
                            ? `${option.user.firstName} ${option.user.lastName}`
                            : ""
                        }
                        ListboxProps={{
                          sx: { fontSize: "13px", font: "Roboto" },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select user"
                            size="small"
                            sx={{
                              padding: "0px",
                              fontSize: "10px",
                              color: "red",
                            }}
                          />
                        )} // onChange={(event, newValue) => {
                      />:'Wait a While....'}
                    </Grid> */}
                    <Grid item xs={6} md={6} xl={6}>
                      <Autocomplete
                        options={userLists[community._id] || []}
                        fullWidth
                        // options={communityTitles}
                        componentsProps={{
                          paper: {
                            sx: {
                              padding: '0px',
                            },
                          },
                        }}
                        size="small"
                        sx={{ width: 'auto', backgroundColor: 'white' }}
                        getOptionLabel={(option: any) =>
                          option.user
                            ? `${option.user.firstName} ${option.user.lastName}`
                            : ''
                        }
                        ListboxProps={{
                          sx: { fontSize: '13px', font: 'Roboto' },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select user"
                            size="small"
                            sx={{
                              padding: '0px',
                              fontSize: '10px',
                              color: 'red',
                            }}
                          />
                        )} // onChange={(event, newValue) => {
                      />
                    </Grid>
                  </>
                ))}
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            marginLeft: '10px',
          }}
        >
          <Button
            autoFocus
            onClick={handleClose}
            size="large"
            variant="contained"
            sx={{
              background:
                'linear-gradient(91.58deg, #3C5AA0 21.06%, #467DB4 66.97%, #50A1CA 92.96%)',
              textTransform: 'capitalize',
              border: 'none',
              borderRadius: '10px',
              boxShadow: 'none',
              color: 'white',
              '&:hover': {
                background:
                  'linear-gradient(91.58deg, #3C5AA0 21.06%, #467DB4 66.97%, #50A1CA 92.96%)',
                cursor: 'pointer',
                color: 'white',
                border: 'none',
                boxShadow: 'none',
              },
            }}
          >
            Transfer & Delete
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
            }}
            autoFocus
            size="large"
            sx={{
              textTransform: 'capitalize',
              // fontWeight:600,
              color: '#50A1CA',
              border: '1px solid #50A1CA',
              borderRadius: '10px',
              boxShadow: 'none',
              '&:hover': {
                cursor: 'pointer',
                border: '1px solid #50A1CA',
                boxShadow: 'none',
              },
            }}
          >
            Cancel & Go Back
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={confirm}
        // onClose={handleCloseDialogg}
        onClose={handleConfirmClose}
        sx={{ textAlign: 'center', fontSize: 'small' }}
      >
        <DialogTitle>
          <Typography
            sx={{
              fontSize: '15px',
              fontFamily: 'Montserrat',
              // color: "red",
            }}
          >
            Are you sure you want to delete your account?
            <Typography
              sx={{
                fontSize: '15px',
                fontFamily: 'Montserrat',
              }}
            >
              Once you delete it, you will be removed from all your communities.
            </Typography>
          </Typography>
          <Divider />
          <Stack
            direction={'row'}
            display={'flex'}
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            sx={{ mt: 1 }}
          >
            <Button
              size="small"
              onClick={handleConfirmClose}
              sx={{
                cursor: 'pointer',
                pr: 1,
                textTransform: 'capitalize',
                color: 'White',
                background:
                  'linear-gradient(to right bottom, #3C5AA0, #50A1CA)',
              }}
            >
              No, Cancel
            </Button>
            <Button
              size="small"
              variant="outlined"
              sx={{
                ml: 3,
                cursor: 'pointer',
                textTransform: 'capitalize',

                color: '#3C5AA0',
              }}
            // onClick={() => {
            //   handleCloseDialogg(index);
            // }}
            >
              Yes, delete
            </Button>
          </Stack>
        </DialogTitle>
      </Dialog>
    </>
  );
};
