import { useSnackbar } from 'notistack';
import { useContext } from 'react';
import { AuthContext, IAuthContext } from '../contexts/Auth.context';
import {
  fetchAllLiveClasses,
  fetchAllPastClasses,
  fetchClassesById,
  generateGoogleAuth,
  generateZoomAuth,
  scheduleClass,
  updateScheduleClass,
} from '../services/liveClasses.service';
import { ICreateClass } from '../models/liveClasses.model';
import { useNavigate } from 'react-router-dom';


export const useLiveClasses = () => {


  const { getAccessToken } = useContext<IAuthContext>(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();


  const createGoogleAuth = async (communityId: string) => {
    try {
      const response = await generateGoogleAuth(getAccessToken(), communityId);
      return response;
    } catch (error) {
      enqueueSnackbar("Couldn't fetch Live Classes", {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };


  const createZoomAuth = async (communityId: string) => {
    try {
      const response = await generateZoomAuth(getAccessToken(), communityId);
      return response;
    } catch (error) {
      enqueueSnackbar("Couldn't fetch Live Classes", {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };


  const createLiveClass = async (classData: ICreateClass) => {
    try {
      const response = await scheduleClass(getAccessToken(), classData);
      if (response?.status === 201) {
        enqueueSnackbar('Meeting created successfully', { variant: 'success' });
        navigate('/meetings');
      } else {
        enqueueSnackbar("Couldn't Create Live Class", {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
      return response
    } catch (error) {
      enqueueSnackbar("Couldn't Create Live Class", {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };


  const updateLiveClass = async (classData: ICreateClass, classId: string) => {
    try {
      const response = await updateScheduleClass(getAccessToken(), classData, classId);
      return { class: response };
    } catch (error) {
      enqueueSnackbar("Couldn't fetch Live Classes", {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };


  const getAllLiveClasses = async (id: string) => {
    try {
      const response = await fetchAllLiveClasses(getAccessToken(), id);
      if (response.status === 200) {
        return response;
      }
      return response;
    } catch (error) {
    }
  };


  const getAllPastClasses = async (id: string) => {
    try {
      const response = await fetchAllPastClasses(getAccessToken(), id);
      if (response.status === 200) {
        return response;
      }
      return response;
    } catch (error) {

    }
  };



  const getClassById = async (communityId: string, classId: string) => {
    try {
      const response = await fetchClassesById(getAccessToken(), communityId, classId);
      if (response.status === 200) {
        return response;
      }
      return response;
    } catch (error) {
      console.log(error, "error")
    }
  };



  return {
    getAllLiveClasses,
    getAllPastClasses,
    getClassById,
    createLiveClass,
    createGoogleAuth,
    updateLiveClass,
    createZoomAuth
  };
};
