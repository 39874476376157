
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Stack } from '@mui/system';
import { CustomTableHeader } from './components/CustomTableHeader';
import { IColumn } from '../../models/table.model';
import { useEffect, useState } from 'react';
import {
    Chip,
    IconButton,
    Paper,
    TableContainer,
    TablePagination,
    Typography,
} from '@mui/material';
import { FiberManualRecordRounded, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import React from 'react';
import SimpleBar from 'simplebar-react';
import { IEventData, IUser } from '../../models/events.models';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useEvents } from '../../hooks/useEvents';
import { GetColor } from './GetColor';
import { getStaticValue } from '../../utils/StaticValues';

interface ITableProps {
    data: IUser[];
    eventRequestData: IEventData | undefined;
    columns?: IColumn[];
    eventRequestList: () => void;
}


export const EventRequestTable = ({
    columns,
    data = [],
    eventRequestList,
    eventRequestData
}: ITableProps) => {
    const [sortedColumns, setSortedColumns] = useState<string[]>([]);
    const [tableData, setTableData] = useState<IUser[]>(data);
    const [events, setEvents] = useState<IEventData | undefined>(eventRequestData);


    useEffect(() => {
        if (eventRequestData) {
            setEvents(eventRequestData);
        }
    }, [eventRequestData]);


    // console.log(eventRequestData, "appointment");





    // console.log(tableData, "tableData")


    const { requestAcceptAndReject } = useEvents();



    useEffect(() => {
        // console.log("Incoming data:", data);
        setTableData(Array.isArray(data) ? data : []);
    }, [data]);

    // console.log(tableData, "tableData");
    const sortedData = Array.isArray(tableData) ? [...tableData] : [];

    // console.log(sortedData, "sortedData");
    const [sortDirections, setSortDirections] = useState<{
        [key: string]: 'asc' | 'desc' | undefined;
    }>({});

    const handleSort = (columnTitle: string) => {
        setSortDirections((prevSortDirections) => {
            let newSortDirections: { [key: string]: 'asc' | 'desc' | undefined } = {};
            let newSortedColumns: string[] = [];

            // Toggle the sorting direction for the clicked column
            if (prevSortDirections[columnTitle] === 'asc') {
                newSortDirections[columnTitle] = 'desc';
            } else {
                newSortDirections[columnTitle] = 'asc';
            }

            // Include the clicked column in the list of sorted columns
            newSortedColumns.push(columnTitle);

            setSortedColumns(newSortedColumns);

            return newSortDirections;
        });
    };

    const sortedColumnTitles = sortedColumns?.filter(
        (columnTitle) => sortDirections[columnTitle] !== undefined
    );

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        handleChangePage(null, 0);
    }, [data]);
    const startIndex = page * rowsPerPage;
    const endIndex = (page + 1) * rowsPerPage;
    const paginatedData = sortedData.slice(startIndex, endIndex);



    const handleAcceptAndReject = async (eventId: string, userId: string, status: string) => {
        try {
            const response = await requestAcceptAndReject(eventId, userId, status);
            eventRequestList()
            return response;
        } catch (error) {
            console.error("Error updating status:", error);
        }
    };


    return (
        <>
            {' '}

            <SimpleBar style={{ height: '48vh', overflowY: 'auto' }}>
                <TableContainer
                    component={Paper}
                    elevation={0}
                    sx={{
                        height: { xs: 'calc(100vh - 32vh)', md: 'calc(100vh - 52vh)' },
                        border: '1px solid #D4DDED',
                        borderRadius: '8px',
                    }}
                >
                    <Table
                        sx={{
                            '& th': {
                                fontSize: '15px',
                                fontWeight: 700,
                                color: '#565656',
                                p: 1,
                                '& .MuiTypography-root': { m: 0, fontSize: '13px', fontWeight: 500, fontFamily: 'Montserrat', color: '#646464' },
                            },
                            '& td': {
                                fontSize: '14px',
                                fontWeight: 500,
                                color: '#1A1A1A',
                                p: '5px 10px',
                                '& .MuiTypography-root': { m: 0, fontSize: '12px', fontWeight: 500, fontFamily: 'Montserrat' },
                            },
                        }}
                    >
                        <TableHead
                            style={{
                                position: 'sticky',
                                top: 0,
                                zIndex: 1,
                                background: '#ffffff',
                            }}
                        >
                            <TableRow sx={{}}>
                                {columns?.map((column: IColumn, i: number) => (
                                    <TableCell
                                        key={i}
                                        onClick={() => handleSort(column.title)}
                                        sx={{ width: '100px' }}
                                    >
                                        <CustomTableHeader
                                            title={column.title}
                                            sortDirection={sortDirections[column.title]}
                                        />
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedData?.map((item: IUser, index) => (
                                <TableRow hover key={index}>
                                    <TableCell sx={{ width: '100px' }}>
                                        <Typography sx={{ fontSize: '14px' }}>
                                            {item?.userId?.firstName}
                                        </Typography>
                                    </TableCell>

                                    <TableCell >
                                        <Typography sx={{ fontSize: '14px' }}>
                                            {item?.userId?.emailId}
                                        </Typography>
                                    </TableCell>
                                    <TableCell >
                                        <Typography>
                                            {item?.userId?.phoneNumber}
                                        </Typography>
                                    </TableCell>

                                    <TableCell >
                                        <Typography>
                                            {eventRequestData?.pricing ? eventRequestData?.pricing : "Free"}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        {item?.status === "PENDING" ? (
                                            <>
                                                <IconButton
                                                    onClick={() => handleAcceptAndReject(eventRequestData?._id || "", item?.userId?._id, "ACCEPTED")}
                                                    sx={{ color: '#44b700' }}>
                                                    <CheckCircleOutlineIcon />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => handleAcceptAndReject(eventRequestData?._id || "", item?.userId?._id, "REJECTED")}
                                                    sx={{ color: 'red' }}>
                                                    <CancelOutlinedIcon />
                                                </IconButton>
                                            </>

                                        ) : (
                                            <>
                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        textTransform: 'capitalize',
                                                    }}
                                                >

                                                    <FiberManualRecordRounded
                                                        key={index}
                                                        sx={{
                                                            hieght: '10px',
                                                            width: '10px',
                                                            color: GetColor(item.status),
                                                        }}
                                                    />
                                                    {getStaticValue(item.status)}
                                                </Typography>
                                            </>

                                        )}

                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>

                    </Table>
                </TableContainer>
            </SimpleBar>
            <Stack
                direction={'row-reverse'}
                alignItems={'center'}
                sx={{
                    color: '#A5A5A5',
                    fontSize: { xs: '10px', md: '1.5vh' },
                    pr: { xs: '0px', md: '25px' },
                    mb: { xs: 12, md: 0 },
                    // border: '1px solid #D4DDED',

                }}
            >
                <Typography
                    sx={{
                        color: '#000000',
                        fontSize: '13px',
                        display: { xs: 'none', md: 'block' },
                        fontFamily: 'Montserrat'
                    }}
                >
                    Showing {Math.min(rowsPerPage, data.length - page * rowsPerPage)} of{' '}
                    {data.length} entries
                </Typography>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={
                        <span style={{ fontFamily: 'Montserrat', fontSize: '14px' }}>View</span>
                    }
                    labelDisplayedRows={() => null}
                    SelectProps={{
                        SelectDisplayProps: {
                            style: { border: '1px solid #A5A5A5', borderRadius: '5px', fontFamily: 'Montserrat' },
                        },
                        // You can also customize the style of the menu options if desired
                        MenuProps: {
                            PaperProps: { style: { border: '1px solid #A5A5A5', fontFamily: 'Montserrat' } },
                        },
                        inputProps: {
                            style: {
                                fontFamily: 'Montserrat'
                            }

                        }
                    }}
                    ActionsComponent={({ onPageChange, page }) => {
                        const totalPages = Math.ceil(data.length / rowsPerPage);

                        return (
                            <Stack
                                direction={'row'}
                                display="flex"
                                alignItems="center"
                                justifyContent={'space-between'}
                            >
                                <IconButton
                                    onClick={(event) => onPageChange(event, page - 1)}
                                    disabled={page === 0}
                                    aria-label="go to previous page"
                                >
                                    <KeyboardArrowLeft />
                                </IconButton>

                                {[...Array(3)].map((_, index) => {
                                    const pageNumber = page + index;
                                    if (pageNumber < totalPages) {
                                        return (
                                            <IconButton
                                                key={pageNumber}
                                                onClick={(event) => onPageChange(event, pageNumber)}
                                                style={{
                                                    fontSize: '1.9vh',
                                                    border:
                                                        page === pageNumber ? '1px solid #3C5AA0' : 'none',
                                                    color: page === pageNumber ? '#3C5AA0' : '#000000',
                                                    width: page === pageNumber ? '30px' : '30px',
                                                    height: page === pageNumber ? '30px' : '30px',
                                                }}
                                            >
                                                {pageNumber + 1}
                                            </IconButton>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}

                                <IconButton
                                    onClick={(event) => onPageChange(event, page + 1)}
                                    disabled={page >= totalPages - 1}
                                    aria-label="next page"
                                >
                                    <KeyboardArrowRight />
                                </IconButton>
                            </Stack>
                        );
                    }}
                />
            </Stack>
        </>
    );
};
