
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Stack } from '@mui/system';
import { CustomTableHeader } from './components/CustomTableHeader';
import { IColumn } from '../../models/table.model';
import { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Checkbox,
    Drawer,
    IconButton,
    Paper,
    TableContainer,
    TablePagination,
    TextField,
    Typography,
} from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import React from 'react';
import SimpleBar from 'simplebar-react';
import { IAttendees } from '../../models/appointments.models';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import CurrencyRupeeOutlinedIcon from '@mui/icons-material/CurrencyRupeeOutlined';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import GooglesIcon from "../../assets/icons/svg-icons/googlemeet.svg"
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import { Attendee, MeetingData } from '../Appointments/Components/Attendees';

interface ITableProps {
    data: Attendee[];
    appointmentData: MeetingData | undefined;
    columns?: IColumn[];
    appointmentsList: () => void;
}


type Anchor = 'top' | 'left' | 'bottom' | 'right';


export const AttendeesTable = ({
    columns,
    data = [],
    appointmentsList,
    appointmentData
}: ITableProps) => {
    const [sortedColumns, setSortedColumns] = useState<string[]>([]);
    const [tableData, setTableData] = useState<Attendee[]>(data);
    const [appointment, setAppointments] = useState<MeetingData | undefined>(appointmentData);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [selectedItemId, setSelectedItemId] = useState<string | null>(null);

    useEffect(() => {
        if (appointmentData) {
            setAppointments(appointmentData);
        }
    }, [appointmentData]);


    // console.log(appointment, "appointment");


    const [state, setState] = useState<{ [key in Anchor]: boolean }>({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });


    const toggleDrawer = (anchor: string, open: boolean) => {
        setState((prevState) => ({
            ...prevState,
            [anchor]: open
        }));
    };


    // console.log(tableData, "tableData")



    useEffect(() => {
        // console.log("Incoming data:", data);
        setTableData(Array.isArray(data) ? data : []);
    }, [data]);

    // console.log(tableData, "tableData");
    const sortedData = Array.isArray(tableData) ? [...tableData] : [];

    // console.log(sortedData, "sortedData");
    const [sortDirections, setSortDirections] = useState<{
        [key: string]: 'asc' | 'desc' | undefined;
    }>({});

    const handleSort = (columnTitle: string) => {
        setSortDirections((prevSortDirections) => {
            let newSortDirections: { [key: string]: 'asc' | 'desc' | undefined } = {};
            let newSortedColumns: string[] = [];

            // Toggle the sorting direction for the clicked column
            if (prevSortDirections[columnTitle] === 'asc') {
                newSortDirections[columnTitle] = 'desc';
            } else {
                newSortDirections[columnTitle] = 'asc';
            }

            // Include the clicked column in the list of sorted columns
            newSortedColumns.push(columnTitle);

            setSortedColumns(newSortedColumns);

            return newSortDirections;
        });
    };

    const sortedColumnTitles = sortedColumns?.filter(
        (columnTitle) => sortDirections[columnTitle] !== undefined
    );

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        handleChangePage(null, 0);
    }, [data]);
    const startIndex = page * rowsPerPage;
    const endIndex = (page + 1) * rowsPerPage;
    const paginatedData = sortedData.slice(startIndex, endIndex);


    const handleClick = (
        event: React.MouseEvent<HTMLElement>,
        itemId: string
    ) => {
        setAnchorEl(event.currentTarget as HTMLElement);
        setSelectedItemId(itemId);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setSelectedItemId(null);
    };


    const list = (anchor: Anchor, item: Attendee, index: number) => (
        <Box sx={{
            width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 400,
            p: 2,
        }}>
            <Stack display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
                <IconButton onClick={() => toggleDrawer(anchor, false)}>
                    <ClearIcon sx={{ color: '#515151' }} />
                </IconButton>
            </Stack>

            <Stack spacing={1.5} mt={2}>
                {/* Appointment */}
                <Stack direction="column" gap={1}>
                    <Typography sx={{ fontSize: '15px', fontWeight: '600', fontFamily: 'Montserrat' }}>
                        {appointment?.title}
                    </Typography>
                    <Typography sx={{ fontSize: '12px', fontWeight: '500', fontFamily: 'Montserrat' }}>

                    </Typography>
                </Stack>

                {/* Event Date & Time */}
                <Stack direction="row" alignItems="center" gap={1}>
                    <CalendarMonthOutlinedIcon sx={{ fontSize: '20px', color: 'gray' }} />
                    <Typography sx={{ fontSize: '12px', fontWeight: '500', fontFamily: 'Montserrat' }}>
                        {item?.day} - {item?.startTime} - {item?.endTime}
                    </Typography>
                </Stack>


                <Stack direction="row" alignItems="center" gap={1}>
                    <CurrencyRupeeOutlinedIcon sx={{ fontSize: '20px', color: 'gray' }} />
                    <Typography sx={{ fontSize: '12px', fontWeight: '500', fontFamily: 'Montserrat' }}>
                        {/* {appointmentData? || "Free"} */}
                    </Typography>
                </Stack>


                <Stack direction="row" alignItems="center" gap={1}>
                    <Person2OutlinedIcon sx={{ fontSize: '20px', color: 'gray' }} />
                    <Typography sx={{ fontSize: '12px', fontWeight: '500', fontFamily: 'Montserrat' }}>
                        {item?.attendeeId?.emailId}
                    </Typography>
                </Stack>


                <Stack direction="row" alignItems="center" gap={1}>
                    <PhoneOutlinedIcon sx={{ fontSize: '20px', color: 'gray' }} />
                    <Typography sx={{ fontSize: '12px', fontWeight: '500', fontFamily: 'Montserrat' }}>

                    </Typography>
                </Stack>

                {appointment?.location && appointment?.location !== "ONLINE" ? (
                    <>
                        <Stack direction='row' alignItems='center' gap={1}>
                            < PinDropOutlinedIcon sx={{ fontSize: '20px', color: 'gray' }} />
                            <Typography sx={{ fontSize: '12px', fontWeight: '500', fontFamily: 'Montserrat' }}>
                                {appointment?.location}
                            </Typography>
                        </Stack>
                    </>
                ) : (
                    <>
                        <Stack direction='row' alignItems='center' gap={1}>
                            <Box component={'img'} src={GooglesIcon} width={'18px'} height={'18px'} />
                            <Typography component={"a"} href={item?.meetingLink} target='_blank' sx={{ fontSize: '12px', fontWeight: '500', fontFamily: 'Montserrat', textDecoration: 'underline', cursor: 'pointer' }}>

                                {item?.meetingLink}
                            </Typography>
                        </Stack>
                    </>

                )}




                <Stack>
                    <Stack>
                        <Typography sx={{ fontSize: '14px', fontWeight: '500', fontFamily: 'Montserrat', color: '#000000' }}>
                            Phone No
                        </Typography>
                        <TextField
                            // value={item?.}
                            size='small'
                            disabled
                            sx={{ backgroundColor: '#F9F9F9', mt: 1 }}
                            InputProps={{
                                sx: {
                                    fontSize: '12px',
                                    color: '#646464',
                                    textAlign: 'center',
                                },
                            }}
                        />
                    </Stack>
                    <Stack sx={{ mt: 1 }}>
                        <Typography sx={{ fontSize: '14px', fontWeight: '500', color: '#000000', fontFamily: 'Montserrat' }}>
                            Email ID
                        </Typography>
                        <TextField
                            // value={item?.attendees?.map((attendee: any) => attendee.email).join(', ')}
                            size='small'
                            disabled
                            sx={{ backgroundColor: '#F9F9F9', mt: 1 }}
                            InputProps={{
                                sx: {
                                    fontSize: '12px',
                                    color: '#646464',
                                    textAlign: 'center',
                                },
                            }}
                        />
                    </Stack>
                </Stack>

                {/* Buttons */}
                <Stack display={'flex'} direction="row" spacing={1} gap={2} mx={2} justifyContent={'flex-end'} alignItems={'end'}>
                    <Button
                        fullWidth
                        size="large"
                        variant="outlined"
                        sx={{ textTransform: 'capitalize', border: '1px solid #E7EBF1', fontFamily: 'Montserrat', borderRadius: '8px', boxShadow: 'none', color: '#000000', backgroundColor: '#ffffff' }}
                    >
                        Cancel
                    </Button>
                    <Button
                        fullWidth
                        size="large"
                        variant="contained"
                        sx={{ backgroundColor: '#3C5AA0', fontFamily: 'Montserrat', textTransform: 'capitalize', border: 'none', borderRadius: '8px', boxShadow: 'none', color: 'white' }}
                    >
                        Reschedule
                    </Button>
                </Stack>
            </Stack>
        </Box>
    );







    return (
        <>
            {' '}

            <SimpleBar style={{ height: '65vh', overflowY: 'auto' }}>
                <TableContainer
                    component={Paper}
                    elevation={0}
                    sx={{
                        height: { xs: 'calc(100vh - 32vh)', md: 'calc(100vh - 45vh)' },
                        border: '1px solid #D4DDED',
                        borderRadius: '8px',


                    }}
                >
                    <Table
                        sx={{
                            '& th': {
                                fontSize: '15px',
                                fontWeight: 700,
                                color: '#565656',
                                p: 1,
                                '& .MuiTypography-root': { m: 0, fontSize: '13px', fontWeight: 500, fontFamily: 'Montserrat', color: '#646464' },
                            },
                            '& td': {
                                color: '#1A1A1A',
                                p: '5px 10px',
                                '& .MuiTypography-root': { m: 0, fontSize: '12px', fontFamily: 'Montserrat' },
                            },
                        }}
                    >
                        <TableHead
                            style={{
                                position: 'sticky',
                                top: 0,
                                zIndex: 1,
                                background: '#ffffff',
                            }}
                        >
                            <TableRow sx={{}}>
                                {columns?.map((column: IColumn, i: number) => (
                                    <TableCell
                                        key={i}
                                        onClick={() => handleSort(column.title)}
                                        sx={{ width: '100px' }}
                                    >
                                        <CustomTableHeader
                                            title={column.title}
                                            sortDirection={sortDirections[column.title]}
                                        />
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedData?.map((item: Attendee, index) => (
                                <TableRow hover key={index}>
                                    <TableCell key={index} sx={{ width: '100px' }}>
                                        <Typography sx={{ fontSize: { xs: '12px', md: '14px' }, fontFamily: 'Montserrat' }}>
                                            {item?.attendeeId?.firstName}
                                        </Typography>
                                        <Typography sx={{ fontSize: { xs: '10px', md: '12px' }, fontFamily: 'Montserrat', fontWeight: 400 }}>
                                            {item?.attendeeId?.emailId}
                                        </Typography>
                                    </TableCell>

                                    <TableCell >
                                        <Typography sx={{ fontSize: '14px' }}>
                                            {appointment?.title}
                                        </Typography>

                                    </TableCell>
                                    <TableCell >
                                        <Typography>
                                            {appointment?.location}
                                        </Typography>
                                    </TableCell>
                                    <TableCell >
                                        <Typography>
                                            {item?.day} -  {''}
                                            {item?.startTime}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Checkbox sx={{
                                            borderRadius: '0px',
                                            padding: 0,
                                            '& .MuiSvgIcon-root': {
                                                fontSize: 18,
                                                border: '1px solid #000000',
                                                borderRadius: '4px',
                                                // color: isSelectAll && isAllSelected ? '#2653A3' : selected ? '#2653A3' : 'transparent',
                                                // backgroundColor: isSelectAll && isAllSelected ? '#e3f2fd' : selected ? '#e3f2fd' : 'transparent',
                                            },
                                        }} />
                                    </TableCell>
                                    <TableCell >
                                        {(['right'] as Anchor[]).map((anchor) => (
                                            <React.Fragment key={anchor}>
                                                <IconButton
                                                    onClick={() => {
                                                        setSelectedItemId(item?._id);
                                                        toggleDrawer(anchor, true);
                                                    }}
                                                >
                                                    <MoreVertOutlinedIcon sx={{ fontSize: '20px', color: '#000000' }} />
                                                </IconButton>
                                                <Drawer
                                                    anchor={anchor}
                                                    open={state[anchor]}
                                                    onClose={() => toggleDrawer(anchor, false)}
                                                >
                                                    {/* {selectedItemId && list(anchor, selectedItemId, index)} */}
                                                </Drawer>
                                            </React.Fragment>
                                        ))}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>

                    </Table>
                </TableContainer>
            </SimpleBar>
            <Stack
                direction={'row-reverse'}
                alignItems={'center'}
                sx={{
                    color: '#A5A5A5',
                    fontSize: { xs: '10px', md: '1.5vh' },
                    pr: { xs: '0px', md: '25px' },
                    mb: { xs: 12, md: 0 },
                    // border: '1px solid #D4DDED',

                }}
            >
                <Typography
                    sx={{
                        color: '#000000',
                        fontSize: '13px',
                        display: { xs: 'none', md: 'block' },
                        fontFamily: 'Montserrat'
                    }}
                >
                    Showing {Math.min(rowsPerPage, data.length - page * rowsPerPage)} of{' '}
                    {data.length} entries
                </Typography>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={
                        <span style={{ fontFamily: 'Montserrat', fontSize: '14px' }}>View</span>
                    }
                    labelDisplayedRows={() => null}
                    SelectProps={{
                        SelectDisplayProps: {
                            style: { border: '1px solid #A5A5A5', borderRadius: '5px', fontFamily: 'Montserrat' },
                        },
                        // You can also customize the style of the menu options if desired
                        MenuProps: {
                            PaperProps: { style: { border: '1px solid #A5A5A5', fontFamily: 'Montserrat' } },
                        },
                        inputProps: {
                            style: {
                                fontFamily: 'Montserrat'
                            }

                        }
                    }}
                    ActionsComponent={({ onPageChange, page }) => {
                        const totalPages = Math.ceil(data.length / rowsPerPage);

                        return (
                            <Stack
                                direction={'row'}
                                display="flex"
                                alignItems="center"
                                justifyContent={'space-between'}
                            >
                                <IconButton
                                    onClick={(event) => onPageChange(event, page - 1)}
                                    disabled={page === 0}
                                    aria-label="go to previous page"
                                >
                                    <KeyboardArrowLeft />
                                </IconButton>

                                {[...Array(3)].map((_, index) => {
                                    const pageNumber = page + index;
                                    if (pageNumber < totalPages) {
                                        return (
                                            <IconButton
                                                key={pageNumber}
                                                onClick={(event) => onPageChange(event, pageNumber)}
                                                style={{
                                                    fontSize: '1.9vh',
                                                    border:
                                                        page === pageNumber ? '1px solid #3C5AA0' : 'none',
                                                    color: page === pageNumber ? '#3C5AA0' : '#000000',
                                                    width: page === pageNumber ? '30px' : '30px',
                                                    height: page === pageNumber ? '30px' : '30px',
                                                }}
                                            >
                                                {pageNumber + 1}
                                            </IconButton>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}

                                <IconButton
                                    onClick={(event) => onPageChange(event, page + 1)}
                                    disabled={page >= totalPages - 1}
                                    aria-label="next page"
                                >
                                    <KeyboardArrowRight />
                                </IconButton>
                            </Stack>
                        );
                    }}
                />
            </Stack>
        </>
    );
};
