export const GetColor = (status: string) => {
  switch (status?.toLowerCase()) {
    case 'success':

    case 'business':
    case 'active':
      return 'success.light';
    case 'denied':
      return 'error.light';
    case 'invited':
      return 'error.light';
    case 'pending':
      return 'warning.light';
    case 'economy':
      return 'success.light';
    case 'published':
      return 'success.light';
    case 'cancelled':
      return 'red';
    case 'failed':
      return 'red';
    case 'created':
      return '#FF4F00';
    case 'expired':
      return 'red';
      case 'rejected':
      return 'red';
    case 'paid':
      return 'success.light';
    case 'Paid_By_Cash':
      return 'success.light';
      case 'settled':
        return 'grey';
        case 'Pending Payment':
          return '#FF4F00';

    default:
      return '';
  }
};
