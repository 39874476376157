
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { BorderIcon, GreenFillIcon } from '../../../assets/icons';
import { Box, Dialog, DialogContent, DialogProps, Grid, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { usePlans } from '../../../hooks/usePlans';
import { useBankAccounts } from '../../../hooks/useBankAccounts';
import { IPlan, Plans } from '../../Plans/Plans';
import { useInvitations } from '../../../hooks/useInvitations';
import { BankAccInterface } from '../../../models/BankAcc.models';
import { Invitations } from '../../../models/Invitation.model';
import { useNavigate } from 'react-router-dom';
import Close from '@mui/icons-material/Close';

const CommunitySteps = () => {
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };


    const communitySelected = useSelector(
        (state: any) => state.selectedCommunity.selectedCommunity
    );

    const community_id = communitySelected?._id;

    const [openPlans, setOpenPlans] = React.useState(false);
    const [openAddUser, setOpenAddUser] = React.useState(false);
    const [openBankDetails, setOpenBankDetails] = React.useState(false);
    const [fullWidth] = React.useState(true);
    const [maxWidth] = React.useState<DialogProps['maxWidth']>('lg');
    const { getCommunityPlansList } = usePlans();
    const { getBankDetailById } = useBankAccounts();
    const { getInviteList } = useInvitations();
    const [plans, setPlans] = React.useState<IPlan[]>([]);
    const [accountDetails, setAccountDetails] = React.useState<BankAccInterface[]>([]);
    const [invitesList, setInvitesList] = React.useState<Invitations[]>([]);
    const [progress, setProgress] = React.useState<number>(0);
    const [error, setError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    //Popup Fuctions Start//

    React.useEffect(() => {
        const fetchDetails = async () => {
            try {
                if (community_id) {
                    const plans_data = await getCommunityPlansList(community_id);
                    if (communitySelected?.status === 'PUBLISHED') {
                        setProgress((prevProgress) => prevProgress + 25);
                    }

                    if (plans_data && plans_data?.length > 0) {
                        setPlans(plans_data);
                        setProgress((prevProgress) => prevProgress + 25);
                    }

                    const response = await getBankDetailById(community_id);

                    // memberListRef.current = users || [];
                    if (response && response.data?.length > 0) {
                        setAccountDetails(response.data);
                        setProgress((prevProgress) => prevProgress + 25);
                    }
                    const invitesList = await getInviteList(community_id);
                    if (invitesList && invitesList.data?.length > 0) {
                        setInvitesList(invitesList.data);
                        setProgress((prevProgress) => prevProgress + 25);
                    }
                }
            } catch (error) {
                console.error('Error fetching invite list:', error);
            }
        };

        fetchDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [community_id]);

    const refetchPlanDetails = async () => {
        try {
            if (community_id) {
                const plans_data = await getCommunityPlansList(community_id);
                if (plans_data && plans_data?.length > 0) {
                    setPlans(plans_data);
                    setProgress((prevProgress) =>
                        prevProgress < 100 ? prevProgress + 25 : 100
                    );
                }
            }
        } catch (error) {
            console.error('Error fetching invite list:', error);
        }
    };

    const refetchBankDetails = async () => {
        try {
            if (community_id) {
                const response = await getBankDetailById(community_id);
                // memberListRef.current = users || [];
                if (response && response.data?.length > 0) {
                    setAccountDetails(response.data);
                    setProgress((prevProgress) =>
                        prevProgress < 100 ? prevProgress + 25 : 100
                    );
                }
            }
        } catch (error) {
            console.error('Error fetching invite list:', error);
        }
    };

    const refetchInviteDetails = async () => {
        try {
            if (community_id) {
                const invitesList = await getInviteList(community_id);
                if (invitesList && invitesList.data?.length > 0) {
                    setInvitesList(invitesList.data);
                    setProgress((prevProgress) =>
                        prevProgress < 100 ? prevProgress + 25 : 100
                    );
                }
            }
        } catch (error) {
            console.error('Error fetching invite list:', error);
        }
    };

    const handleClickOpenPlans = () => {
        // if( communitySelected?.status === "CREATED" ){
        setOpenPlans(true);
        // }
    };

    const handleClose = () => {
        setOpenPlans(false);
        refetchPlanDetails();
    };

    const navigate = useNavigate();

    const handleClickCommunityPublish = () => {
        if (plans?.length > 0) {
            navigate(`/communities/${community_id}/build`);
        }
    };
    const handleClickCommunityPublishFree = () => {
        navigate(`/communities/${community_id}/build`);
    };

    const handleClickOpenAddUser = () => {
        if (
            communitySelected.collectSubscription === 'YES' &&
            communitySelected?.status === 'PUBLISHED' &&
            // accountDetails.length > 0 &&
            plans.length > 0
        ) {
            setOpenAddUser(true);
        } else if (
            communitySelected.collectSubscription === 'NO' &&
            communitySelected.status === 'PUBLISHED'
        ) {
            setOpenAddUser(true);
        } else {
            setError(true);
            if (
                communitySelected.collectSubscription === 'NO' &&
                communitySelected?.status !== 'PUBLISHED'
            ) {
                setErrorMessage('Please build your community to invite members');
            } else {
                setErrorMessage(
                    'Please complete the first 2 necessary steps to invite members.'
                );
            }
        }
    };

    const handleCloseAddUser = () => {
        setOpenAddUser(false);
        refetchInviteDetails();
    };

    const handleOpenBankDetails = () => {
        if (communitySelected?.status === 'PUBLISHED') {
            setOpenBankDetails(true);
        }
    };

    const handleCloseBankDetails = () => {
        setOpenBankDetails(false);
        refetchBankDetails();
    };

    return (
        <Box sx={{
            borderRadius: '12px',
            border: '1px solid #E7EBF1',
            backgroundColor: 'white'

        }}>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel')} elevation={0} sx={{ p: 1.5, backgroundColor: 'transparent', border: '1px 1px 1px 1px solid black' }}>
                <AccordionSummary
                    // expandIcon={<ArrowForwardIosOutlinedIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Stack flexDirection={'row'} justifyContent={'center'} alignItems={'center'} gap={2}>
                        <GreenFillIcon />
                        <Typography sx={{ flexShrink: 0, fontFamily: 'Montserrat', fontSize: '16px', fontWeight: '500' }}>
                            Create your community
                        </Typography>
                    </Stack>
                </AccordionSummary>

            </Accordion>
            <Accordion elevation={0} sx={{ p: 1.5, backgroundColor: 'transparent' }}>
                <AccordionSummary
                    expandIcon={<ArrowForwardIosOutlinedIcon />}
                    // aria-controls="panel2bh-content"
                    // id="panel2bh-header"
                    onClick={handleClickOpenPlans}
                >
                    <Stack flexDirection={'row'} justifyContent={'center'} alignItems={'center'} gap={2}>
                        {plans?.length > 0 ? <GreenFillIcon /> : <BorderIcon />}
                        <Typography sx={{ flexShrink: 0, fontFamily: 'Montserrat', fontSize: '16px', fontWeight: '500' }}>
                            Create plan
                        </Typography>
                    </Stack>
                </AccordionSummary>
                <Dialog
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    open={openPlans}
                    onClose={handleClose}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ backgroundColor: '#F0F9FF', height: '50px', px: 2 }}
                    >
                        <Typography
                            sx={{
                                color: '#3C5AA0',
                                fontSize: '20px',
                                fontWeight: '500',
                                backgroundColor: '#F0F9FF',
                            }}
                        >
                            {/* Create Plans */}
                        </Typography>
                        <Box
                            sx={{
                                backgroundColor: '#3C5AA0',
                                padding: '4px',
                                borderRadius: '50%',
                                width: '20px',
                                height: '20px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                            }}
                            onClick={() => handleClose()}
                        >
                            <Close sx={{ fontSize: '15px', color: '#FFFFFF' }} />
                        </Box>
                    </Grid>
                    <DialogContent sx={{ pt: '10px' }}>
                        <Plans />
                    </DialogContent>
                </Dialog>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} elevation={0} sx={{ p: 1.5, backgroundColor: 'transparent' }}>
                <AccordionSummary
                    expandIcon={<ArrowForwardIosOutlinedIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                >
                    <Stack flexDirection={'row'} justifyContent={'center'} alignItems={'center'} gap={2}>
                        <BorderIcon />
                        <Typography sx={{ flexShrink: 0, fontFamily: 'Montserrat', fontSize: '16px', fontWeight: '500' }}>
                            Build community
                        </Typography>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} elevation={0} sx={{ p: 1.5, backgroundColor: 'transparent' }}>
                <AccordionSummary
                    expandIcon={<ArrowForwardIosOutlinedIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                >
                    <Stack flexDirection={'row'} justifyContent={'center'} alignItems={'center'} gap={2}>
                        <BorderIcon />
                        <Typography sx={{ flexShrink: 0, fontFamily: 'Montserrat', fontSize: '16px', fontWeight: '500' }}>
                            Add bank details
                        </Typography>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} elevation={0} sx={{ p: 1.5, backgroundColor: 'transparent' }}>
                <AccordionSummary
                    expandIcon={<ArrowForwardIosOutlinedIcon />}

                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                >
                    <Stack flexDirection={'row'} justifyContent={'center'} alignItems={'center'} gap={2}>
                        <BorderIcon />
                        <Typography sx={{ flexShrink: 0, fontFamily: 'Montserrat', fontSize: '16px', fontWeight: '500' }}>
                            Invite
                        </Typography>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                </AccordionDetails>
            </Accordion>

        </Box>
    );
}
export default CommunitySteps;